import { Component, OnInit, HostListener } from '@angular/core';
import { AgregarElementoService } from 'src/app/services/agregar-elemento.service';
 @Component({
  selector: 'app-agregar-elemento',
  templateUrl: './agregar-elemento.component.html',
  styleUrls: ['./agregar-elemento.component.scss']
})
export class AgregarElementoComponent implements OnInit {

  constructor(public _agregarElementoService:AgregarElementoService) { }

  ngOnInit() {
    this._agregarElementoService.menuElement = document.getElementById('agregar')

  }


  @HostListener('document:mousedown', ['$event']) onMouseDown(e) {
    this._agregarElementoService.onMouseDown(e)
 
  }
  @HostListener('document:mousemove', ['$event']) onMouseMove(e) {
    this._agregarElementoService.onMouseMove(e)
    // console.log(e);

  }
  @HostListener('document:mouseup', ['$event']) onMouseup(e) {
    this._agregarElementoService.onMouseUp(e)
 
  }





}
