import { ModalTemplateService } from './../../components/modal-template/modal-template.service';
import { TemplatesService } from './../../services/templates.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.scss']
})
export class PlantillasComponent implements OnInit {
  templates
  constructor(
    public _templateService: TemplatesService,
    public _modalTemplateService: ModalTemplateService
  ) { }

  ngOnInit() {
    this.getTemplates()
    this._templateService.notificacion.subscribe(resp => this.getTemplates())
  }

  getTemplates(){
    this._templateService.getTemplates().subscribe(resp=>{
      console.log(resp);
      
      this.templates = resp

    })
  }

  deleteTemplate(id){
    this._templateService.deleteTemplate(id).subscribe()
  }

  // crearConfig(template) {
  //   this._selecrProyectModalService.mostrarModal(template._id)
  //   this.contenido = template.codigo[0]
  //   let config = new Config(
  //     this.nombre,
  //     this.contenido,
  //     this.usuario,
  //     template.nombre
  //   )

  //   this._editorService.sendConfig(config).subscribe(resp =>{
  //     this.router.navigateByUrl(`/perfil/${this.usuario._id}` )
  //   })
  // }
}
