import { TemplatesService } from './../../services/templates.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subir-template',
  templateUrl: './subir-template.component.html',
  styleUrls: ['./subir-template.component.scss']
})
export class SubirTemplateComponent implements OnInit {
  files = new Array();
  constructor(public templatesService: TemplatesService) { }

  ngOnInit() {
  }

  uploadFile(event){
    console.log(event.target.files[0])
    this.templatesService.uploadTemplate(event.target.files[0])
    let fileList = event.target.files;
    if (fileList) {
      for (let index = 0; index < fileList.length; index++) {
        // console.log(fileList)

        this.files.push(fileList[index].name)

      }
    }



  }

}
