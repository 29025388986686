import { Usuario } from './../models/usuario.model';
import { UsuarioService } from './../services/usuario.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  recuerdame: boolean = false
  constructor(
    public router: Router,
    public _usuarioService: UsuarioService
  ) { }


  ngOnInit() {
    this.email = localStorage.getItem('email') || '';
    if (this.email.length>1) {
      this.recuerdame = true;
      
    }

  }

  ingresar(forma: NgForm){
    
    if (forma.invalid) {
      return;      
    }

    let usuario = new Usuario(null, forma.value.email, forma.value.password) 
    
    this._usuarioService.login(usuario, forma.value.recuerdame).subscribe(correcto =>{
      if (this._usuarioService.usuario.role === 'ADMIN_ROLE') {
        this.router.navigateByUrl('/dashboard');  
      } else{
        this.router.navigateByUrl(`perfil/${this._usuarioService.usuario._id}` );  
      }
      
    })

  }

}



