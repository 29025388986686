import { EditorService } from './editor.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Usuario } from './../models/usuario.model';
import { URL_SERVICIOS } from './../config/global';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  usuario: Usuario;
  public notificacion = new EventEmitter<any>();

  token: string;
  constructor(
    public http: HttpClient,
    public _editorService: EditorService
  ) { this.token = _editorService.token }

  getTemplates() {
    let url = URL_SERVICIOS + '/template';
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp.templates
    }));
  }

  buscarTemplates(termino: string) {
    let url = URL_SERVICIOS + '/busqueda/coleccion/template/' + termino;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp.templates
    }))
  }

  getTemplate(id) {
    let url = URL_SERVICIOS + '/template/' + id;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      console.log(resp);

      return resp;

    }));
  }

  uploadTemplate(file:File){
    console.log(file);

    return new Promise((resolve, reject) => {
      console.log("test");

      let formData = new FormData();
      let xhr = new XMLHttpRequest();

      formData.append('imagen', file, file.name);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {

            resolve(JSON.parse(xhr.response));
            console.log(JSON.parse(xhr.response));
            
          } else {
            console.log("Fallo la subida");
            reject(xhr.response);
          }
        }
      }
      console.log(formData.get('imagen'))
      let url = URL_SERVICIOS + '/template/createTemplate';
      xhr.open('PUT', url, true);
      xhr.send(formData);

    });
  }

  deleteTemplate(id){    
    let url = URL_SERVICIOS + '/template/' + id;
    url += '?token=' + this.token;
    return this.http.delete(url).pipe(map((resp: any) => {
      console.log(resp);
      this.notificacion.emit(resp);

      return resp;

    }));
  }

}
