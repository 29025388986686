import { UsuarioService } from '../usuario.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class adminGuard implements CanActivate  {
  constructor(public _usuarioService: UsuarioService){}

  canActivate(){

    if (this._usuarioService.usuario.role === 'ADMIN_ROLE') {
      return true
    }else {
      this._usuarioService.logout()
      return false

    }
    
  }

}
