import { adminGuard } from './guard/admin.guard';
import { LoginGuard } from './guard/login.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2ImgMaxModule } from 'ng2-img-max';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Ng2ImgMaxModule
  ],
  providers:[
    LoginGuard,
    adminGuard
  ]
})
export class ServiceModule { }
