import { SessionModalService } from './../components/session-modal/session-modal.service';
import { Usuario } from './../models/usuario.model';
import { URL_SERVICIOS } from './../config/global';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuario: Usuario;
  token: string;


  constructor(public http: HttpClient,
    public router: Router,
    private _sessionModal: SessionModalService
  ) {
    this.cargarStorage()

  }

  cargarUsuario(desde: number = 0) {
    let url = URL_SERVICIOS + '/usuario?desde=' + desde;
    url += '?token=' + this.token;
    return this.http.get(url);
  }

  guardarStorage(id: string, token: string, usuario: Usuario) {
    localStorage.setItem('id', id);
    localStorage.setItem('token', token);
    localStorage.setItem('usuario', JSON.stringify(usuario));

    this.usuario = usuario;
    this.token = token;
  }

  estaLogueado() {
    return (this.token.length > 5) ? true : false
  }

  tokenError() {
    Swal.fire({
      icon: 'warning',
      title: 'Sesión finalizada',
      text: 'Por favor vuelva a iniciar sesión',
      html:
        `
      <div class="login-box card">
        <div class="card-body">

            <form ngNativeValidate #f="ngForm" class="form-horizontal form-material" (ngSubmit)="ingresar( f )">
                <div class="form-group m-t-40">
                    <div class="col-xs-12">
                        <input [(ngModel)]="email" name="email" id="email" class="form-control" type="email" required placeholder="Usuario">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input ngModel id="pass" name="password" class="form-control" type="password" required placeholder="Contraseña">
                    </div>
                </div>                                
             </form>
        </div>
    </div>
      `,
      
      showConfirmButton: true,
      preConfirm: () => {
        let email:any = (<HTMLInputElement>Swal.getPopup().querySelector('#email')).value;
        let pass = (<HTMLInputElement>Swal.getPopup().querySelector('#pass')).value;

        console.log(email);
        console.log(pass);
        let usuario = new Usuario(null, email, pass) 
         return this.login(usuario, false).subscribe( ress =>{
          console.log(ress);
          
          
          Swal.fire('Correcto','','success')    
          
                    
        },
        err =>{
          console.log(err);
          
          Swal.fire({title:'Incorrecto',text: 'Razon: Sos un inútil',icon: 'error', onClose: ()=>
        {
          this.tokenError();
        }
        
        })    
        }
        )
      }
        
    })
  }

  logout() {
    this.usuario = null;
    this.token = '';

    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    this.router.navigateByUrl('/login')
  }
  logoutOnInactive() {
    this._sessionModal.mostrarModal()
    this.usuario = null;
    this.token = '';

    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    
  //  this.tokenError();
  }

  loginGoogle(token: string) {
    let url = URL_SERVICIOS + '/login/google';
    return this.http.post(url, { token }).pipe(map((resp: any) => {
      this.guardarStorage(resp.id, resp.token, resp.usuario);
      return true
    }))
  }

  crearUsuario(usuario: Usuario) {
    let url = URL_SERVICIOS + '/usuario';
    return this.http.post(url, usuario)
      .pipe(map((resp: any) => {

        Swal.fire({
          icon: 'success',
          title: 'Tu Perfil se creo correctamente',
          text: 'Tu correo: ' + usuario.email,
          showConfirmButton: false,
          timer: 5000
        })
        return resp.Usuarios;
      }))
  }

  cargarStorage() {
    let p = new Promise((error)=>{

      if (localStorage) {
        console.log("funcion");
        
      }
      if (localStorage.getItem('token')) {
        
        this.token = localStorage.getItem('token');
        this.usuario = JSON.parse(localStorage.getItem('usuario'));
      } else {
        this.token = '';
        this.usuario = null
  
      }
    }).catch(err=>{
      console.log(err);
      Swal.fire('Error', 'Permiso de localStorage denegado, Por favor activa el permiso y recarga la pagina','error');
    })
    
    // p.then(()=>{
    //   console.log("oiko");
      
    // })
  }



  login(usuario: Usuario, recordarme: boolean) {

    if (recordarme) {
      localStorage.setItem('email', usuario.email);


    } else {
      localStorage.removeItem('email')
    }
    let url = URL_SERVICIOS + '/login'
    return this.http.post(url, usuario).pipe(map((resp: any) => {

      console.log(resp);

      this.guardarStorage(resp.id, resp.token, resp.usuario)
      return resp
    }

    )

    )
  }

  actualizarUsuario(usuario: Usuario) {

    let url = URL_SERVICIOS + '/usuario/' + usuario._id;
    url += '?token=' + this.token;
    return this.http.put(url, usuario).pipe(map((resp: any) => {

      if (usuario._id === this.usuario._id) {
        let usuarioDB: Usuario = resp.Usuarios;
        this.guardarStorage(usuarioDB._id, this.token, usuarioDB);
      }
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Usuario actualizado',
      //   showConfirmButton: true
      // });

      return true;
    }));
  }

  buscarUsuarios(termino: string) {
    let url = URL_SERVICIOS + '/busqueda/coleccion/usuario/' + termino;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp.usuarios

    }))
  }

  getUsuario(id) {
    let url = URL_SERVICIOS + '/usuario/' + id;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp.usuario;
    }))
  }

  borrarUsusario(id: string) {
    let url = URL_SERVICIOS + '/usuario/' + id;
    url += '?token=' + this.token;
    return this.http.delete(url);

  }

}
