import { SessionModalService } from './../components/session-modal/session-modal.service';
import { UsuarioService } from './../services/usuario.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor(private _usuarioService: UsuarioService,
    private _sessionModal: SessionModalService

    ) { 
    
  }

  logoutOnInactive(){
    // setTimeout(() => {
    //   this._sessionModal.mostrarModal();
    //   this._usuarioService.logoutOnInactive();

    // }, 600000);
  } 
  ngOnInit() {
    
  }

}
