import { MenuService } from './../../services/menu.service';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-menu-config',
  templateUrl: './menu-config.component.html',
  styleUrls: ['./menu-config.component.scss']
})
export class MenuConfigComponent implements OnInit {
  seccion = 'bloque'
  btnSecction: HTMLElement
  btnFila: HTMLElement
  btnCol: HTMLElement
  btnbloque: HTMLElement
  btnCard: HTMLElement

  constructor(
    public _menuService: MenuService
  ) { }

  @HostListener('document:mousedown', ['$event']) onMouseDown(e) {
    this._menuService.onMouseDown(e)
 
  }
  @HostListener('document:mousemove', ['$event']) onMouseMove(e) {
    this._menuService.onMouseMove(e)
    // console.log(e);

  }
  @HostListener('document:mouseup', ['$event']) onMouseup(e) {
    this._menuService.onMouseUp(e)
 
  }

 

  ngOnInit() {
    
    this._menuService.menuElement = document.getElementById('menu')
   
    this._menuService.onInit()
    // this.btnSecction = document.getElementById('btnSeccion').parentElement;
    // this.btnFila = document.getElementById('btnFila').parentElement;
    // this.btnCol = document.getElementById('btnCol').parentElement;
    // this.btnbloque = document.getElementById('btnBloque').parentElement;
    // this.btnCard = document.getElementById('btnCard').parentElement;

    // this._menuService.underline = document.getElementById('underline')
    // this._menuService.resaltado = document.getElementById('resaltado')
    // this._menuService.italics = document.getElementById('italics')
    // this._menuService.bold = document.getElementById('bold')
    // this._menuService.btnTextcenter = document.getElementById('center')
    // this._menuService.btnTextjustify = document.getElementById('justify')
    // this._menuService.btnTextright = document.getElementById('right')
    // this._menuService.btnLista = document.getElementById("list")
    // this._menuService.btnDisplay = document.getElementById('display1')
    // this._menuService.btnDisplayDos = document.getElementById('display2')
    // this._menuService.btnDisplayTres = document.getElementById('display3')
    // this._menuService.btnDisplayCuatro = document.getElementById('display4')
    // this._menuService.btnH1 = document.getElementById('btnH1')
    // console.log(this._menuService.btnH1);
    

    // this._menuService.btnH2 = document.getElementById('btnH2')
    // this._menuService.btnH3 = document.getElementById('btnH3')
    // this._menuService.btnH4 = document.getElementById('btnH4')
    // this._menuService.btnH5 = document.getElementById('btnH5')
    // this._menuService.btnEdit = document.getElementById('btnEdit')
    // this._menuService.btnAdd = document.getElementById('btnbtn');


  }

  cambiarSeccion(seccion) {
    this.seccion = seccion
    this.limpiador()
 
     
    switch (seccion) {
      case 'seccion':
        this.btnSecction.classList.add('active')
        break;
      case 'fila':
        this.btnFila.classList.add('active')
        break;
      case 'col':
         
        this.btnCol.classList.add('active')
        break;
      case 'card':
         
        this.btnCol.classList.add('active')
        break;
      case 'bloque':
        this.btnbloque.classList.add('active')
        break;

      default:
        break;
    }

  }
  limpiador() {
    this.btnSecction.classList.remove('active');
    this.btnFila.classList.remove('active');
    this.btnCol.classList.remove('active');
    this.btnbloque.classList.remove('active');
  }



}
