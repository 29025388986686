import { URL_SERVICIOS } from './../config/global';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

  transform(img: string, arr): any {

    // console.log(img);
    let tipo = arr[0] || ''
   

    let url = URL_SERVICIOS + '/img';
    if (!img) {
      return url + '/xxxx/xxx';

    }


    if (img.indexOf('https') >= 0) {
      return img;
    }


    switch (tipo) {
      case 'templates':
        url += '/templates/'+arr[1] +'/' + img;
        break;
      case 'pages':
        url = URL_SERVICIOS +  '/config/files/' + arr[1] + '/img/' + img;
        break;
      case 'usuario':
        url += '/usuario/' + img;
        break;



      default:
        break;
    }
    return url
  }
}
