import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalCollectionService {
  newObj 
  oculto = 'oculto'
  object
  name
  collectionName
  id
  images
  constructor() { }

  mostrarModal() {
    this.oculto = ''
  }
  
  setimages(images){
    this.images = images
  }

  setObject(object){
    this.object = object
  }
  getObject(){
    return this.object;
  }

  setName(name){
    this.name = name
  }
  getName(){
    return this.name;
  }

  setId(id){
    this.id = id
  }
  
  setNewObjectBoolean(newObj){
    this.newObj = newObj
  }
  getNewObjectBoolean(){
    return this.newObj 
  }

  getId(){
    return this.id;
  }

  ocultarModal() {
    this.oculto = 'oculto'
  }

 

}
