import { URL_SERVICIOS } from './../../config/global';
import { ConfigService } from './../../services/config.service';
import { ModalTemplateService } from './modal-template.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent implements OnInit {
  URL_SERVICIOS = URL_SERVICIOS
  constructor(public _modalTemplate: ModalTemplateService,
    public _configService: ConfigService

    ) { }

  ngOnInit() {
  }

  preview(url){
    window.open(url, '_blank').focus()
  }
}
