import { MenuConfigComponent } from './../components/menu-config/menu-config.component';
import { SidebarComponent } from './../shared/sidebar/sidebar.component';
import { ModalCollectionEditComponent } from './../components/modal-collection-edit/modal-collection-edit.component';
import { HeaderComponent } from './../shared/header/header.component';
import { SessionModalComponent } from './../components/session-modal/session-modal.component';
import { ModalTemplateComponent } from './../components/modal-template/modal-template.component';
import { ModalUploadComponent } from './../components/modal-upload/modal-upload.component';
import { ServiceModule } from './../services/service.module';
import { EditorComponent } from './editor/editor.component';
import { PagesComponent } from './pages.component';
import { PAGES_ROUTES } from './pages.route';
import { SharedModule } from './../shared/shared.module';
import { PipesModule } from './../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserComponent } from './user/user.component';
import { CrearProyectoComponent } from './crear-proyecto/crear-proyecto.component';
import { ModalSelectProyectComponent } from '../components/modal-select-proyect/modal-select-proyect.component';
import { PlantillasComponent } from './plantillas/plantillas.component';
import { SubirTemplateComponent } from './subir-template/subir-template.component';
import { AgregarElementoComponent } from '../components/agregar-elemento/agregar-elemento.component';
import { ModalGalleryComponent } from '../components/modal-gallery/modal-gallery.component';

@NgModule({
  declarations: [
    PagesComponent,
    EditorComponent,
    DashboardComponent,
    UserComponent,
    ModalUploadComponent,
    ModalTemplateComponent,
    SessionModalComponent,
    ModalCollectionEditComponent,
    ModalSelectProyectComponent,
    HeaderComponent,
    SidebarComponent,
    CrearProyectoComponent,
    PlantillasComponent,
    SubirTemplateComponent,
    MenuConfigComponent,
    AgregarElementoComponent,
    ModalGalleryComponent

  ],
  exports: [DashboardComponent,
    EditorComponent
    //FormsModule
  ],
  imports: [
    //FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    // SharedModule,
    PAGES_ROUTES,
    ServiceModule,
    FormsModule
  ],

})
export class PagesModule { }
