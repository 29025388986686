import { URL_SERVICIOS } from './../../config/global';
import { ModalUploadService } from './../modal-upload/modal-upload.service';
import { EditorService } from './../../services/editor.service';
import { ModalCollectionService } from './modal-collection.service';
import { Injectable, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-collection-edit',
  templateUrl: './modal-collection-edit.component.html',
  styleUrls: ['./modal-collection-edit.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class ModalCollectionEditComponent implements OnInit {
  object = null;
  newObj: boolean = false;
  id
  idImgToEdit
  folderNameSelected
  imagesFolderObj = new Array()
  imagesOfFolder
  imagesRoot
  imageOfFolder
  images
  mostrarImg = false;
  imgStateReady = false
  imagesFolderName = new Array()
  imageId
  constructor( 
    public _modalCollectionService: ModalCollectionService,
    public _modalUpload: ModalUploadService,
    public _editorService: EditorService

  ) { }

  ngOnInit() {
    
  }

  selectImage(id) {
    console.log(id);
    
    let element = document.getElementById(id)

    // let as: any = element.attributes;

    let imgToEdit = document.getElementById(this.imageId)
    // if (as.style) {
    //   element.removeAttribute('style');
    //   this.deleteImageBoolean = false;
    //   this.imageToDelete = '';
    //   return;
    // }

    // if (this.deleteImageBoolean) {
    //   document.getElementById(this.imageToDelete).removeAttribute('style');

    // }

    // element.setAttribute('style', 'border: dashed black 1px');
    // this.deleteImageBoolean = true;
    // this.imageToDelete = id;



    if (imgToEdit) {
      let url = URL_SERVICIOS + '/config/files/' + this.id + '/img/' + id
      imgToEdit.setAttribute('src', url)
    }

  }

  showImages(){
    this.mostrarImg = true;
    this.id = this._modalCollectionService.getId()

    console.log(this._modalCollectionService.images);
    this.images = this._modalCollectionService.images;
      for (let i = 0; i < this.images.length; i++) {
        const folder = this.images[i];

        if (folder.nombre == 'root') {
          this.imagesRoot = folder.archivos;
        } else if (folder.nombre && folder.nombre != 'root') {
          // for (let index = 0; index < this.imagesFolderName.length; index++) {
          //   const element = this.imagesFolderName[index];

          // }
          this.imagesFolderName.push(folder.nombre)
          this.imagesFolderObj.push(folder)
        }
      }
    

  }
  
  selectImageFolder(name) {
    for (let index = 0; index < this.imagesFolderObj.length; index++) {
      if (name == this.imagesFolderObj[index].nombre) {
        this.imagesOfFolder = this.imagesFolderObj[index].archivos
        this.imageOfFolder = true
        this.folderNameSelected = name
      }

    }
    if (name == 'root') {
      this.imageOfFolder = false;
      this.folderNameSelected = ''
      for (let i = 0; i < this.images.length; i++) {
        const folder = this.images[i];

        if (folder.nombre == 'root') {
          this.imagesRoot = folder.archivos;

        } else if (folder.nombre && folder.nombre != 'root') {

          //  this.imagesFolderName.push(folder.nombre)
          this.imagesFolderObj.push(folder)
        }
      }
    }

    ////console.log(this.imagesOfFolder);

  }

  loadForm(obj, name , configId, newObj?) {
    this._modalCollectionService.setName(name);
    this._modalCollectionService.setObject(obj);
    this._modalCollectionService.setId(configId);
    this._modalCollectionService.setNewObjectBoolean(newObj);

    this.newObj = newObj;
    console.log(newObj);
    
    let textArea = ``
    let img = ``
    for (var [key, value] of Object.entries(obj)) {
      let idObj = obj._id;
      if (key.indexOf('img')>=0 ) {
        console.log('hay una imagen');
        if (!value) {
          value = URL_SERVICIOS + '/img/xx/ss'
        }
        let name = key.replace('img_', '')
        img = ` <div class="form-group">  
                        <label>${name}</label>
                        <img id="${key}" class="w150"  src='${value}'></<img>
                        
                </div>
                <button id="cambiar_img_${name}" #name class="btn btn-primary" >Cambiar</button>                `
        document.getElementById('modalBody').insertAdjacentHTML('afterbegin', img);

      } else if (key === '_id') {

      } else if (key === '__v') {

      } else {
        textArea = ` <div class="form-group">
                        <label>${key}</label>
                        <div  class="form-control" rows="5">
                          <span  id="${key}" contenteditable="true" >${value}</span>
                        </div>
                     </div>`
        document.getElementById('modalBody').insertAdjacentHTML('afterbegin', textArea);
      }

    }
    this._modalCollectionService.mostrarModal()
    this.object = obj
    console.log(this.object);

  }
  onClick(e){
    console.log(e);
    if (e.target.id.indexOf('cambiar_img')>=0) {
      this.imageId = e.target.id.replace('cambiar_', '');
      this.showImages()
    }
  }

  borrarBody() {

    document.getElementById('modalBody').remove()
    let modalBody = document.createElement('div')
    modalBody.className = 'modal-body';
    modalBody.id = 'modalBody';
    document.getElementById('content').insertAdjacentElement('afterbegin', modalBody)

  }

   

  onChange(e) {
    console.log(e.target.textContent);

  }

  guardar() {

    this._modalCollectionService.ocultarModal()
    // console.log(document.getElementById('modalBody').innerHTML);






    let object = this._modalCollectionService.getObject();
    let name = this._modalCollectionService.getName();
    this.id = this._modalCollectionService.getId();

    // console.log(object);

    let array = new Array()
    let arrayKey = new Array()
    for (var [key, value] of Object.entries(object)) {
      let element = document.getElementById(key)

      if (element) {

        if (element.localName == 'img') {
          arrayKey.push(key)
          array.push(element.getAttribute('src'))
        }

        if (element.localName == 'span') {
          arrayKey.push(key)
          array.push(element.textContent)
        }
      }
    }
    let map = new Map();
    map.set('_id', object._id)
    for (let i = 0; i < array.length; i++) {
      const value = array[i];
      for (let j = 0; j < arrayKey.length; j++) {
        const key = arrayKey[j];
        if (i == j) {
          map.set(key, value)
        }
      }
    }
    // console.log(map);

    let obj = Array.from(map).reduce((obj, [key, value]) => (
      Object.assign(obj, { [key]: value }) // Be careful! Maps can have non-String keys; object literals can't.
    ), {});

    console.log(obj);
    console.log(name);

    this._editorService.updateObject(obj, name, this.id).subscribe(e =>{
      this._modalCollectionService.ocultarModal()
      this.borrarBody()
    })

    // array.map((value, index) =>{
    //   arrayKey.map((Key) =>{
    //     map.set(key, value);
    //   })
    // })
    // console.log(map);




  }

  addNew(){
    let object = this._modalCollectionService.getObject();
    let name = this._modalCollectionService.getName();
    let id = this._modalCollectionService.getId();

    let array = new Array()
    let arrayKey = new Array()
    for (var [key, value] of Object.entries(object)) {
      let element = document.getElementById(key)

      if (element) {

        if (element.localName == 'img') {
          arrayKey.push(key)
          array.push(element.getAttribute('src'))
        }

        if (element.localName == 'span') {
          arrayKey.push(key)
          array.push(element.textContent)
        }
      }
    }
    let map = new Map();
    for (let i = 0; i < array.length; i++) {
      const value = array[i];
      for (let j = 0; j < arrayKey.length; j++) {
        const key = arrayKey[j];
        if (i == j) {
          map.set(key, value)
        }
      }
    }

    let obj = Array.from(map).reduce((obj, [key, value]) => (
      Object.assign(obj, { [key]: value }) // Be careful! Maps can have non-String keys; object literals can't.
    ), {});
    this._editorService.newObject(obj, name, id).subscribe(e =>{
      this._modalCollectionService.ocultarModal()
      this.borrarBody()
    })

  }

  eliminar(){
    this._editorService.deleteDBCollection(this._modalCollectionService.object._id, this._modalCollectionService.getName(), this._modalCollectionService.id).subscribe(e=>{
      
      this._modalCollectionService.ocultarModal()
      this.borrarBody()
    })
  }
}
