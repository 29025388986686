import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionModalService {
  oculto = 'oculto';

  ocultarModal() {
    
    this.oculto = 'oculto';
  }
  constructor() { }


  mostrarModal() {
    
    this.oculto = '';
  }


}
