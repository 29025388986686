import { ConfigService } from './../../services/config.service';
import { URL_SERVICIOS } from './../../config/global';
import { UsuarioService } from './../../services/usuario.service';
//import { config } from 'rxjs';
import { EditorService } from './../../services/editor.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  configs
  usuarios  
  desde: number = 0;
  totalRegistros: number = 0;

  usuario
  cargado : boolean = false;
    url_servicios = URL_SERVICIOS
  
  constructor(public _editorService: EditorService,
    private _usuarioService: UsuarioService,
    private _configService: ConfigService
    ) {

 
    
   
  }

  getConfigs(){
    this._editorService.getConfig().subscribe((resp: any) => {
      this.configs = resp.config
      console.log(resp.config);
      this.cargado = true
    })
  }
  ngOnInit() {
    this.getConfigs();
    this._configService.notificacion.subscribe(resp => this.getConfigs() )
  }

  cambiarDesde(valor: number) {
    let desde = this.desde + valor
    // console.log(desde);

    if (desde >= this.totalRegistros) {
      return;
    }

    if (desde < 0) {
      return;
    }

    this.desde += valor;
    this.cargarUsuarios();
  }
  cargarUsuarios() {

    //  this.cargando = true;
    this._usuarioService.cargarUsuario(this.desde).subscribe((resp: any) => {
      // console.log(resp);
      this.totalRegistros = resp.totalUsuarios;
      this.usuarios = resp.Usuarios
      console.log(this.usuarios);

      //  this.cargando = false;
    })
  }

  buscarUsuario(termino: string) {
    if (termino.length <= 0) {
      this.usuarios = null
     // this.cargarUsuarios();
      return;
    }
    this._usuarioService.buscarUsuarios(termino).subscribe((usuarios) => {

      this.usuarios = usuarios
      console.log(usuarios);

    })
  }

  deleteConfig(id){
    console.log(id);
    
    this._configService.deleteConfig(id).subscribe(resp => console.log(resp));
  }



}
