import { EditorComponent } from './editor.component';
import { ElementoService } from './elemento.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DragService {
  elementSobre
  seArrastroAdentroArriba: boolean = false;
  seArrastroAdentroAbajo: boolean = false;
  seArrastroAdentroIzquierda: boolean = false;
  seArrastroAdentroDerecha: boolean = false;
  seArrastroAfueraArriba: boolean = false;
  seArrastroAfueraAbajo: boolean = false;
  seArrastroAfueraIzquierda: boolean = false;
  seArrastroAfueraDerecha: boolean = false;
  seArrastroAfueraDesdeAdentroDerecha: boolean = false;
  seArrastroAfueraDesdeAdentroIzquierda: boolean = false;
  elementoBlanco
  elementAuxiliar: HTMLElement
  bloqueSobre: HTMLElement
  leftInicial = 0;
  topInicial = 0;
  leftActual = 0;
  topActual = 0;
  indicador

  rowDeElementoArrastrado: HTMLElement
  colDeElementoArrastrado: HTMLElement

  typeOfTextEditable = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'i', 'b', 'u', 'li'];

  constructor(
    public _elementoService: ElementoService
  ) { }

  dragEnter(event) {

    if (event.target.id == 'indicador') {
      // this.permitirOver = false;
    } else {
      if (document.getElementById('indicador')) {
        document.getElementById('indicador').remove()
      }
    }



    this.topInicial = event.offsetY;
    this.leftInicial = event.offsetX;
    event.preventDefault();

  }

  dropElement(event) {

    if (this.elementAuxiliar && this.elementAuxiliar.firstElementChild) {
      if (this.elementAuxiliar.firstElementChild.hasAttribute('fantasma')) {
        this.elementAuxiliar.firstElementChild.remove();
      }
    }




    let elementDrop = this.selectorDeBloques(event.target)
    let fantasma = document.getElementsByClassName('fantasma')
    while (fantasma.length > 0) {

      fantasma.item(0).remove()

    }

    // let elementoQueTuvoFantasma = document.getElementsByClassName('contieneFantasma')
    // console.log(elementoQueTuvoFantasma.length);

    // for (let i = 0; i < elementoQueTuvoFantasma.length; i++) {
    //   const element = elementoQueTuvoFantasma.item(0);
    //   element.classList.remove('contieneFantasma')

    // }

    let pressed = document.getElementById(event.dataTransfer.getData('text'));
    let permitirMover = false;
    if (event.target.id.indexOf('col_') >= 0) {
      permitirMover = true;
    }
    if (event.target.id.indexOf('row_') >= 0) {
      permitirMover = true;
    }
    if (event.target.id.indexOf('bloque_') >= 0) {
      permitirMover = true;
    }
    if (event.target.id.indexOf('indicador') >= 0) {
      permitirMover = true;
      elementDrop = this.elementSobre;
    }

    let col_de_elementPressed = this.elementoBlanco.parentElement;
    let row_de_elementPressed = this.elementoBlanco.parentElement.parentElement;
    if (elementDrop.classList.contains(this.elementAuxiliar.id) || !permitirMover) {
      this.elementoBlanco.parentElement.replaceChild(this.elementAuxiliar, this.elementoBlanco)
      this.elementAuxiliar.removeAttribute('draggable')

      this.limpiarCondiciones()
      if (document.getElementById('indicador')) {
        document.getElementById('indicador').remove()
      }
      if (document.getElementById('imagenFantasma')) {
        document.getElementById('imagenFantasma').remove()
      }
      this.elementoBlanco = { id: '' }

      return;
    } else
      if (col_de_elementPressed && elementDrop.id != this.elementoBlanco.id) {

        let colHuerfano
        if (col_de_elementPressed.childNodes.length < 2) {
          if (this.elementoBlanco.parentElement.parentElement.classList.contains('row')) {
            console.log("si es row");


            let row: HTMLElement = this.elementoBlanco.parentElement.parentElement;
            if (row.childElementCount == 2) {
              row.childNodes.forEach((col: HTMLElement) => {
                if (col != this.elementoBlanco.parentElement) {

                  colHuerfano = col
                  // row_de_elementPressed = col.parentElement
                }
              })
            }
          }
          this.elementoBlanco.parentElement.remove()
        }

        if (colHuerfano) {
          this.reducirColYRowsQuitando(colHuerfano)
        }

      }
    console.log(row_de_elementPressed);

    if (row_de_elementPressed) {
      if (row_de_elementPressed.childElementCount < 1) {
        row_de_elementPressed.remove()
      }
    }



    if (this.seArrastroAdentroIzquierda) {
      this.colocarIzquierdaAdentro(elementDrop, pressed)
    }

    // ADENTRO ARRIBA
    if (this.seArrastroAdentroArriba) {

      this.colocarArribaAdentro(elementDrop);

    }
    // ADENTRO ABAJO
    if (this.seArrastroAdentroAbajo) {
      this.colocarAbajoAdentro(elementDrop);
    }

    // AFUERA IZQUIERDA
    if (this.seArrastroAfueraIzquierda) {
      this.colocarIzquierdaAfuera(elementDrop);
    }
    if (this.seArrastroAfueraDesdeAdentroIzquierda) {

      this.colocarIzquierdaAfuera(elementDrop.parentElement);
    }

    // AFUERA DERECHA
    if (this.seArrastroAfueraDerecha) {
      this.colocarDerechaAfuera(elementDrop);

    }
    if (this.seArrastroAfueraDesdeAdentroDerecha) {
      // this.volverDondeEstaba()
      this.colocarDerechaAfuera(elementDrop.parentElement);
    }

    // AFUERA ARRIBA
    if (this.seArrastroAfueraArriba) {
      this.colocarArribaAfuera(elementDrop);
    }
    // AFUERA ABAJO
    if (this.seArrastroAfueraAbajo) {
      this.colocarAbajoAfuera(elementDrop);
    }


    if (this.seArrastroAdentroDerecha) {

      this.colocarDerechaAdentro(elementDrop);
    }
    this.reducirColYRowsAgregando(elementDrop.id);

    // this.onMouseUp(element);
    this.elementAuxiliar.removeAttribute('draggable')

    if (document.getElementById('ghostImagen')) {
      document.getElementById('ghostImagen').remove()
    }
    if (document.getElementById('imagenFantasma')) {
      document.getElementById('imagenFantasma').remove()
    }
    this.limpiarCondiciones()
    if (document.getElementById('indicador')) {
      document.getElementById('indicador').remove()
    }
    this.elementoBlanco = { id: '' }
    this.elementAuxiliar.removeAttribute('draggable')
    event.preventDefault();
  }

  dragOver(event) {
    // element.target.style.opacity = .5;
    if (!this.elementoBlanco) {
      return
    }

    let elementOver = this.selectorDeBloques(event.target)
    this.topActual = event.offsetY;
    this.leftActual = event.offsetX;
    let indicadorTop = 0;
    let indicadorLeft = 0;
    let indicadorWidth = 0;
    let indicadorHeight = 0;

    let fantasma: any = document.createElement('div');
    fantasma.style.position = 'absolute';
    fantasma.style.top = '0 px'
    fantasma.style.left = '0 px'
    fantasma.style.width = elementOver.getBoundingClientRect().width + 'px'
    fantasma.style.height = elementOver.getBoundingClientRect().height + 'px'
    fantasma.style.zIndex = '1000';
    // fantasma.style.background = 'black';
    // fantasma.style.opacity = '0.2';
    fantasma.setAttribute('fantasma', '');
    fantasma.classList.add('fantasma');
    fantasma.id = elementOver.id;
    // if (!elementOver.classList.contains('contieneFantasma')) {
    //   elementOver.prepend(fantasma)
    //   elementOver.classList.add('contieneFantasma')
    // }
    if (elementOver.firstElementChild) {
      if (!elementOver.firstElementChild.hasAttribute('fantasma')) {
        elementOver.prepend(fantasma)
      } else {
        fantasma = elementOver.firstElementChild

      }
    } else if (elementOver.firstChild) {
      elementOver.prepend(fantasma)
    }

    console.log(elementOver);

    if (elementOver.id.indexOf('bloque') >= 0 && elementOver.id != this.elementoBlanco.id && this.elementoBlanco.id != ''
    ) {


      // elementOver.parentElement.style.padding = '40px'
      //                     IZQUIERDA

      if (this.leftActual > (fantasma.getBoundingClientRect().width * 0.1) && this.leftActual < (fantasma.getBoundingClientRect().width / 2) && this.topActual < fantasma.getBoundingClientRect().height * 0.75
        && this.topActual > fantasma.getBoundingClientRect().height * 0.25
      ) {
        this.limpiarCondiciones()
        indicadorTop = fantasma.getBoundingClientRect().top - fantasma.parentElement.getBoundingClientRect().top + 12;
        indicadorLeft = -6;
        indicadorWidth = 12;
        indicadorHeight = fantasma.getBoundingClientRect().height - 24;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.seArrastroAdentroIzquierda = true;
        this.elementSobre = elementOver;

        fantasma.appendChild(indicador);
        //                      DERECHA
      }

      if (this.leftActual > (fantasma.getBoundingClientRect().width / 2)
        && this.topActual < fantasma.getBoundingClientRect().height * 0.75
        && this.topActual > fantasma.getBoundingClientRect().height * 0.25
        && this.leftActual < fantasma.getBoundingClientRect().width * 0.9
      ) {
        this.limpiarCondiciones()
        indicadorTop = fantasma.getBoundingClientRect().top - fantasma.parentElement.getBoundingClientRect().top + 12;
        indicadorLeft = elementOver.getBoundingClientRect().width - 6;
        indicadorWidth = 12;
        indicadorHeight = fantasma.getBoundingClientRect().height - 24;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.elementSobre = elementOver;

        this.seArrastroAdentroDerecha = true;
        fantasma.appendChild(indicador);
      }
      //                           ARRIBA

      if (this.topActual < fantasma.getBoundingClientRect().height * 0.25) {
        this.limpiarCondiciones()
        indicadorTop = fantasma.getBoundingClientRect().top - fantasma.parentElement.parentElement.getBoundingClientRect().top - 6;
        this.elementSobre = elementOver;

        indicadorLeft = 16;
        indicadorWidth = fantasma.parentElement.getBoundingClientRect().width - 32;
        indicadorHeight = 12;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)

        let name = this.indicador.getAttribute('name')
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.indicador.setAttribute('name', 'arribaAdentro');
        this.seArrastroAdentroArriba = true
        fantasma.parentElement.appendChild(indicador);
      }

      //                                ABAJO
      if (this.topActual > fantasma.getBoundingClientRect().height * 0.75) {
        this.limpiarCondiciones()
        indicadorTop = fantasma.getBoundingClientRect().height + fantasma.getBoundingClientRect().top - fantasma.parentElement.parentElement.getBoundingClientRect().top - 6;

        indicadorLeft = 16;
        indicadorWidth = fantasma.getBoundingClientRect().width - 32;
        indicadorHeight = 12;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.seArrastroAdentroAbajo = true;
        fantasma.parentElement.append(indicador);
        this.elementSobre = elementOver;

      }


      //      izquierda afuera
      if (this.leftActual < fantasma.getBoundingClientRect().width * 0.1) {
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.limpiarCondiciones();
        indicadorTop = 12
        indicadorLeft = -6
        indicadorWidth = 12;
        indicadorHeight = fantasma.parentElement.parentElement.getBoundingClientRect().height - 24;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        this.seArrastroAfueraDesdeAdentroIzquierda = true;
        fantasma.parentElement.appendChild(indicador);
        this.elementSobre = elementOver;

      }
      //                derecha afuera
      if (this.leftActual > fantasma.getBoundingClientRect().width * 0.9) {
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.limpiarCondiciones()
        indicadorTop = 12;
        indicadorLeft = fantasma.parentElement.parentElement.getBoundingClientRect().width - 6;
        indicadorWidth = 12;
        indicadorHeight = fantasma.parentElement.parentElement.getBoundingClientRect().height - 24;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        this.seArrastroAfueraDesdeAdentroDerecha = true;
        fantasma.parentElement.appendChild(indicador);
        this.elementSobre = elementOver;

      }

      // ============================== AFUERA ============================== //

    }
    if (elementOver.id.indexOf('col_') >= 0) {

      //                              izquierda

      if (this.leftActual < (fantasma.getBoundingClientRect().width / 2)) {
        this.limpiarCondiciones()
        this.seArrastroAfueraIzquierda = true;

        indicadorTop = 12;
        indicadorLeft = -6;
        indicadorWidth = 12;
        indicadorHeight = fantasma.getBoundingClientRect().height - 24;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.elementSobre = elementOver;

        fantasma.appendChild(indicador);
      }
      //                              derecha

      if (this.leftActual > (fantasma.getBoundingClientRect().width / 2)) {
        this.limpiarCondiciones()
        indicadorTop = 12;
        indicadorLeft = fantasma.getBoundingClientRect().width - 6;
        indicadorWidth = 12;
        indicadorHeight = fantasma.getBoundingClientRect().height - 24;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.elementSobre = elementOver;

        fantasma.appendChild(indicador);
        this.seArrastroAfueraDerecha = true;
      }

    }

    if (fantasma.id.indexOf('row_') >= 0) {
      //                              arriba
      if (this.topActual < (fantasma.getBoundingClientRect().height / 2)) {
        this.limpiarCondiciones()

        indicadorTop = fantasma.getBoundingClientRect().top - fantasma.parentElement.getBoundingClientRect().top - 6;

        indicadorLeft = fantasma.getBoundingClientRect().left;
        indicadorWidth = fantasma.parentElement.getBoundingClientRect().width - 32;
        indicadorHeight = 12;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }

        this.seArrastroAfueraArriba = true
        let col = document.createElement('div');
        // indicador.id = ''
        col.classList.add('row');
        col.classList.add('my-3');
        col.appendChild(indicador)
        // col.id = 'indicador'
        fantasma.appendChild(col);
        this.elementSobre = elementOver;

      }

      //                              abajo
      if (this.topActual > (fantasma.getBoundingClientRect().height / 2)) {
        this.limpiarCondiciones()
        indicadorTop = fantasma.getBoundingClientRect().top - fantasma.parentElement.getBoundingClientRect().top + fantasma.getBoundingClientRect().height + 6;

        indicadorLeft = fantasma.getBoundingClientRect().left;
        indicadorWidth = fantasma.parentElement.getBoundingClientRect().width - 32;
        indicadorHeight = 12;
        let indicador = this.setIndicador(indicadorTop, indicadorLeft, indicadorWidth, indicadorHeight)
        if (document.getElementById('indicador')) {
          document.getElementById('indicador').remove()
        }
        this.elementSobre = elementOver;

        this.seArrastroAfueraAbajo = true
        let col = document.createElement('div');
        // indicador.id = ''
        col.classList.add('row');
        col.classList.add('my-3');
        col.appendChild(indicador)
        // col.id = 'indicador'
        fantasma.appendChild(col);
      }
    }

    event.preventDefault();

  }

  dragLeave(event) {

    event.preventDefault();
    // this.onMouseUp(element);

    // let elementoQueTuvoFantasma = document.getElementsByClassName('contieneFantasma')
    // console.log(elementoQueTuvoFantasma.length);

    // for (let i = 0; i < elementoQueTuvoFantasma.length; i++) {
    //   const element = elementoQueTuvoFantasma.item(0);
    //   element.classList.remove('contieneFantasma')

    // }
  }


  dragStart(event, textoEditable) {
    if (textoEditable) {

      return
    }
    this._elementoService.quitarBorde()
    event.dataTransfer.setData('text', event.target.id)
    // this.elementPressedTarget = element.target;
    console.log(event.target);
    


    var crt = event.target.cloneNode(true);
    crt.style.width = "300px";
    crt.style.height = "300px";
    crt.style.position = 'absolute';
    crt.style.left = '-400px'
    crt.style.top = '0px';
    crt.id = 'imagenFantasma';

    this.elementAuxiliar = event.target;

    document.body.appendChild(crt);
    event.dataTransfer.setDragImage(crt, 0, 0);
    let blanco = document.createElement('div');
    blanco.style.width = this.elementAuxiliar.getBoundingClientRect().width + 'px';
    blanco.style.height = this.elementAuxiliar.getBoundingClientRect().height + 'px';
    blanco.style.border = '2px solid #007bff';
    blanco.id = 'ghostImagen';
    blanco.classList.add(this.elementAuxiliar.id);
    blanco.style.backgroundColor = 'white';

    setTimeout(() => {
      this.elementAuxiliar.parentElement.replaceChild(blanco, this.elementAuxiliar);
      this.elementoBlanco = blanco

    }, 30);
  }

  colocarArribaAdentro(element) {

    this.cambiarPosicion(element, this.elementAuxiliar, true);

  }

  // AFUERA ARRIBA
  colocarArribaAfuera(element) {
    let row = this.crearRow(this.elementAuxiliar);
    this.cambiarPosicion(element, row, true)
  }

  // AFUERA ABAJO
  colocarAbajoAfuera(element) {
    let row = this.crearRow(this.elementAuxiliar);
    this.cambiarPosicion(element, row, false)
  }

  // AFUERA IZQUIERDA
  colocarIzquierdaAfuera(element) {

    let colNuevo = this.crearCol(this.elementAuxiliar);

    this.cambiarPosicion(element, colNuevo, true);

    this.reducirColYRowsAgregando(element.firstChild.id);

    // this.bloqueSobre.appendChild(row);
  }

  // AFUERA DERECHA
  colocarDerechaAfuera(element) {

    let colNuevo = this.crearCol(this.elementAuxiliar);

    this.cambiarPosicion(element, colNuevo, false);

    this.reducirColYRowsAgregando(element.firstChild.id);
  }

  // ADENTRO ABAJO
  colocarAbajoAdentro(element) {
    // this.removerVacio()

    // let div = document.createElement('div');
    // div.append(this.elementAuxiliar)
    this.cambiarPosicion(element, this.elementAuxiliar, false);

  }

  // ADENTRO IZQUIERDA
  colocarIzquierdaAdentro(element, pressed) {
    this.bloqueSobre = element;
    let id = element.id

    element.parentElement.replaceChild(this.colocarElementosCostadoAdentro(true), element);
    this.reducirColYRowsAgregando(id);

  }

  // ADENTRO DERECHA
  colocarDerechaAdentro(element) {
    this.bloqueSobre = element;
    let id = element.id

    element.parentElement.replaceChild(this.colocarElementosCostadoAdentro(false), element);
    this.reducirColYRowsAgregando(id)

  }


  limpiarCondiciones() {
    this.seArrastroAdentroIzquierda = false;
    this.seArrastroAdentroDerecha = false;
    this.seArrastroAdentroAbajo = false;
    this.seArrastroAdentroArriba = false;

    this.seArrastroAfueraAbajo = false;
    this.seArrastroAfueraArriba = false;
    this.seArrastroAfueraIzquierda = false;
    this.seArrastroAfueraDerecha = false;
    this.seArrastroAfueraDesdeAdentroDerecha = false;
    this.seArrastroAfueraDesdeAdentroIzquierda = false;


  }

  reducirColYRowsAgregando(id) {
    let colHijo: HTMLElement = document.getElementById(id).parentElement;
    let rowHijo = colHijo.parentElement;
    let colPadre = rowHijo.parentElement;
    let rowPadre = colPadre.parentElement;

    let elementoAbuelo = rowPadre.parentElement

    if (colPadre.classList.contains('col') && rowPadre.classList.contains('row')) {

      if (rowPadre.childNodes.length == 1) {

        if (colPadre.childNodes.length == 1) {
          console.log("reduciendo agregando");

          elementoAbuelo.replaceChild(rowHijo, rowPadre);
        }
      }
    }
  }


  reducirColYRowsQuitando(colSolitario: HTMLElement) {
    let rowPadre: HTMLElement
    let colPadre: HTMLElement
    let rowHijo: HTMLElement
    let colHijo: HTMLElement
    if (colSolitario.className.includes('col')) {
      colHijo = colSolitario;
      if (colHijo.parentElement.className.includes('row')) {
        rowHijo = colHijo.parentElement;
        if (rowHijo.parentElement.className.includes('col')) {
          colPadre = rowHijo.parentElement
          if (colPadre.parentElement.className.includes('row')) {


            rowPadre = colPadre.parentElement
            console.log(rowHijo.childElementCount);

            if (colPadre.childElementCount == 1 && rowHijo.childElementCount == 1) {
              // rowPadre.replaceWith(rowHijo)
              console.log("reduciendo Quitando");


              rowPadre.replaceChild(colHijo, colPadre)

            }

          }
        }
      }
    }
  }


  // cambia el orden de los elementos que se le pase, si es antes pone justo antes de colSobre si no coloca despues
  cambiarPosicion(colSobre: Element, colNuevo, antes: boolean) {
    let index = 0;

    let fin = []
    let elementsArray = colSobre.parentElement;
    let guardar: any = colSobre.parentElement.childNodes
    let auxiliar = [];

    while (guardar[index] != colSobre) {
      if (colNuevo == guardar[index] || colSobre == guardar[index]) {
        index++;
      } else {
        auxiliar.push(guardar[index]);
        index++;
      }
    }

    for (let i = auxiliar.length; i < guardar.length; i++) {
      if (colNuevo == guardar[i] || colSobre == guardar[i]) {
      } else {
        fin.push(guardar[i]);
      }
    }

    if (antes) {
      auxiliar.push(colNuevo);
      auxiliar.push(colSobre);

    } else {
      auxiliar.push(colSobre);
      auxiliar.push(colNuevo);
    }
    for (let i = 0; i < fin.length; i++) {
      const element = fin[i];
      auxiliar.push(element)
    }

    while (elementsArray.childNodes.length > 0) {
      elementsArray.lastChild.remove()
    }

    for (let i = 0; i < auxiliar.length; i++) {
      const element = auxiliar[i];
      elementsArray.appendChild(element)
    }

  }


  crearRow(elemento) {
    let row = document.createElement('div');
    let col = document.createElement('div');
    col.className = 'col-xs col-md';
    col.id = 'col_' + Math.floor((Math.random() * 10000) + 1);
    row.id = 'row_' + Math.floor((Math.random() * 10000) + 1);
    row.className = 'row my-3 pt-4';
    // this.removerVacio();
    col.appendChild(elemento);
    row.appendChild(col);
    return row;
  }
  crearCol(elemento) {

    let col = document.createElement('div');
    col.className = 'col-xs col-md'
    col.id = 'col_' + Math.floor((Math.random() * 10000) + 1);

    // this.removerVacio()
    // = this.elementAuxiliar;
    col.insertAdjacentElement('afterbegin', elemento);

    return col;
  }

  colocarElementosCostadoAdentro(izquierda) {
    let colOriginal = document.createElement('div');
    colOriginal.className = 'col-xs col-md';
    let row = document.createElement('div');
    row.className = 'row_ my-3 row';
    let colPressed = document.createElement('div');
    colPressed.className = 'col-xs col-md';
    colPressed.appendChild(this.elementAuxiliar);

    colOriginal.append(this.bloqueSobre.cloneNode(true));

    row.appendChild(colOriginal);

    this.cambiarPosicion(colOriginal, colPressed, izquierda);
    return row
  }


  setIndicador(top, left, width, height,) {
    let div = document.createElement('div');
    div.style.top = top + 'px';
    div.style.left = left + 'px';
    div.style.width = width + 'px';
    div.style.height = height + 'px';
    div.style.position = 'absolute';
    div.style.background = '#007bff';
    div.style.borderRadius = '30px';
    div.style.zIndex = '10';
    div.id = 'indicador';
    this.indicador = div;
    return div
  }


  selectorDeBloques(element) {
    let dragableElement: HTMLElement = document.createElement('div')
    let auxiliar = element
    if (element.nodeName == 'U' || element.nodeName == 'B' || element.nodeName == 'I') {
      while (auxiliar.nodeName == 'U' || auxiliar.nodeName == 'B' || auxiliar.nodeName == 'I') {
        auxiliar = auxiliar.parentElement;
      }
    }
    element = auxiliar;
    // CARD
    if (element.classList.contains('fantasma')) {
      dragableElement = element.parentElement;
    }
    else if (element.classList.contains('elementHr')) {
      dragableElement = element
    } else if (element.localName == 'hr') {
      dragableElement = element.parentElement;
    }
    else if (element.classList.contains('card-body')) {

      dragableElement = element.parentElement;

    }
    // texto dentro de card
    else if (element.classList.contains('card-text')
      || element.classList.contains('card-title')
      || element.classList.contains('card-subtitle')) {
      let card = element.parentElement;
      if (card.classList.contains('card-body')) {


        dragableElement = card.parentElement;


      }
    }

    else if (element.classList.contains('btn')) { //pregunta por boton
      if (element.parentElement.classList.contains('card-body') ||
        element.parentElement.classList.contains('card-header') ||
        element.parentElement.classList.contains('card-footer')) {
        let card = element.parentElement;
        if (card.classList.contains('card-body')) {


          dragableElement = card.parentElement;


        }
      } else if (element.classList.contains('separador')) {
        dragableElement = element;


      }
      else {
        dragableElement = element;

      }
    }

    // lista item
    else if (element.classList.contains('list-group-item')) {
      let list = element.parentElement
      dragableElement = list;

    }

    else if (element.localName == "td" || element.localName == "th") { //pregunta si lo seleccionado es tabla
      let tabla: HTMLElement = element.parentElement.parentElement.parentElement

      dragableElement = tabla


    }
    else if (element.classList.contains('separador')) {
      dragableElement = element;


    }

    //imagen
    else if (element.localName == 'img'){
      console.log(element);
      
      dragableElement = element.parentElement

    }
    // else if (element.classList.contains('elementImg')) {
    //   dragableElement = element
    // } else if (element.localName == 'img') {
    //   dragableElement  = element.parentElement;
    // }
    // texto cualquiera
    else if (this.typeOfTextEditable.includes(element.localName)) {


      dragableElement = element;


    }
    return dragableElement;
  }


  onMouseUp() {

  }




}

