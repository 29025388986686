import { UsuarioService } from './../usuario.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(public _usuarioService: UsuarioService,
    public router: Router
    ) {

  }
  canActivate(): boolean {


    if (this._usuarioService.estaLogueado()) {
      return true;
    } else{
      this.router.navigateByUrl('/login')
      return false
    }
    

    return true
  }
}
