import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElementoService {

  elemento
  
  constructor() { }

  quitarBorde() {

    if (this.elemento) {
      // this.elementoSeleccionado.removeAttribute('contenteditable')
      this.elemento.classList.remove('elementoConBorde')
      this.elemento = null;
    }
  }

}
