import { UsuarioService } from './../../services/usuario.service';
import { Usuario } from 'src/app/models/usuario.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  

  usuario : Usuario
  constructor(public _usuarioService: UsuarioService) { }

  

  ngOnInit() {
    this.usuario = this._usuarioService.usuario
        
  }
  logout(){
   this._usuarioService.logout();
  }
}
