import { EditorService } from './../../services/editor.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelecProyectModalService {

  img 
  nombre
  oculto = 'oculto'

  constructor(
    private _templateService: EditorService
  ) { }

  ocultarModal() {
    this.oculto = 'oculto'
  }

  mostrarModal(id) {
    this._templateService.getTemplate(id).subscribe((resp:any) =>{
      this.img = resp.template.img;
      this.nombre = resp.template.nombre;      
      
      
    })
    this.oculto = ''
  }

}
