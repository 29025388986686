import { Router } from '@angular/router';
import { UsuarioService } from './../../services/usuario.service';
import { Usuario } from './../../models/usuario.model';
import { NgForm } from '@angular/forms';
import { SessionModalService } from './session-modal.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-modal',
  templateUrl: './session-modal.component.html',
  styleUrls: ['./session-modal.component.scss']
})
export class SessionModalComponent implements OnInit {
email
  constructor(
    public _sessionModalService : SessionModalService,
    public router: Router,
    public _usuarioService: UsuarioService

  ) { }

  ngOnInit() {
  }

  ingresar(forma: NgForm){
    
    if (forma.invalid) {
      return;      
    }

    let usuario = new Usuario(null, forma.value.email, forma.value.password) 
    
    this._usuarioService.login(usuario, forma.value.recuerdame).subscribe(correcto =>{
      this._sessionModalService.ocultarModal()
      window.location.reload()
    })

  }
  
}
