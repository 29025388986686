import { map, retry } from 'rxjs/operators';
import { URL_SERVICIOS } from './../config/global';
import { HttpClient } from '@angular/common/http';
import { Config } from './../models/config.model';
import { Injectable, EventEmitter } from '@angular/core';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  usuario
  token
  public notificacion = new EventEmitter<any>();

  constructor(
    public http: HttpClient,
    private _usuarioService: UsuarioService
  ) {
    this.token = _usuarioService.token
    this.usuario = _usuarioService.usuario
  }


  crearConfig(template) {
    
    let codigo = template.contenido;    
    console.log("template",template);
    
    let config = new Config(
      template.nombre,
      codigo,
      this.usuario,
      template.nombre
    )

    console.log(config);
    
    let url = URL_SERVICIOS + '/html';
    url += '?token=' + this.token;
    return this.http.post(url, config).pipe(map((resp: any) => {
      console.log(resp);
      
      return resp;
      // url = URL_SERVICIOS + '/collection/cloneCollection/' + resp.tempName + '/' + resp.collectionName + '/' + resp.config._id;
      // url += '?token=' + this.token;
      //  this.http.get(url).pipe(map((res:any) =>{
        
      // })).subscribe(res=>{
      //   console.log(res);
      //   return res;
      // })
    }));
  }

  deleteConfig(id){
    let url = URL_SERVICIOS + '/config/' + id;
    url += '?token=' + this.token;
    return this.http.delete(url).pipe(map((resp: any) =>{
      this.notificacion.emit(resp);

      return resp;
    }))
  }
  


}
