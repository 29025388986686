import { ModalUploadService } from './../components/modal-upload/modal-upload.service';
 import { Injectable } from '@angular/core';
import { hrtime } from 'process';
import { ModalGalleryService } from './modal-gallery.service';
import { URL_SERVICIOS } from '../config/global';

@Injectable({
  providedIn: 'root'
})
export class AgregarElementoService {
  segmentoSeleccionado
  ocultar = 'oculto'

  offsetY
  offsetX
  menuElement
  id
  mouse

  constructor(public _modalUpload: ModalUploadService, public _modalGallery: ModalGalleryService) { }

  subirImagen() {
     

    // this._modalUpload.id = this.id
    this._modalUpload.mostrarModal(this.id);
    // this._modalUpload.oculto = '';

  }


  createCard() {

    let card = document.createElement('div');
    card.classList.add('card');
    card.classList.add('text-white');
    card.classList.add('bg-dark');
    card.style.width = '18rem'

    let body = document.createElement('div');
    body.setAttribute('type', 'card')

    body.classList.add('card-body');
    let title = document.createElement('h5');
    title.classList.add('card-title');
    title.textContent = 'Card title';
    let p = document.createElement('p');
    p.classList.add('card-text');
    p.classList.add('card-texct');
    p.classList.add('card-texsct');
    p.textContent = `Some quick example text to build on the card title and make up the bulk of the card's content.`;
    let btn = document.createElement('a');
    btn.href = 'javascript:null';
    btn.classList.add('btn-outline-light');
    btn.classList.add('btn');

    btn.textContent = 'Go somewhere';
    body.append(title)
    body.append(p)
    body.append(btn);
    card.append(body);
    let row = document.createElement('div');
    let col = document.createElement('div');
    row.classList.add('row')
    row.classList.add('py-3')
    col.classList.add('col-xs');
    col.classList.add('col-md');
    card.id = 'bloque_card_' + Math.floor((Math.random() * 10000) + 1);


    col.append(card);
    row.append(col);
    this.segmentoSeleccionado.appendChild(row)

  }
  onBtnbtn() {
    let boton = document.createElement("button")
    boton.classList.add("btn")
    boton.classList.add("btn-primary")
    boton.textContent = 'Button';
    //this.segmentoSeleccionado.appendChild(boton)
    let row = document.createElement('div');
    let col = document.createElement('div');

    col.classList.add('col-xs')
    col.classList.add('col-md')
    row.classList.add('row')
    row.classList.add('py-3')
    boton.id = 'bloque_boton_' + Math.floor((Math.random() * 10000) + 1);
    col.append(boton)
    row.append(col);
    this.segmentoSeleccionado.appendChild(row)

  }

  agregarSeparador() {
    let separador = document.createElement('div');
    let col = document.createElement('div');
    let row = document.createElement('div');

    separador.style.height = '150px';
    separador.style.width = '100%';
    //separador.style.boxShadow = 'inset 0 0 0 2px #D3D3D3'
    separador.id = 'bloque_separador_' + Math.floor((Math.random() * 10000) + 1);
    separador.classList.add('separador')
    col.append(separador);
    row.append(col)
    this.segmentoSeleccionado.append(row);

  }


  agregarHr() {
    let HR = document.createElement('div')
    let col = document.createElement('div')
    let row = document.createElement('div')

    HR.id = 'bloque_hr_' + Math.floor((Math.random() * 10000) + 1);
    HR.classList.add('elementHr')
    let elementoHr = document.createElement('hr')
    HR.style.paddingTop = '5px'
    HR.style.paddingBottom = '5px'

    HR.append(elementoHr);
    col.append(HR)
    row.append(col)
    this.segmentoSeleccionado.append(row)

  }



  onMouseUp(e) {
    this.mouse = e
    this.menuElement.style.cursor = ''
    this.menuElement.style.opacity = '1'

  }

  onMouseMove(event) {
    this.mouse = event

    if (this.mouse.which == 1 && this.mouse.target.classList.contains('dragmenu')) {
      this.menuElement.style.cursor = 'move'
      this.menuElement.style.opacity = '.7'
 

      this.menuElement.style.top = event.clientY - this.offsetY + 'px'

      this.menuElement.style.left = event.clientX - this.offsetX + 'px'
    } else {
      this.mouse.target.style.cursor = ''
    }


  }

  onMouseDown(event) {
    console.log(event.offsetX);

    this.offsetX = event.offsetX
    this.offsetY = event.offsetY

    this.mouse = event
    console.log(event);


  }


  createTable() {
    let body = document.getElementsByTagName("body")
    let tabla = document.createElement("table")
    let tbody = document.createElement("tbody")

    for (let i = 0; i < 2; i++) {
      let row = document.createElement("tr")

      for (let j = 0; j < 3; j++) {
        let col = document.createElement("td")
        let texto = document.createTextNode("cell")

        col.appendChild(texto)
        row.appendChild(col)
      }

      tbody.appendChild(row)
    }
    tabla.id = "bloque_tabla_" + Math.floor((Math.random() * 10000) + 1)
    tabla.append(tbody)
    tabla.classList.add("table")
    //document.getElementById("vista").appendChild(tabla)
    let col = document.createElement("div")
    col.classList.add("col")
    col.id = "col_" + Math.floor((Math.random() * 10000) + 1)

    let row = document.createElement("div")
    row.classList.add("row")
    row.id = "row_" + Math.floor((Math.random() * 10000) + 1)
    col.appendChild(tabla)
    row.appendChild(col)


    this.segmentoSeleccionado.appendChild(row)

  }

  ocultarVentanaAgregarElemento() {
    this.ocultar = 'oculto'
  }

  agregarTexto() {
    let row = document.createElement('div');
    let col = document.createElement('div');

    let textarea = document.createElement('h1');
    textarea.textContent = `Escriba un texto`;
    textarea.append(document.createElement('br'))
    textarea.style.outline = '0';
    textarea.classList.add('bloque')
    // textarea.setAttribute('contenteditable', 'true')
    col.className = ' col-xs col-md ';
    col.id = 'col_' + Math.floor((Math.random() * 10000) + 1);;
    row.className = ' row py-3 ';
    row.id = 'row_' + Math.floor((Math.random() * 10000) + 1);;
    col.appendChild(textarea);
    textarea.id = 'bloque_texto_' + Math.floor((Math.random() * 10000) + 1);

    // textarea.classList.add('text-white')
    // textarea.setAttribute('draggable', 'true')
    row.appendChild(col);
    console.log(this.segmentoSeleccionado)
    this.segmentoSeleccionado.appendChild(row);
 
  }
 
  addImage() {

    let row = document.createElement('div')
    let col = document.createElement('div')
    let imagen = document.createElement('div')
    imagen.id = 'bloque_img' + Math.floor((Math.random() * 10000) + 1);
    imagen.classList.add('elementImg')
    let elementoImg = document.createElement('img')
    this._modalGallery.mostralModalGallery(this.id);
    this._modalGallery.notificacion.subscribe(imagenAgregar => {
      //let url = URL_SERVICIOS+"/img/user/"+this.id+"/"+imagenAgregar
      elementoImg.src = imagenAgregar
      elementoImg.setAttribute("draggable","false")

    imagen.append(elementoImg)
    col.append(imagen)
    row.append(col)
    console.log(this.segmentoSeleccionado);
    
    this.segmentoSeleccionado.append(row)
    })
 
  }
 
}
