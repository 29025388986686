import { Component, OnInit } from '@angular/core';
import { ModalGalleryService } from 'src/app/services/modal-gallery.service';

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.scss']
})
export class ModalGalleryComponent implements OnInit {

  constructor(public _galleryService: ModalGalleryService) { }

  ngOnInit() {
  }

}
