import { Config } from './../../models/config.model';
import { EditorService } from './../../services/editor.service';
import { Usuario } from './../../models/usuario.model';
import { UsuarioService } from './../../services/usuario.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  usuario: Usuario
  configs
  constructor(
    private route: ActivatedRoute,
    private _editorService: EditorService,
    private _usuarioService : UsuarioService
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this._usuarioService.getUsuario(id).subscribe((resp : Usuario)=>{
        this.usuario = resp;
        console.log(resp);
        
        this._editorService.getConfigPorUsuario(id).subscribe((resp: any) =>{
          this.configs = resp
          console.log(resp);
          
        })
      });
    }

  }

}
