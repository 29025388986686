import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RegisterComponent } from './login/register.component';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './services/guard/login.guard';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { PagesComponent } from './pages/pages.component';
import { EditorComponent } from './pages/editor/editor.component';
import { Routes, RouterModule } from '@angular/router';


const appRoutes: Routes = [

    {
        path: 'login', component: LoginComponent
    }, 
    {
        path: 'register', component: RegisterComponent
    }, 
    // { path: 'editor', component: EditorComponent },

    // { path: 'pages', component: PagesComponent },


    { path: '', component: PagesComponent },
    { path: '**', component: PagesComponent }
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: false })