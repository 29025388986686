import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceModule } from './services/service.module';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { PagesModule } from './pages/pages.module';
import { APP_ROUTES } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { ModalUploadComponent } from './components/modal-upload/modal-upload.component';
import { ModalTemplateComponent } from './components/modal-template/modal-template.component';
import { SessionModalComponent } from './components/session-modal/session-modal.component';
import { ModalSelectProyectComponent } from './components/modal-select-proyect/modal-select-proyect.component';
import { ModalCollectionEditComponent } from './components/modal-collection-edit/modal-collection-edit.component';
import { MenuConfigComponent } from './components/menu-config/menu-config.component';
import { AgregarElementoComponent } from './components/agregar-elemento/agregar-elemento.component';
import { ModalGalleryComponent } from './components/modal-gallery/modal-gallery.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NopagefoundComponent,
    RegisterComponent,
    //ModalGalleryComponent,
    //AgregarElementoComponent,
    // MenuConfigComponent,
    // ModalCollectionEditComponent,
    //ModalSelectProyectComponent,
    //SessionModalComponent,
   // ModalTemplateComponent,
   // ModalUploadComponent,

    //EditorComponent,
    // PagesComponent

  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    APP_ROUTES,
    FormsModule,
    HttpClientModule, ReactiveFormsModule,
    PagesModule,
    ServiceModule,
    

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
