import { SubirArchivoService } from './../../services/subir-archivo.service';
import { ModalUploadService } from './modal-upload.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})
export class ModalUploadComponent implements OnInit {
  file
  imagenSubir: File;
  imagenTemp: string | ArrayBuffer;

  constructor(public _subirArchivoService: SubirArchivoService,
    public _modalUploadService: ModalUploadService
  ) {

  }

  ngOnInit() {
    
  }

  // seleccionImagen() {
    
  //   if (!archivo && !this._modalUploadService.file) {
  //     this.imagenSubir = null;
  //     return
  //   }
    
  //   if (archivo) {
  //     if (archivo.type.indexOf('image') < 0) {
  //       Swal.fire('Solor imagenes', "El archivo seleccionado no es una imagen", 'error')
  //       this.imagenSubir = null
  //       return
  //     }  else{
  //       this.imagenSubir = archivo
  //     }
  //   }
  //   if (this._modalUploadService.file) {
  //     if (this._modalUploadService.file.type.indexOf('image')<0) {
  //       Swal.fire('Solor imagenes', "El archivo seleccionado no es una imagen", 'error')
  //         this.imagenSubir = null
  //         return
  //     } else{
  //       this.imagenSubir = this._modalUploadService.file
  //     } 
  //   }
    
  //   // this.imagenSubir = archivo || this._modalUploadService.file;
  //   let reader = new FileReader();
  //   let urlImagenTemp = reader.readAsDataURL(archivo);

  //   reader.onloadend = () => this.imagenTemp = reader.result;
  // }

  // subirImagen() {
  //   this._subirArchivoService.subirArchivo(this.imagenSubir,
  //      this._modalUploadService.id)
  //     .then(resp => {
        
  //       Swal.fire('Imagen subida correctamente','', 'success')
  //       this._modalUploadService.notificacion.emit(resp);
  //       if (this._modalUploadService.isNotificaion2) {
  //         this._modalUploadService.notificacion2.emit(resp);  
  //       }
        
  //       //this._modalUploadService.ocultarModal();
  //       this.cerrarModal();

  //     }).catch(err => {
  //       Swal.fire('Error al subir la imagen' , err.mensaje, 'error');
        
        
  //     })
  // }

  cerrarModal() {
    this.imagenTemp = null;
    this.imagenSubir = null;
    this._modalUploadService.ocultarModal();

  }

}
