import { Card } from './../models/card.model';
import { HostListener, Injectable } from '@angular/core';
import { AgregarElementoService } from './agregar-elemento.service';
import { ModalGalleryService } from './modal-gallery.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  segmentoSeleccionado: HTMLElement
  elementoSeleccionado: HTMLElement
  menuElement: HTMLElement

  modificarOffset = false;
  allowDrag = true;
  indicador;
  permitirRedimensionar = false;
  colEnBp = 0; // tamaño de columna en Bootstrap
  colEnPx = 0; // tamano de columna en pixeles
  offsetEnBp = 0; // tamano de offset en Bootstrap
  clase_col_a_modificar = 0;
  col_a_redimensionar
  clase_offset_a_modificar = 0;
  seccion = 'bloque'
  btnSecction: HTMLElement
  btnFila: HTMLElement
  btnCol: HTMLElement
  btnbloque: HTMLElement
  btnCard: HTMLElement


  offsetY
  offsetX

  redimensionarLadoIzquierdo = false;
  elementAuxiliar // elemento que se esta arrastrando
  permitirOver = true;
  elementSobre // elemento donde se esta soltando
  permitirSoltarElemento = false;
  elementSelected
  textSelection: Selection
  underline
  resaltado
  bold
  italics
  btnTextcenter
  btnTextjustify
  btnTextright
  btnEdit
  card: Card
  puntoIzquierdo
  puntoDerecho
  puntoAbajo
  showMenu = ''
  cardTitleCheck
  cardSubtitleCheck
  cardTextCheck
  cardButtonCheck
  listaBloques = ['text', 'card']
  cardSelectedTitle
  cardSelectedText
  cardSelectedSubtitle
  cardSelectedButton

  cardSelectedList
  cardListCheck

  cardListcheckBoolean

  cardImagecheckBoolean = false;
  cardImageCheck
  cardSelectedImage

  id

  cardTextcheckBoolean = false;
  cardTitlecheckBoolean = false;
  cardSubtitlecheckBoolean = false;
  cardButtoncheckBoolean = false;

  typeOfTextEditable = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'i', 'b', 'u', 'li'];
  clasesDeComponentes = []

  cardSelected

  tipo_de_elemento_seleccionado

  btnLista
  btnDisplay
  btnDisplayDos
  btnDisplayTres
  btnDisplayCuatro
  textoEditable

  btnAdd

  buttonlargeCheckBoolean = false;

  listaDeColores = ['primary', 'secondary', 'dark', 'success', 'info', 'light', 'warning', 'danger', 'muted']

  btnH1
  btnH2
  btnH3
  btnH4
  btnH5
  botonSeleccionado: HTMLElement

  elementoOverPath

  btnBlockClass = 'btn-secondary'
  btnSmallClass = 'btn-secondary'
  btnMediumClass = 'btn-secondary'
  btnLargeClass = 'btn-secondary'

  elementoDraggable: HTMLElement
  liSeleccionado: HTMLElement

  tipoBloque
  tablaCheck
  tableCheckedBoolean = false
  tableSelectedText
  tableSelected

  elementoParaBreadCrumb: string

  botonTableDark = 'btn-secondary'
  botonTableStriped = 'btn-secondary'
  botonTableBordered = 'btn-secondary'
  botonTableBorderless = 'btn-secondary'
  botonTableHover = 'btn-secondary'
  botonTableSmall = 'btn-secondary'

  ocultar = 'oculto'

  textoDelBoton: string



  paddingRow = {
    pt: 0,
    pb: 0,
    pl: 0,
    px: 0,
    pr: 0,
    py: 0,
    p: 0
  }

  paddingCol = {
    pt: 0,
    pb: 0,
    pl: 0,
    px: 0,
    pr: 0,
    py: 0,
    p: 0
  }
  paddingBloque = {
    pt: 0,
    pb: 0,
    pl: 0,
    px: 0,
    pr: 0,
    py: 0,
    p: 0
  }
  marginBloque = {
    mt: 0,
    mb: 0,
    ml: 0,
    mr: 0,
    m: 0
  }

  paddingElementos = {
    pt: 0,
    pb: 0,
    pl: 0,
    pr: 0,
    p: 0
  }


  columnaSeleccionada: HTMLElement
  ptColSelected: number = 0
  pbColSelected: number = 0
  plColSelected: number = 0
  pxColSelected: number = 0
  prColSelected: number = 0
  pyColSelected: number = 0
  pcolumnaSeleccionada: number = 0

  rowSeleccionado: HTMLElement
  ptRowSelected: number = 0
  mouse

  alturadeSeparador: number

  ruta: Array<string> = ['seccion', 'fila', 'columna']

  anchodeCard: number

  columnaSize: number
  NewColumnaSize: number




  constructor(public _agregarElementoService: AgregarElementoService, public _galeryService: ModalGalleryService) { }

  onInit() {
    this.menuElement = document.getElementById('menu');
    console.log(this.menuElement);

  }

  onClick(event) {
    // this.limpiadorDeColores()
    // console.log(this.textoEditable);

    // if (this.textoEditable) {

    //   // this.comprobarConfiguracionDeTexto(this.textoEditable);
    // }

    // this.textoEditable = this.textoEditable;
  }

  onMouseUp(e) {
    this.mouse = e
    this.menuElement.style.cursor = ''
    this.menuElement.style.opacity = '1'

  }

  onMouseMove(event) {
    this.mouse = event

    if (this.mouse.which == 1 && this.mouse.target.classList.contains('dragmenu')) {
        this.menuElement.style.cursor = 'move'
      this.menuElement.style.opacity = '.7'
 
      this.menuElement.style.top = event.clientY - this.offsetY + 'px'

      this.menuElement.style.left = event.clientX - this.offsetX + 'px'
    } else {
      this.mouse.target.style.cursor = ''
    }


  }

  onMouseDown(event) {
 
    this.offsetX = event.offsetX
    this.offsetY = event.offsetY

    this.mouse = event
 

  }

  seccionBackGroundColor(color) {


    // let elementos = document.getElementsByClassName('bg-light');

    // let permitidos = []

    // for (let i = 0; i < elementos.length; i++) {
    //   const element = elementos.item(i);
    //   console.log(element);

    //   let arreglo = this.breadcrumb(element);
    //   console.log(arreglo);

    //   if (arreglo.includes(this.segmentoSeleccionado)) {
    //     permitidos.push(element);
    //   }
    // }

    // permitidos.forEach((element:HTMLElement) => {
    //   element.classList.remove('bg-light')
    // })



    switch (color) {
      case 'dark':
        this.segmentoSeleccionado.parentElement.classList.add('bg-dark');
        this.segmentoSeleccionado.parentElement.classList.add('text-white');
        break;
      case 'white':
        this.segmentoSeleccionado.parentElement.classList.remove('bg-dark');
        this.segmentoSeleccionado.parentElement.classList.add('bg-light');
        this.segmentoSeleccionado.parentElement.classList.remove('text-white');
        break;
      case 'light':
        this.segmentoSeleccionado.parentElement.classList.remove('bg-dark');
        this.segmentoSeleccionado.parentElement.classList.remove('bg-light');
        this.segmentoSeleccionado.parentElement.classList.remove('text-white');
        break;



      default:
        break;
    }


    // if (color == 'dark') {
    //   this.segmentoSeleccionado.parentElement.classList.add('bg-dark');
    //   this.segmentoSeleccionado.parentElement.classList.add('text-white');
    // } else {
    //   this.segmentoSeleccionado.parentElement.classList.remove('bg-dark');
    //   this.segmentoSeleccionado.parentElement.classList.add('bg-light');
    //   this.segmentoSeleccionado.parentElement.classList.remove('text-white');
    // }

  }

  comprobarConfiguracionDeTexto(textoEditable) {

    if (textoEditable.classList.contains("lead")) {
      let lead = document.getElementById("lead")
      lead.classList.add("btn-dark")
      lead.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("text-center")) {
      this.btnTextcenter.classList.add("btn-dark")
      this.btnTextcenter.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("text-justify")) {
      this.btnTextjustify.classList.add("btn-dark")
      this.btnTextjustify.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("text-right")) {
      this.btnTextright.classList.add("btn-dark")
      this.btnTextright.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-1")) {
      this.btnDisplay.classList.add("btn-dark")
      this.btnDisplay.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-2")) {
      this.btnDisplayDos.classList.add("btn-dark")
      this.btnDisplayDos.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-3")) {
      this.btnDisplayTres.classList.add("btn-dark")
      this.btnDisplayTres.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-4")) {
      this.btnDisplayCuatro.classList.add("btn-dark")
      this.btnDisplayCuatro.classList.remove("btn-secondary")
    }

    // texto seleccionado
    var selection = window.getSelection();

    if (selection.toString()) {
      if (selection.focusNode.parentNode.nodeName == 'U'
        || selection.focusNode.parentNode.parentNode.nodeName == 'U'
        || selection.focusNode.parentNode.parentNode.parentNode.nodeName == 'U') {
        this.underline.classList.remove('btn-secondary')
        this.underline.classList.add('btn-dark')

      }
      if (selection.focusNode.parentNode.nodeName == 'B'
        || selection.focusNode.parentNode.parentNode.nodeName == 'B'
        || selection.focusNode.parentNode.parentNode.parentNode.nodeName == 'B') {
        this.bold.classList.remove('btn-secondary')
        this.bold.classList.add('btn-dark')
      }
      if (selection.focusNode.parentNode.nodeName == 'I'
        || selection.focusNode.parentNode.parentNode.nodeName == 'I'
        || selection.focusNode.parentNode.parentNode.parentNode.nodeName == 'I') {
        this.italics.classList.remove('btn-secondary')
        this.italics.classList.add('btn-dark')
      }
    }

    if (textoEditable.localName == 'h1') {

      this.switchToDark(this.btnH1)
    }
    if (textoEditable.localName == 'h2') {
      this.switchToDark(this.btnH2)
    }

    if (textoEditable.localName == 'h3') {
      this.switchToDark(this.btnH3)
    }

    if (textoEditable.localName == 'h4') {
      this.switchToDark(this.btnH4)
    }

    if (textoEditable.localName == 'h5') {
      this.switchToDark(this.btnH5)
    }


  }

  limpiadorDeColores() {

    this.switchToSecondary(this.underline)

    this.switchToSecondary(this.italics)

    this.bold.classList.add('btn-secondary');
    this.bold.classList.remove('btn-dark');
    this.switchToSecondary(this.bold)

    this.btnTextcenter.classList.remove('btn-dark')
    this.btnTextcenter.classList.add('btn-secondary')

    this.btnTextjustify.classList.remove('btn-dark')
    this.btnTextjustify.classList.add('btn-secondary')


    this.btnTextright.classList.remove('btn-dark')
    this.btnTextright.classList.add('btn-secondary')

    this.btnDisplay.classList.remove('btn-dark')
    this.btnDisplay.classList.add('btn-secondary')
    this.btnDisplayDos.classList.remove('btn-dark')
    this.btnDisplayDos.classList.add('btn-secondary')
    this.btnDisplayTres.classList.remove('btn-dark')
    this.btnDisplayTres.classList.add('btn-secondary')
    this.btnDisplayCuatro.classList.remove('btn-dark')
    this.btnDisplayCuatro.classList.add('btn-secondary')

    this.btnAdd.classList.remove("btn-dark")
    this.btnAdd.classList.add("btn-secondary")
    this.switchToSecondary(this.btnH1);
    this.switchToSecondary(this.btnH2);

    this.switchToSecondary(this.btnH3)
    this.switchToSecondary(this.btnH4)
    this.switchToSecondary(this.btnH5)

  }

  breadcrumb(e) {
    let elemento = e
    let arreglo = []
    arreglo.push(elemento)
    for (let i = 0; i < 50; i++) {
      if (elemento.classList.length > 0) {
        if (elemento.classList.contains('row')) {
          i = 51;
        } else {
          elemento = elemento.parentElement;
          arreglo.push(elemento);
        }
      } else {
        elemento = elemento.parentElement;
        arreglo.push(elemento)
      }
    }
    return arreglo;

  }




  onChangePaddingTop(clase, numero, elemento) {
    console.log(numero);

    let index = elemento.className.indexOf(clase)
    if (numero <= 5 && numero >= 0) {
      if (index >= 0) {
        let size = elemento.className.charAt(index + 3)
        elemento.classList.remove(clase + size)
        console.log("no deberia de mostrar");

      }
      elemento.classList.add(clase + numero)
      console.log(elemento.className);

      if (numero == 0) {
        elemento.classList.remove(clase + "0")
      }
    }

  }

  paddingGeneral(elemento, paddingValue) {
    if (paddingValue >= 0 && paddingValue <= 5) {
      let arreglo = ["pt-", "pl-", "pb-", "px-", "pr-", "py-"] //guardo en un array los nombres que puede tener p
      arreglo.forEach(element => { //recorro con un for el arreglo
        let index = elemento.className.indexOf(element)
        if (index >= 0) {
          elemento.classList.remove(element + parseInt(elemento.className.charAt(index + 3)))
        }
      });
      let ind = elemento.className.indexOf("p-")
      elemento.classList.remove("p-" + elemento.className.charAt(ind + 2))
      elemento.classList.add("p-" + paddingValue)
    }
  }


  menuRouter(element: HTMLElement) {
    console.log(element)
    let arregloRuta = this.breadcrumb(element)
    let auxiliar = element

    if (element.nodeName == 'U' || element.nodeName == 'B' || element.nodeName == 'I') {
      while (auxiliar.nodeName == 'U' || auxiliar.nodeName == 'B' || auxiliar.nodeName == 'I') {
        auxiliar = auxiliar.parentElement;
      }
    }
    element = auxiliar;
    // CARD
    if (element.classList.contains('card-body')) {

      // this.tipo_de_elemento_seleccionado = 'card'
      this.desSeleccionarElemento()


      this.elementoSeleccionado = element;
      this.elementoParaBreadCrumb = 'card'
      this.elementoDraggable = element.parentElement;
      this.columnaSeleccionada = this.elementoDraggable.parentElement
      this.configPadding(this.columnaSeleccionada, this.paddingCol)
      this.rowSeleccionado = this.columnaSeleccionada.parentElement
      this.configPadding(this.rowSeleccionado, this.paddingRow)
      this.ocultar = ''
      this.reconocerEspaciados(this.elementoDraggable) //////////////////////////////////////////////////////////////////////////////////
      this.onCardBody(element)
      console.log(element, 'seleccionar');
      

      this.reconocerColumnaSize(this.columnaSeleccionada)

      this.ruta = ['seccion', 'fila', 'columna', 'card']
      this.seccion = "card"


      this.anchodeCard = Math.floor((this.elementoDraggable.getBoundingClientRect().width * 100)/(this.columnaSeleccionada.getBoundingClientRect().width -(this.columnaSeleccionada.getBoundingClientRect().width*0.06 )))

      if (element.classList.contains('align-items-end')) {

      }

    }
    else if (element.classList.contains('card-img-top')){

      
        
        this.cardImagecheckBoolean = true;
        this.cardSelectedImage = element
      
      

      this.ruta = ['seccion', 'fila', 'columna', 'card', 'cardImg']
      this.seccion= "cardImg"
      this.ocultar=''
      this.elementoSeleccionado = element
      this.elementoDraggable = element.parentElement

    }

    else if (element.classList.contains('separador')) {
      this.elementoSeleccionado = element
      this.elementoDraggable = element
      this.seccion = 'separador'
      this.alturadeSeparador = element.getBoundingClientRect().height
      this.ruta = ['seccion', 'fila', 'columna', 'separador']
      this.ocultar = ''
      this.reconocerEspaciados(this.elementoDraggable) ///////////////////////////////////////////////////////////////////////////////////

      this.reconocerColumnaSize(this.elementoDraggable.parentElement)
    }

    // texto dentro de card
    else if (element.classList.contains('card-text')
      || element.classList.contains('card-title')
      || element.classList.contains('card-subtitle')) {

      let card = element.parentElement;
      if (card.classList.contains('card-body')) {
        this.tipo_de_elemento_seleccionado = 'card'

        this.ruta = ['seccion', 'fila', 'columna', 'card', 'texto']
        this.seccion = 'texto'
        this.ocultar = ''

        if (arregloRuta.includes(this.elementoSeleccionado)) {

          if (this.textoEditable && element != this.textoEditable) {
            this.textoEditable.removeAttribute('contenteditable')
          }
          element.setAttribute('contenteditable', 'true')
          this.textoEditable = element;
        }
        if (this.elementoSeleccionado != card) {
          this.desSeleccionarElemento()
          this.elementoSeleccionado = card;
          this.elementoDraggable = card.parentElement;

          this.anchodeCard = Math.floor((this.elementoDraggable.getBoundingClientRect().width * 100)/(this.columnaSeleccionada.getBoundingClientRect().width -(this.columnaSeleccionada.getBoundingClientRect().width*0.06 )))
          this.columnaSeleccionada = this.elementoDraggable.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol)
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)

          this.reconocerColumnaSize(this.columnaSeleccionada)

        }

        this.onCardBody(card)

      }
    }

    else if (element.classList.contains('btn')) { //pregunta por boton
      if (element.parentElement.classList.contains('card-body') ||
        element.parentElement.classList.contains('card-header') ||
        element.parentElement.classList.contains('card-footer')) {
        let card = element.parentElement;
        if (card.classList.contains('card-body')) {
          this.desSeleccionarElemento()

          this.elementoSeleccionado = element;
          this.tipo_de_elemento_seleccionado = 'button'
          this.ruta = ['seccion', 'fila', 'columna', 'card', 'button']
          this.seccion = 'button'
          this.ocultar = ''


          this.botonSeleccionado = element;
          this.onConfigBoton(element) // llama a la funcion de configurar botones

          this.textoDelBoton = this.botonSeleccionado.textContent

          this.columnaSeleccionada = this.botonSeleccionado.parentElement.parentElement.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol)
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)

          this.reconocerColumnaSize(this.columnaSeleccionada)



        }
      }
      else {
        this.desSeleccionarElemento()
        this.botonSeleccionado = element;

        this.onConfigBoton(element) // llama a la funcion de configurar botones

        this.textoDelBoton = this.botonSeleccionado.textContent

        this.columnaSeleccionada = this.botonSeleccionado.parentElement
        this.configPadding(this.columnaSeleccionada, this.paddingCol)
        this.rowSeleccionado = this.columnaSeleccionada.parentElement
        this.configPadding(this.rowSeleccionado, this.paddingRow)
        this.ruta = ['seccion', 'fila', 'columna', 'button']
        this.seccion = 'button'
        this.ocultar = ''
        this.elementoDraggable = this.botonSeleccionado

        this.reconocerColumnaSize(this.columnaSeleccionada)
      }
    }

    // lista item
    else if (!this.typeOfTextEditable.includes(element.parentElement.parentElement.localName) && element.classList.contains('list-group-item')) {
      this.tipo_de_elemento_seleccionado = 'lista'
      this.ruta = ['seccion', 'fila', 'columna', 'lista']
      this.seccion = 'lista'
      this.ocultar = ''


      let list = element.parentElement
      if (arregloRuta.includes(this.elementoSeleccionado)) {
        if (this.textoEditable && element != this.textoEditable) {
          this.textoEditable.removeAttribute('contenteditable')
        }

        this.textoEditable = element;
        this.textoEditable.setAttribute('contenteditable', 'true')
        this.ruta = ['seccion', 'fila', 'columna', 'lista', 'texto']
        this.seccion = 'texto'
        this.ocultar = ''
      }
      if (this.elementoSeleccionado != list) {
        this.desSeleccionarElemento()
        this.elementoSeleccionado = list

        this.columnaSeleccionada = this.elementoSeleccionado.parentElement
        this.configPadding(this.columnaSeleccionada, this.paddingCol)
        this.rowSeleccionado = this.columnaSeleccionada.parentElement
        this.configPadding(this.rowSeleccionado, this.paddingRow)

      }

      this.elementoDraggable = this.elementoSeleccionado
      this.reconocerColumnaSize(this.columnaSeleccionada)




    } else if (element.localName == "td") { //pregunta si lo seleccionado es tabla
      //console.log("entra")
      let tabla: HTMLElement = element.parentElement.parentElement.parentElement
      this.tipo_de_elemento_seleccionado = 'tabla'
      this.ruta = ['seccion', 'fila', 'columna', 'tabla']
      this.seccion = 'tabla'
      this.ocultar = ''


      if (arregloRuta.includes(this.elementoSeleccionado)) {
        if (this.textoEditable && element != this.textoEditable) {
          this.textoEditable.removeAttribute('contenteditable')
        }

        this.textoEditable = element;
        this.textoEditable.setAttribute('contenteditable', 'true')
        this.ruta = ['seccion', 'fila', 'columna', 'tabla', 'texto']
        this.seccion = 'texto'
        this.ocultar = ''
      }
      if (this.elementoSeleccionado != tabla) {
        this.desSeleccionarElemento()
        this.elementoSeleccionado = tabla
        this.configTable(tabla)

        this.columnaSeleccionada = this.elementoSeleccionado.parentElement
        this.configPadding(this.columnaSeleccionada, this.paddingCol) //para extraer valores de columna
        this.rowSeleccionado = this.columnaSeleccionada.parentElement
        this.configPadding(this.rowSeleccionado, this.paddingRow)
        this.elementoDraggable = this.elementoSeleccionado
        this.reconocerEspaciados(this.elementoDraggable) /////////////////////////////////////////////////////////////////////////////////////////////////
        this.reconocerColumnaSize(this.columnaSeleccionada)

      }

      tabla.childNodes.forEach((element: HTMLElement) => {
        if (element.localName == "thead") {
          this.tableCheckedBoolean = true
        }

      });




    }

    else if (element.classList.contains('elementHr')) {
      this.elementoSeleccionado = element
      this.elementoSeleccionado.classList.add('elementoConBorde')
      this.elementoDraggable = this.elementoSeleccionado
    } else if (element.localName == 'hr') {
      this.elementoSeleccionado = element.parentElement;
      this.elementoSeleccionado.classList.add('elementoConBorde')
      this.elementoDraggable = this.elementoSeleccionado
    }


    //imagen
    else if (element.classList.contains('elementImg')) {
      this.elementoSeleccionado = element
      this.elementoDraggable = element.parentElement
      this.rowSeleccionado = element.parentElement.parentElement
      this.columnaSeleccionada = element.parentElement

      this.ruta = ['seccion', 'fila', 'columna', 'imagen']
      this.seccion = "imagen"
      this.ocultar = ''
    } else if (element.localName == 'img') {
      this.elementoSeleccionado = element

      this.elementoDraggable = element.parentElement

      this.rowSeleccionado = element.parentElement.parentElement
      this.columnaSeleccionada = element.parentElement

      this.reconocerColumnaSize(this.columnaSeleccionada)

      this.ruta = ['seccion', 'fila', 'columna', 'imagen']
      this.seccion = "imagen"
      this.ocultar = ''
    }

    // texto cualquiera
    else if (this.typeOfTextEditable.includes(element.nodeName.toLocaleLowerCase())) {

      this.tipo_de_elemento_seleccionado = 'texto'
      this.ruta = ['seccion', 'fila', 'columna', 'texto']
      this.seccion = 'texto'
      this.ocultar = ''

      if (element.localName == 'li') {
        this.liSeleccionado = element;
        element = element.parentElement.parentElement;
      }
      if (arregloRuta.includes(this.elementoSeleccionado)) {



        if (this.elementoSeleccionado != element) {
          this.desSeleccionarElemento()

          this.elementoSeleccionado = element
          this.columnaSeleccionada = this.elementoSeleccionado.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol) //llama a la funcion que extrae el valor de la columna
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)

          this.elementoDraggable = this.elementoSeleccionado
          this.reconocerEspaciados(this.elementoDraggable)
          this.reconocerColumnaSize(this.columnaSeleccionada)


        }
        element.setAttribute('contenteditable', 'true')
        this.textoEditable = element
      } else {
        if (this.elementoSeleccionado != element) {
          this.desSeleccionarElemento()

          this.elementoSeleccionado = element
          this.columnaSeleccionada = this.elementoSeleccionado.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol)
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)

          this.elementoDraggable = this.elementoSeleccionado
          this.reconocerEspaciados(this.elementoDraggable)
          this.reconocerColumnaSize(this.columnaSeleccionada)



        }

      }


    }



    else {

      this.desSeleccionarElemento()
    }

  }

  desSeleccionarElemento() {
    this.showMenu = ''
    if (this.elementoSeleccionado) {
      if (this.textoEditable) {

        this.textoEditable.removeAttribute('contenteditable');
      }

      this.elementoSeleccionado.classList.remove('elementoConBorde');
      this.elementoSeleccionado = null;

    }
    this.textoEditable = null;

    this.paddingCol.pt = 0
    this.paddingCol.pb = 0
    this.paddingCol.pl = 0
    this.paddingCol.pr = 0
    this.paddingCol.px = 0
    this.paddingCol.py = 0
    this.paddingCol.p = 0

    this.paddingRow.pl = 0
    this.paddingRow.pb = 0
    this.paddingRow.pr = 0
    this.paddingRow.pt = 0
    this.paddingRow.px = 0
    this.paddingRow.py = 0
    this.paddingRow.p = 0


    /////////////
    this.NewColumnaSize = 0

  }


  onText() {
    this.underline = document.getElementById('underline')
    this.resaltado = document.getElementById('resaltado')
    this.italics = document.getElementById('italics')
    this.bold = document.getElementById('bold')
    this.btnTextcenter = document.getElementById('center')
    this.btnTextjustify = document.getElementById('justify')
    this.btnTextright = document.getElementById('right')
    this.btnLista = document.getElementById("list")
    this.btnDisplay = document.getElementById('display1')
    this.btnDisplayDos = document.getElementById('display2')
    this.btnDisplayTres = document.getElementById('display3')
    this.btnDisplayCuatro = document.getElementById('display4')
    this.btnH1 = document.getElementById('btnH1')
    this.btnH2 = document.getElementById('btnH2')

    this.btnH3 = document.getElementById('btnH3')
    this.btnH4 = document.getElementById('btnH4')
    this.btnH5 = document.getElementById('btnH5')


    this.btnEdit = document.getElementById('btnEdit')

    this.btnAdd = document.getElementById('btnbtn');

  }

  configPadding(col: HTMLElement, padding: any) {
    console.log(col)
    let arreglo = ["pt-", "pl-", "pb-", "px-", "pr-", "py-"] //guardo en un array los nombres que puede tener p
    arreglo.forEach(element => { //recorro con un for el arreglo
      let index = col.className.indexOf(element)
      if (element == "pt-" && index >= 0) { //pregunto si en esa posicion equivale a pt
        padding.pt = parseInt(col.className.charAt(index + 3)) //guardo en una variable el valor de la columna
      }
      if (element == "pb-" && index >= 0) {
        padding.pb = parseInt(col.className.charAt(index + 3))
      }
      if (element == "pl-" && index >= 0) {
        padding.pl = parseInt(col.className.charAt(index + 3))
      }
      if (element == "px-" && index > 0) {
        padding.px = parseInt(col.className.charAt(index + 3))
      }
      if (element == "pr-" && index > 0) {
        padding.pr = parseInt(col.className.charAt(index + 3))
      }
      if (element == "py-" && index > 0) {
        padding.py = parseInt(col.className.charAt(index + 3))
      }


    });
    let indice = col.className.indexOf("p-")
    if (indice >= 0) {
      padding.p = parseInt(col.className.charAt(indice + 2))

    }
  }

  configTable(element: HTMLElement) {
    //console.log(element.firstChild.firstChild.childNodes.length)
    this.showMenu = "configTable"
    this.tableSelected = element
    this.tablaCheck = document.getElementById('theadCheck')
    this.reconocerEspaciados(element)

    if (element.classList.contains('table-dark')) {
      this.botonTableDark = 'btn-dark'
    } else {
      element.classList.remove('table-dark')
      this.botonTableDark = 'btn-secondary'
    }

    if (element.classList.contains('table-striped')) {
      this.botonTableStriped = 'btn-dark'
    } else {
      element.classList.remove('table-striped')
      this.botonTableStriped = 'btn-secondary'
    }

    if (element.classList.contains('table-bordered')) {
      this.botonTableBordered = 'btn-dark'
    } else {
      element.classList.remove('table-bordered')
      this.botonTableBordered = 'btn-secondary'
    }

    if (element.classList.contains('table-borderless')) {
      this.botonTableBorderless = 'btn-dark'
    } else {
      element.classList.remove('table-borderless')
      this.botonTableBorderless = 'btn-secondary'
    }

    if (element.classList.contains('table-hover')) {
      this.botonTableHover = 'btn-dark'
    } else {
      element.classList.remove('table-hover')
      this.botonTableHover = 'btn-secondary'
    }

    if (element.classList.contains('table-sm')) {
      this.botonTableSmall = 'btn-dark'
    } else {
      element.classList.remove("table-sm")
      this.botonTableSmall = 'btn-secondary'
    }

  }

  reconocerEspaciados(elemento) {
    let style = getComputedStyle(elemento)
    this.marginBloque = {
      mt: parseInt(style.marginTop.slice(0, style.marginTop.length - 2)),
      mb: parseInt(style.marginBottom.slice(0, style.marginBottom.length - 2)),
      ml: parseInt(style.marginLeft.slice(0, style.marginLeft.length - 2)),
      mr: parseInt(style.marginRight.slice(0, style.marginRight.length - 2)),
      m: parseInt(style.margin.slice(0, style.margin.length - 2))
    }
    this.paddingElementos = {
      pt: parseInt(style.paddingTop.slice(0, style.paddingTop.length - 2)),
      pb: parseInt(style.paddingBottom.slice(0, style.paddingBottom.length - 2)),
      pl: parseInt(style.paddingLeft.slice(0, style.paddingLeft.length - 2)),
      pr: parseInt(style.paddingRight.slice(0, style.paddingRight.length - 2)),
      p: parseInt(style.paddingTop.slice(0, style.paddingTop.length - 2)),
    }

  }

  modificarEspaciado(elemento) {
    console.log(this.marginBloque);

    elemento.style.margin = this.marginBloque.m + 'px'
    elemento.style.marginTop = this.marginBloque.mt + 'px'
    elemento.style.marginBottom = this.marginBloque.mb + 'px'
    elemento.style.marginLeft = this.marginBloque.ml + 'px'
    elemento.style.marginRight = this.marginBloque.mr + 'px'

    elemento.style.padding = this.paddingElementos.p + 'px'
    elemento.style.paddingTop = this.paddingElementos.pt + 'px'
    elemento.style.paddingBottom = this.paddingElementos.pb + 'px'
    elemento.style.paddingLeft = this.paddingElementos.pl + 'px'
    elemento.style.paddingRight = this.paddingElementos.pr + 'px'
  }

  onConfigBoton(elemento) {
    this.reconocerEspaciados(elemento)

    let style = getComputedStyle(elemento);
    console.log(parseInt(style.marginTop.slice(0, style.marginTop.length - 2)));

    this.elementoSeleccionado = elemento
    this.showMenu = "botonConfig"



    if (elemento.classList.contains('btn-block')) {

      this.btnBlockClass = 'btn-dark'
    } else {
      this.btnBlockClass = 'btn-secondary'
    }
  }

  onCardBody(elemento) {
    this.cardSelected = elemento
    this.showMenu = 'cardBody'

    this.reconocerEspaciados(elemento)
    // if (!document.getElementById('cardBody')) {
    //   return;
    // } 
    this.cardTextCheck = document.getElementById('textCheck')
    this.cardTitleCheck = document.getElementById('titleCheck')
    this.cardSubtitleCheck = document.getElementById('subtitleCheck')
    this.cardButtonCheck = document.getElementById('buttonCheck')

    this.cardListCheck = document.getElementById('listaCheck')

    this.cardImageCheck = document.getElementById('imagenCardCheck')
    //console.log(elemento,'para checkbox');
    
    elemento.childNodes.forEach(element => {
      if (element.classList.contains('card-text')) {
        this.cardTextcheckBoolean = true;
        this.cardSelectedText = element;
      }
      if (element.classList.contains('card-title')) {
        this.cardTitlecheckBoolean = true;
        this.cardSelectedTitle = element;
      }
      if (element.classList.contains('card-subtitle')) {
        this.cardSubtitlecheckBoolean = true;
        this.cardSelectedSubtitle = element;
      }
      if (element.classList.contains('btn')) {
        this.cardButtoncheckBoolean = true;
        this.cardSelectedButton = element;
      }

      if (element.classList.contains('list-group')) {
        this.cardListcheckBoolean = true
        this.cardSelectedList = element
      }

      if(element.classList.contains('card-img-top')){
        this.cardImagecheckBoolean = true;
        this.cardSelectedImage = element
      }
    });


    elemento.classList.add('elementoConBorde')

  }

  onBold() {
    this.cambiarColorDeBoton(this.bold);

    document.execCommand('bold', true);

  }
  onItalics() {
    this.cambiarColorDeBoton(this.italics);
    document.execCommand('italic', true);
  }


  onList() {

    let ul = document.createElement('ul');
    let li = document.createElement('li');
    li.insertAdjacentHTML('afterbegin', this.textoEditable.textContent);
    ul.append(li)
    while (this.textoEditable.firstChild) {
      this.textoEditable.firstChild.remove()
    }
    this.textoEditable.append(ul)

  }


  establecerLead() {
    if (this.textoEditable) {
      if (!this.textoEditable.classList.contains("lead")) {
        this.agregarClase("lead")
      } else {
        this.textoEditable.classList.remove("lead")
      }
      this.switchButtonColor('lead')
    }

  }

  quitarRedimensionadores() {
    if (this.puntoAbajo) {
      this.puntoAbajo.remove()
      this.puntoIzquierdo.remove()
      this.puntoDerecho.remove()
    }

  }
  cambiarTipoDeTexto(tipo: string) {
    if (this.textoEditable) {

      this.elementoSeleccionado = this.textoEditable
      this.quitarRedimensionadores()
      let atributos: string = this.textoEditable.attributes

      let nuevo = document.createElement(tipo);

      // nuevo.append(this.textoEditable.innerHTML);
      // console.log(this.textoEditable.childNodes);
      nuevo.insertAdjacentHTML('afterbegin', this.textoEditable.innerHTML);
      // for (let i = 0; i < this.textoEditable.childNodes.length; i++) {
      //   const childNode = this.textoEditable.childNodes[i];
      // }
      for (let i = 0; i < atributos.length; i++) {
        const atributo: any = atributos[i];
        nuevo.setAttribute(atributo.nodeName, atributo.nodeValue)
      }
      this.textoEditable.parentElement.replaceChild(nuevo, this.textoEditable);
      this.textoEditable = nuevo;
      this.elementoSeleccionado = nuevo;
      // this.quitarBorde()

      this.switchToSecondary(this.btnH1)
      this.switchToSecondary(this.btnH2)
      this.switchToSecondary(this.btnH3)
      this.switchToSecondary(this.btnH4)
      this.switchToSecondary(this.btnH5)

      if (tipo == 'h1') {

        this.switchToDark(this.btnH1)
      }

      if (tipo == 'h2') {
        this.switchToDark(this.btnH2)
      }

      if (tipo == 'h3') {
        this.switchToDark(this.btnH3)
      }

      if (tipo == 'h4') {
        this.switchToDark(this.btnH4)
      }

      if (tipo == 'h5') {
        this.switchToDark(this.btnH5)
      }





    }
  }

  agregarClase(clase) {
    if (this.textoEditable) {

      this.textoEditable.classList.add(clase);
    }
  }


  onJustify() {
    if (this.textoEditable) {

      this.funcion('text-justify')
      /* if (this.textoEditable.classList.contains("text-right") || this.textoEditable.classList.contains("text-center") ) {
        this.textoEditable.classList.remove("text-right")
        this.textoEditable.classList.remove("text-center")
        this.switchButtonColor('justify') //ver si esta bien asi
        this.switchButtonColor('right') //ver si esta bien asi
      } */
      if (!this.textoEditable.classList.contains("text-justify")) {
        this.agregarClase("text-justify")
      } else {
        this.textoEditable.classList.remove("text-justify")
      }
      this.switchButtonColor('justify')
    }
  }

  cambiarColorDeBoton(boton) {
    // if (boton.classList.contains('btn-dark')) {
    //   boton.classList.remove('btn-dark')
    //   boton.classList.add('btn-secondary')
    // } else {
    //   boton.classList.add('btn-dark')
    // }
  }

  switchToDark(element) {
    element.classList.remove('btn-secondary')
    element.classList.add('btn-dark')
  }
  switchToSecondary(element) {
    // console.log(element);


    element.classList.remove('btn-dark')
    element.classList.add('btn-secondary')
  }


  onRight() {
    if (this.textoEditable) {

      this.funcion('text-right')
      if (!this.textoEditable.classList.contains("text-right")) {
        this.agregarClase("text-right")
      } else {
        this.textoEditable.classList.remove("text-right")
      }
      this.switchButtonColor('right')
    }
  }

  switchButtonColor(id) {
    let elemento = document.getElementById(id)
    if (elemento.classList.contains('btn-secondary')) {
      elemento.classList.add("btn-dark");
      elemento.classList.remove("btn-secondary")
    } else {
      elemento.classList.add("btn-secondary")
      elemento.classList.remove("btn-dark")
    }
  }


  funcion2(omitir) {
    if (omitir != 'display-1' && this.textoEditable.classList.contains('display-1')) {
      this.textoEditable.classList.remove('display-1')
      this.btnDisplay.classList.remove('btn-dark')
      this.btnDisplay.classList.add('btn-secondary')
    }
    if (omitir != 'display-2' && this.textoEditable.classList.contains('display-2')) {
      this.textoEditable.classList.remove('display-2')
      this.btnDisplayDos.classList.remove('btn-dark')
      this.btnDisplayDos.classList.add('btn-secondary')
    }
    if (omitir != 'display-3' && this.textoEditable.classList.contains('display-3')) {
      this.textoEditable.classList.remove('display-3')
      this.btnDisplayTres.classList.remove('btn-dark')
      this.btnDisplayTres.classList.add('btn-secondary')
    }
    if (omitir != 'display-4' && this.textoEditable.classList.contains('display-4')) {
      this.textoEditable.classList.remove('display-4')
      this.btnDisplayCuatro.classList.remove('btn-dark')
      this.btnDisplayCuatro.classList.add('btn-secondary')
    }
  }
  funcion(omitir) {

    if (omitir != 'text-justify' && this.textoEditable.classList.contains('text-justify')) {
      this.textoEditable.classList.remove('text-justify')
      //this.textoEditable.classList.remove('btn-dark');
      this.btnTextjustify.classList.remove('btn-dark')
      this.btnTextjustify.classList.add('btn-secondary')
    }
    if (omitir != 'text-center' && this.textoEditable.classList.contains('text-center')) {
      this.textoEditable.classList.remove('text-center')
      this.btnTextcenter.classList.remove('btn-dark')
      this.btnTextcenter.classList.add('btn-secondary')
    }
    if (omitir != 'text-right' && this.textoEditable.classList.contains('text-right')) {
      this.textoEditable.classList.remove('text-right')
      this.btnTextright.classList.remove('btn-dark');
      this.btnTextright.classList.add('btn-secondary')
    }

  }
  onCenter() {
    if (this.textoEditable) {
      this.funcion('text-center')
      if (!this.textoEditable.classList.contains("text-center")) {
        this.agregarClase("text-center")
      } else {
        this.textoEditable.classList.remove("text-center")
      }
      this.switchButtonColor('center')
    }



  }

  onUnderLine() {


    this.cambiarColorDeBoton(this.underline);


    document.execCommand('underline', false);

    // let selection = window.getSelection()
    // if (selection.focusNode.parentNode.nodeName == 'U') {
    //   let startOffset = selection.getRangeAt(0).startOffset


    //   let endOffset = selection.getRangeAt(0).endOffset
    //   if (startOffset>0) {
    //     let rangeInicial = document.createRange();
    //     rangeInicial.setStart(selection.focusNode, 0)
    //     rangeInicial.setEnd(selection.focusNode, startOffset)
    //     console.log(rangeInicial.toString());

    //    }
    // }
    // this.editSelection('u')
  }

  //agrega al texto display 1
  onDisplay1() {
    if (this.textoEditable) {

      this.funcion2('display-1')
      if (!this.textoEditable.classList.contains("display-1")) {
        //this.agregarClase('display-1')
        this.textoEditable.classList.add('display-1')
      } else {
        this.textoEditable.classList.remove("display-1")
      }
      this.switchButtonColor('display1')
    }
  }

  //agrega al texto display 2
  onDisplay2() {
    if (this.textoEditable) {

      this.funcion2('display-2')
      if (!this.textoEditable.classList.contains("display-2")) {
        this.agregarClase('display-2')
      } else {
        this.textoEditable.classList.remove('display-2')
      }
      this.switchButtonColor('display2')
    }
  }

  //agrega al texto display 3
  onDisplay3() {

    if (this.textoEditable) {

      this.funcion2('display-3')
      if (!this.textoEditable.classList.contains("display-3")) {
        this.agregarClase('display-3')
      } else {
        this.textoEditable.classList.remove('display-3')
      }
      this.switchButtonColor('display3')

    }
  }
  //agrega al texto display 4
  onDisplay4() {
    if (this.textoEditable) {

      this.funcion2('display-4')
      if (!this.textoEditable.classList.contains("display-4")) {
        this.agregarClase('display-4')
      } else {
        this.textoEditable.classList.remove('display-4')
      }
      this.switchButtonColor('display4')
    }
  }

  alineacion(horizontal, vertical, el: HTMLElement) {
    this.limpiarClasesAlineacion(el)
    el.classList.add(horizontal);
    el.classList.add(vertical);
    el.classList.add('d-flex');
    el.classList.add('flex-column');
    el.classList.add('flex-wrap');

  }

  agregarClases(clasesNuevas: Array<string>, elemento: HTMLElement, clasesAQuitar?: Array<string>) {
    for (let i = 0; i < clasesNuevas.length; i++) {
      const clase = clasesNuevas[i];
      elemento.classList.add(clase)
    }
    if (clasesAQuitar) {
      for (let i = 0; i < clasesAQuitar.length; i++) {
        const clase = clasesAQuitar[i];
        elemento.classList.remove(clase)

      }
    }

  }

  limpiarClasesAlineacion(el) {
    el.classList.remove('align-content-center')
    el.classList.remove('align-content-end')
    el.classList.remove('align-content-start')
    el.classList.remove('justify-content-center')
    el.classList.remove('justify-content-end')
    el.classList.remove('justify-content-start')
    el.classList.remove('d-flex')

  }

  reconocerColor(elemento) {
    let comienzo
    this.listaDeColores.forEach(color => {
      let index = elemento.className.indexOf(color)

      if (index >= 0) {
        comienzo = elemento.className.slice(index);
        if (comienzo.indexOf(' ') >= 0) {
          comienzo = comienzo.slice(0, comienzo.indexOf(' '))
        }
      }
    });
    return comienzo
  }
  cambiarColorCard(color) {
    this.elementoDraggable.classList.remove('bg-' + this.reconocerColor(this.elementoDraggable));
    this.elementoDraggable.classList.add(color)
    if (color == 'bg-light') {
      this.elementoDraggable.classList.remove('text-white');
    } else {
      this.elementoDraggable.classList.add('text-white');
    }

    if (color == 'none') {
      this.elementoDraggable.classList.remove('bg-' + this.reconocerColor(this.elementoDraggable));
      if (this.elementoDraggable.classList.contains('text-white')) {
        console.log('entra');

        this.elementoDraggable.classList.remove('text-white');

      }
    }

  }

  removeElement(id) {
    if (id == 'titleCheck') {
      if (this.cardTitlecheckBoolean) {
        // this.cardTitleCheck.removeAttribute('checked')
        this.cardTitlecheckBoolean = false;
        this.cardSelectedTitle.remove()
      } else {
        let title = document.createElement('h5');
        title.classList.add('card-title')
        title.textContent = 'Card title';
        // this.cardTitleCheck.setAttribute('checked', '')
        this.cardTitlecheckBoolean = true;
        this.cardSelectedTitle = title;
        this.cardSelected.prepend(title)
      }
    }
    if (id == 'textCheck') {
      if (this.cardTextcheckBoolean) {
        this.cardSelectedText.remove()
        this.cardSelectedText = null;
        // this.cardTextCheck.removeAttribute('checked')
        this.cardTextcheckBoolean = false;
      } else {
        let p = document.createElement('p')
        p.classList.add('card-text');
        p.textContent = `Some quick example text to build on the card title and make up the bulk of the card's content.`
        this.cardSelectedText = p
        // this.cardTextCheck.setAttribute('checked', '')
        this.cardTextcheckBoolean = true;
        if (this.cardSelectedButton) {
          this.cardSelected.insertBefore(p, this.cardSelectedButton);
        } else {
          this.cardSelected.append(p)
        }
      }
    }
    if (id == 'subtitleCheck') {
      if (this.cardSubtitlecheckBoolean) {
        this.cardSubtitlecheckBoolean = false;
        this.cardSelectedSubtitle.remove()
        this.cardSelectedSubtitle = null;
      } else {
        let subtitle = document.createElement('h6');
        subtitle.classList.add('card-subtitle')
        subtitle.textContent = 'Card subtitle';
        this.cardSubtitlecheckBoolean = true;
        this.cardSelectedSubtitle = subtitle;
        if (this.cardSelectedText) {
          // document.getElementById('').insertBefore()
          this.cardSelected.insertBefore(subtitle, this.cardSelectedText);
        } else if (this.cardSelectedButton && !this.cardSelectedText) {
          this.cardSelected.insertBefore(subtitle, this.cardSelectedButton);
        } else {
          this.cardSelected.append(subtitle)
        }
      }
    }

    if (id == 'buttonCheck') {
      if (this.cardButtoncheckBoolean) {
        this.cardButtoncheckBoolean = false;
        this.cardSelectedButton.remove()
        this.cardSelectedButton = null;
      } else {
        this.cardButtoncheckBoolean = true;
        let button = document.createElement('a');
        button.classList.add('btn');
        button.classList.add('btn-primary');
        button.href = 'javascript:null'
        button.textContent = 'Go somewhere'
        this.cardSelectedButton = button;

        this.cardSelected.append(button);
      }
    }

    //lista contenida en card ///////////////////////////////////////////////////////////////////////
    if (id == "listaCheck") {
      if (this.cardListcheckBoolean) {
        this.cardListcheckBoolean = false;
        this.cardSelectedList.remove();
        this.cardSelectedList = null;
      } else {
        this.cardListcheckBoolean = true;
        let ul = document.createElement("ul")
        ul.classList.add("list-group")
        let li = document.createElement("li")
        li.classList.add('list-group-item')
        li.textContent = "Cras justo odio"
        ul.append(li)
        this.cardSelectedList = ul;
        if (this.cardSelectedButton) {
          this.cardSelected.insertBefore(ul, this.cardSelectedButton)
        } else {
          this.cardSelected.append(ul);
        }

      }
    }


    if (id=="imagenCardCheck"){
      if(this.cardImagecheckBoolean){
        this.cardImagecheckBoolean= false
        this.cardSelectedImage.remove()
        this.cardSelectedImage=null
      }else{
        this.cardImagecheckBoolean=true
        let imgCard= document.createElement("img")
        imgCard.classList.add("card-img-top")
        imgCard.setAttribute('src','https://picsum.photos/100')
        this.cardSelectedImage = imgCard
        //this.cardSelected.append(imgCard)
        this.cardSelected.parentElement.prepend(imgCard)
      }
    }
  }


  //add image to a card

  addImagenCard(){

  }

  switchClass(element: HTMLElement, clase: string) {
    if (element.classList.contains(clase)) {
      element.classList.remove(clase)
    } else {
      element.classList.add(clase)
    }

  }

  limpiador() {
    // this.btnSecction.classList.remove('active');
    // this.btnFila.classList.remove('active');
    // this.btnCol.classList.remove('active');
    // this.btnbloque.classList.remove('active');
  }
  cambiarSeccion(seccion) {
    this.seccion = seccion
    this.limpiador()


    switch (seccion) {
      case 'seccion':

        break;
      case 'fila':

        this.seccion = 'fila'
        break;
      case 'columna':


        this.seccion = 'columna'
        break;
      case 'card':

        break;
      case 'bloque':
        break;

      case 'texto':
        this.seccion = "texto"
        break;

      case 'button':
        this.seccion = "button"
        break;

      case 'imagen':
        this.seccion = "imagen"
        break;

      case 'cardImg':
        this.seccion = "cardImg"

      default:
        break;

    }


  }
  changeAlturaSeparador(altura) {
    if (this.elementoSeleccionado.classList.contains('separador')) {
      this.elementoSeleccionado.style.height = altura + "px"
    }

  }

  changeAnchoCard(ancho) {    
    if (this.elementoDraggable.classList.contains('card')) {
      this.elementoDraggable.style.width = ancho + "%"
    }

  }

  cambiarTamanoBoton(size) {
    if (size == "chico") {
      this.elementoSeleccionado.classList.add('btn-sm')
      this.elementoSeleccionado.classList.remove('btn-lg')

    }
    if (size == "mediano") {
      this.elementoSeleccionado.classList.remove('btn-sm')
      this.elementoSeleccionado.classList.remove('btn-lg')
    }
    if (size == "grande") {
      this.elementoSeleccionado.classList.add('btn-lg')
      this.elementoSeleccionado.classList.remove('btn-sm')

    }
  }

  setBloque(b) {
    let bloque = document.getElementById('btnBloque')
    if (bloque.classList.contains('btn-dark')) {
      this.elementoSeleccionado.classList.remove('btn-block')
      this.switchToSecondary(bloque)
    } else {
      this.elementoSeleccionado.classList.add('btn-block')
      this.switchToDark(bloque)
    }
  }

  switchOutline() {
    if (this.botonSeleccionado.classList.contains('btn-outline-' + this.reconocerColor(this.botonSeleccionado))) {
      this.botonSeleccionado.classList.add('btn-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.remove('btn-outline-' + this.reconocerColor(this.botonSeleccionado));

    } else {
      this.botonSeleccionado.classList.add('btn-outline-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.remove('btn-' + this.reconocerColor(this.botonSeleccionado));
    }

  }

  cambiarColorBoton(color) {

    if (this.botonSeleccionado.classList.contains('btn-outline-' + this.reconocerColor(this.botonSeleccionado))) {
      this.botonSeleccionado.classList.remove('btn-outline-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.add('btn-outline-' + color);

    } else {
      this.botonSeleccionado.classList.remove('btn-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.add('btn-' + color)
    }

  }

  addThead(theadCheck: string) {
    let cantidad = this.elementoSeleccionado.firstChild.firstChild.childNodes.length //pregunto por cantidad de columnas que tengo
    //console.log(cantidad) 

    if (this.tableCheckedBoolean) {  //aqui pregunto si el boton check esta activado, es decir que si tengo head
      this.tableCheckedBoolean = false    // si ya tiene, entonces limpio el boton
      this.elementoSeleccionado.firstChild.remove() //remueve esa fila de encabezado
    } else {     //aqui creo el encabezado de la tabla
      let head = document.createElement('thead'); //creo el elemento thead
      let row = document.createElement("tr") //creo el elemento th

      for (let j = 0; j < cantidad; j++) {   //for para ir creando las columnas
        let col = document.createElement("th")   //creo las columnas
        let texto = document.createTextNode("cell")  //escribo algo dentro de las columnas

        col.appendChild(texto)  //agrego el texto dentro de la columna
        row.appendChild(col)    //agrego la columna a la fila
      }

      head.appendChild(row)  // agrego la fila al encabezado

      this.tableCheckedBoolean = true;  // checkeo boton en activo

      this.elementoSeleccionado.prepend(head)  //inserto la tabla 
    }


  }

  cambiarTabla(formato: string) {
    if (formato == "dark") {
      if (this.elementoSeleccionado.classList.contains('table-dark')) {
        this.elementoSeleccionado.classList.remove('table-dark')
        this.botonTableDark = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-dark')
        this.botonTableDark = 'btn-dark'
      }

    }

    if (formato == "striped") {
      if (this.elementoSeleccionado.classList.contains('table-striped')) {
        this.elementoSeleccionado.classList.remove('table-striped')
        this.botonTableStriped = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-striped')
        this.botonTableStriped = 'btn-dark'
      }

    }

    if (formato == "bordered") {
      if (this.elementoSeleccionado.classList.contains('table-bordered')) {
        this.elementoSeleccionado.classList.remove('table-bordered')
        this.botonTableBordered = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-bordered')
        this.botonTableBordered = 'btn-dark'
      }

    }

    if (formato == "borderless") {
      if (this.elementoSeleccionado.classList.contains('table-borderless')) {
        this.elementoSeleccionado.classList.remove('table-borderless')
        this.botonTableBorderless = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-borderless')
        this.botonTableBorderless = 'btn-dark'
      }

    }

    if (formato == "hover") {
      if (this.elementoSeleccionado.classList.contains('table-hover')) {
        this.elementoSeleccionado.classList.remove('table-hover')
        this.botonTableHover = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-hover')
        this.botonTableHover = 'btn-dark'
      }

    }

    if (formato == "small") {
      if (this.elementoSeleccionado.classList.contains('table-sm')) {
        this.elementoSeleccionado.classList.remove('table-sm')
        this.botonTableSmall = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-sm')
        this.botonTableSmall = 'btn-dark'
      }


    }
  }

  ocultarVentanaMenuConfig() {
    this.ocultar = 'oculto'
  }

  agregarSegmentoUpDown(segmentoSeleccionado: HTMLElement, posicion) {
    let div = document.createElement('div');
    div.style.width = '100%';
   
    let body = segmentoSeleccionado.parentElement.parentElement;
    
    

    let segmento = document.createElement('div')

    segmento.classList.add('container');
    segmento.setAttribute('seccion', '');
    div.append(segmento);

    //segmento.style.height='400px'
    if (posicion == "arriba") {
      body.insertBefore(div, segmentoSeleccionado.parentElement)
    } else {
      body.insertBefore(div, segmentoSeleccionado.parentElement.nextElementSibling)
    }

    this.segmentoSeleccionado = segmento
    this._agregarElementoService.segmentoSeleccionado = segmento
    this._agregarElementoService.agregarTexto()
    //this.agregarTexto()
  }


  cambiarColordeTexto(textColor: string) {
    //console.log(textColor)
    if (this.textoEditable.classList.contains('text-' + this.reconocerColor(this.textoEditable))) {
      this.textoEditable.classList.remove('text-' + this.reconocerColor(this.textoEditable))
      this.textoEditable.classList.add('text-' + textColor)
    } else {
      this.textoEditable.classList.add('text-' + textColor)
    }

    if(textColor == 'none'){
      this.textoEditable.classList.remove('text-' + this.reconocerColor(this.textoEditable))
    }
  }



  cambiarTextoBoton() {
    console.log(this.botonSeleccionado);

    this.botonSeleccionado.innerText = this.textoDelBoton
  }


  eliminarElemento() {
    console.log(this.elementoDraggable);

    if (this.elementoDraggable) {
      let row: HTMLElement = this.elementoDraggable.parentElement.parentElement;
      let col: HTMLElement = this.elementoDraggable.parentElement;

      console.log(row);
      console.log(col);



      if (row && col) {
        if (col.childElementCount == 1) {
          if (row.childElementCount == 1) {
            row.remove()
          } else {
            col.remove()
          }
        } else {
          this.elementoDraggable.remove()
        }
      }


    }
  }

  cambiarImagen(elementoImagenSeleccionado: HTMLElement) {

    this._galeryService.mostralModalGallery(this.id)
    this._galeryService.notificacion.subscribe((item) => {

      elementoImagenSeleccionado.setAttribute('src', item)
      this._galeryService.notificacion.unsubscribe()
      console.log(this._galeryService.notificacion.closed);

    })



  }

  colocarFondoImagennElemento(elemento:HTMLElement){
    console.log(elemento);
    console.log(this._galeryService.notificacion.closed);
    
    this._galeryService.mostralModalGallery(this.id)
    let f = this._galeryService.notificacion.subscribe((item) => {

      elemento.style.backgroundImage = `url(${item})`
      elemento.style.backgroundRepeat= 'no-repeat'
      elemento.style.backgroundPosition= 'center'
      elemento.style.backgroundSize = 'cover'
      f.unsubscribe()
      
 
    })

  }


  imageDesignCentered(source) {
    let col = document.createElement('col')
    let row = document.createElement('row')
    let imageDiv = document.createElement('div')
    //let imageDivWrap = document.createElement('div')
    let image = document.createElement('img')
    let title = document.createElement('h1')

    image.setAttribute('src', source)
    title.innerText = 'Title'
    imageDiv.id = 'bloque_img_' + Math.floor((Math.random() * 10000) + 1);
    imageDiv.classList.add('elementImg')
    imageDiv.classList.add('box-flex')
    imageDiv.classList.add('box-flex-center')
    title.classList.add('box-caption')
    title.classList.add('text-light')


    imageDiv.append(image)
    imageDiv.append(title)
    col.append(imageDiv)
    row.append(col)
    //imageDivWrap.append(imageDiv)


    return imageDiv


  }

  cambiarDesign(tipoDesign) {

    if (tipoDesign == 'center') {
      let srcImg = this.elementoSeleccionado.getAttribute('src')
      let nuevoDesign = this.imageDesignCentered(srcImg)



      this.elementoDraggable.replaceWith(nuevoDesign)



    }


  }

  changeColumnSize(elementoColumna, newCol){
    console.log(this.columnaSize,"columna original")
    //console.log(elementoColumna, "original");
    //if(this.columnaSize=="auto")
    if (!this.columnaSize){ 
      elementoColumna.classList.remove('col-md')
    }else{
      elementoColumna.classList.remove('col-md-'+this.columnaSize)
    }
    
    console.log(elementoColumna, "removido");
    
    elementoColumna.classList.add('col-md-'+newCol)

    this.columnaSize = newCol

  }


  reconocerColumnaSize(columnaElement) {
    if (columnaElement.classList.contains("col-md")) {
      this.columnaSize = 0
      //console.log(this.columnaSize, "al hacer clic");
    } else if (columnaElement.classList.contains("col-md-1")) {
      this.columnaSize = 1
      //console.log(this.columnaSize, "al hacer clic");
    } else if (columnaElement.classList.contains('col-md-2')) {
      this.columnaSize = 2
    } else if (columnaElement.classList.contains('col-md-3')) {
      this.columnaSize = 3
    } else if (columnaElement.classList.contains('col-md-4')) {
      this.columnaSize = 4
    } else if (columnaElement.classList.contains('col-md-5')) {
      this.columnaSize = 5
    } else if (columnaElement.classList.contains('col-md-6')) {
      this.columnaSize = 6
    } else if (columnaElement.classList.contains('col-md-7')) {
      this.columnaSize = 7
    } else if (columnaElement.classList.contains('col-md-8')) {
      this.columnaSize = 8
    } else if (columnaElement.classList.contains('col-md-9')) {
      this.columnaSize = 9
    } else if (columnaElement.classList.contains('col-md-10')) {
      this.columnaSize = 10
    } else if (columnaElement.classList.contains('col-md-11')) {
      this.columnaSize = 11
    } else if (columnaElement.classList.contains('col-md-12')) {
      this.columnaSize = 12
    }
    return this.columnaSize
    this.desSeleccionarElemento()
  }

  setAutoSizeCol(columnaElemento){
    console.log(columnaElemento);
    console.log(this.columnaSize)
    columnaElemento.classList.remove('col-md-'+this.columnaSize)

    console.log(columnaElemento,'removed');

    columnaElemento.classList.add('col-md')
    this.columnaSize= undefined
    this.desSeleccionarElemento()

  }

  CardImagePositionHorizontal(elemento){
    console.log(elemento,'card img');
    
  }


}
