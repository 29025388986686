import { Usuario } from './usuario.model';
export class Config {
    constructor(
        public nombre: string,
        public codigo: Array<string>,
        public usuario: Usuario,
        public img?: string,
        public tempId?: string

    ){}

}