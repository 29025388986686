import { config } from 'rxjs';
import { Config } from './../../models/config.model';
import { EditorService } from './../../services/editor.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario } from './../../models/usuario.model';
import { UsuarioService } from './../../services/usuario.service';
import { Component, OnInit } from '@angular/core';
import { SelecProyectModalService } from 'src/app/components/modal-select-proyect/selec-proyect-modal.service';

@Component({
  selector: 'app-crear-proyecto',
  templateUrl: './crear-proyecto.component.html',
  styleUrls: ['./crear-proyecto.component.scss']
})
export class CrearProyectoComponent implements OnInit {
  newUser: boolean = true;
  filterPost = '';
  totalRegistros: number = 0;

  usuarios: Usuario[] = [];
  nombre: string;
  desde: number = 0;
  forma: FormGroup;
  contenido
  templates
  usuario
  formaNombre;
  showUsers: boolean = true;
  userCreated: boolean = false;

  plantilla

  showTemplates: boolean = false;

  constructor(public _usuarioService: UsuarioService,
    public _editorService: EditorService,
    public _selecrProyectModalService: SelecProyectModalService,
    public router: Router) { }
  sonIguales(campo1: string, campo2: string) {
    return (group: FormGroup) => {
      let pass1 = group.controls[campo1].value
      let pass2 = group.controls[campo2].value
      if (pass1 === pass2) {
        return null;
      } else {
        return { sonIguales: true }
      }
    }
  }

  hideForm(form) {
    this.nombre = form.value.name

    document.getElementById('body').remove();

    this._editorService.getTemplates().subscribe(resp => {
      this.templates = resp.templates;

      this.showTemplates = true;

    })

  }

  crearConfig(template) {
    this._selecrProyectModalService.mostrarModal(template._id)
    this.contenido = template.codigo
    let config = new Config(
      this.nombre,
      this.contenido,
      this.usuario,
      template.nombre
    )

    this._editorService.sendConfig(config).subscribe(resp =>{
      this.router.navigateByUrl(`/perfil/${this.usuario._id}` )
    })
  }

  ngOnInit() {
    this.formaNombre = new FormGroup({
      name: new FormControl(null, Validators.required)
    })

    this.forma = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      correo: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      condiciones: new FormControl(false)
    }, { validators: this.sonIguales('password', 'password') });

  }

  cambiarDesde(valor: number) {
    let desde = this.desde + valor
    // console.log(desde);

    if (desde >= this.totalRegistros) {
      return;
    }

    if (desde < 0) {
      return;
    }

    this.desde += valor;
    this.cargarUsuarios();
  }


  registrarUsuario() {

    if (this.forma.invalid) {
      console.log("forma invalid");

      return
    }

    let usuario = new Usuario(
      this.forma.value.nombre,
      this.forma.value.correo,
      this.forma.value.password
    );

    this._usuarioService.crearUsuario(usuario).subscribe(resp => {
      this.usuario = resp;

      this.userCreated = true;
    })
  }

buscarTemplate(termino){
  
  if (termino.length <= 0) {
    this.cargarTemplates();
    return;
  }
  this._editorService.buscarTemplates(termino).subscribe((usuarios) => {

    this.templates = usuarios

  })
}

  switchTab(tab: string) {
    switch (tab) {
      case 'newUser':
        this.newUser = true;
        document.getElementById('newUser_tab').className = ' nav-link active'

        document.getElementById('selectUser_tab').className = 'nav-link'

        break;
      case 'selectUser':
        this.newUser = false;

        document.getElementById('selectUser_tab').className = ' nav-link active'

        document.getElementById('newUser_tab').className = 'nav-link';
        this.cargarUsuarios();
        break;
      default:
        break;
    }
  }
  buscarUsuario(termino: string) {
    if (termino.length <= 0) {
      this.cargarUsuarios();
      return;
    }
    this._usuarioService.buscarUsuarios(termino).subscribe((usuarios) => {

      this.usuarios = usuarios

    })
  }

  selectUser(usuario) {
    this.usuario = usuario;
    this.showUsers = false;
  }



  cargarUsuarios() {

    //  this.cargando = true;
    this._usuarioService.cargarUsuario(this.desde).subscribe((resp: any) => {
      // console.log(resp);
      this.totalRegistros = resp.totalUsuarios;
      this.usuarios = resp.Usuarios

      //  this.cargando = false;
    })
  }
  cargarTemplates() {

    //  this.cargando = true;
    this._editorService.getTemplates().subscribe((resp: any) => {
      // console.log(resp);
      
      this.templates = resp.templates    
      //  this.cargando = false;
    })
  }
}
