import { URL_SERVICIOS } from './../../config/global';
import Swal from 'sweetalert2';
import { SubirArchivoService } from './../../services/subir-archivo.service';
import { Injectable, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ModalUploadService {

   idImg
  public isNotificaion2: boolean
  public tipo: string;
  public id: string;
  public id2: string;
  public file: File
  public oculto: string = 'oculto';
  imagenSubir
  public notificacion = new EventEmitter<any>();
  public notificacion2 = new EventEmitter<any>();
  imagenTemp
  constructor() {

  }

  ocultarModal() {
    this.oculto = 'oculto';
    // this.id = null;
    // this.file = null
  }

  mostrarModal(id: string, isnoti2?: boolean) {

   this.id = id;
    console.log(this.id);
    
    this.oculto = '';
    // this.isNotificaion2 = isnoti2
  }

  subirImagen() {
 
    console.log(this.id);
     this.subirArchivo(this.imagenSubir,this.id)
      .then((resp:any) => {
        Swal.fire('Imagen subida correctamente', '', 'success')
        this.notificacion.emit(resp);
        console.log(this.id2);
        console.log(resp);
        
        if (this.isNotificaion2) {
          this.notificacion2.emit(resp);
          let url = URL_SERVICIOS+ '/config/files/' + this.id2 + '/img/' + resp.imgSubida

          document.getElementById('imgToEdit').setAttribute('src', url )

        }
        //this._modalUploadService.ocultarModal();
        this.ocultarModal();
      }).catch(err => {
        Swal.fire('Error al subir la imagen', err.mensaje, 'error');
      })
  }

  seleccionImagen(file  ) {
    // this.idImg = idImg
    // this.id = id
    // this.id2 = id
    this.file = file
    if (!file) {
      this.imagenSubir = null;
      return
    }

    if (file) {
      if (file.type.indexOf('image') < 0) {
        Swal.fire('Solor imagenes', "El archivo seleccionado no es una imagen", 'error')
        this.imagenSubir = null
      } else {
        this.imagenSubir = file
        let reader = new FileReader();
        let urlImagenTemp = reader.readAsDataURL(file);

        reader.onloadend = () => this.imagenTemp = reader.result;
      }
    }

    // this.imagenSubir = archivo || this._modalUploadService.file;

  }



  subirArchivo(archivo: File, id: string) {


    return new Promise((resolve, reject) => {
      console.log("test");

      let formData = new FormData();
      let xhr = new XMLHttpRequest();
      console.log(archivo);

      formData.append('imagen', archivo, archivo.name);
      xhr.onreadystatechange = function () {
         
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log("imagen subida");
            
            resolve(JSON.parse(xhr.response));
          } else {
            console.log("Fallo la subida");
            reject(xhr.response);
          }
        }
      }
      let url = URL_SERVICIOS + '/img/user/' + id;
      xhr.open('PUT', url, true);
      xhr.send(formData);

    });
  }


}
