import { Component, OnInit } from '@angular/core';
import { SelecProyectModalService } from './selec-proyect-modal.service';

@Component({
  selector: 'app-modal-select-proyect',
  templateUrl: './modal-select-proyect.component.html',
  styleUrls: ['./modal-select-proyect.component.scss']
})
export class ModalSelectProyectComponent implements OnInit {

  constructor(
    public _selectProyectModalService:SelecProyectModalService
  ) { }

  ngOnInit() {
  }

}
