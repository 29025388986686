import { ModalUploadService } from './../components/modal-upload/modal-upload.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from './../config/global';
import { Injectable } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';


@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService {

  constructor(
    private ng2ImgMax: Ng2ImgMaxService,
    private http : HttpClient,
    private modalService: ModalUploadService
  ) { }

  subirPerfilDePagina(url:any, id:string){
    let archivo:File
    let imagen
    return fetch(url)
  .then(res => res.blob())
  .then(blob => {
    archivo = new File([blob], "profile.png",{ type: "image/png" })  
    return new Promise((resolve, reject) => {
      console.log("test");
      
      let formData = new FormData();
      let xhr = new XMLHttpRequest();
      console.log(imagen)
      formData.append('imagen', imagen, imagen.name);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log("imagen subida");
            resolve(JSON.parse(xhr.response));
          } else {
            console.log("Fallo la subida");
            reject(xhr.response);
          }
        }
      }
      let url = URL_SERVICIOS + '/upload/page/'  + id;
      xhr.open('PUT', url, true);
      xhr.send(formData);

    });  


    // return this.ng2ImgMax.resizeImage(archivo, 1000, 375).subscribe(
    //   result => {
    //     imagen = result;

       

    //   },
    //   error => {
    //     console.log('😢 Oh no!', error);
    //   }
    // );



   
  })
    
    
  }

  subirArchivo(archivo: File, id: string) {

    
    return new Promise((resolve, reject) => {
      console.log("test");
      
      let formData = new FormData();
      let xhr = new XMLHttpRequest();
      console.log(archivo);
      
      formData.append('imagen', archivo, archivo.name);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log("imagen subida");
            resolve(JSON.parse(xhr.response));
          } else {
            console.log("Fallo la subida");
            reject(xhr.response);
          }
        }
      }
      let url = URL_SERVICIOS + '/upload/page/'  + id;
      xhr.open('PUT', url, true);
      xhr.send(formData);

    });
  }

  subirImagenPorProyecto(id, file){

    return new Promise((resolve, reject) => {
      console.log("test");
      
      let formData = new FormData();
      let xhr = new XMLHttpRequest();
      console.log(file);
      
      formData.append('imagen', file, file.name);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log("imagen subida");
            resolve(JSON.parse(xhr.response));
          } else {
            console.log("Fallo la subida");
            reject(xhr.response);
          }
        }
      }
      let url = URL_SERVICIOS + '/user/'  + id;
      xhr.open('PUT', url, true);
      xhr.send(formData);

    });
  }

  deleteImage(name, id){
    let url = URL_SERVICIOS + '/img/' + id +'/'+ name;
    return this.http.delete(url).pipe(map(  (resp: any)=>{
      this.modalService.notificacion.emit(resp)
      return resp
    } ))

  }
}
