import { SubirTemplateComponent } from './subir-template/subir-template.component';
import { PlantillasComponent } from './plantillas/plantillas.component';
import { adminGuard } from './../services/guard/admin.guard';
import { CrearProyectoComponent } from './crear-proyecto/crear-proyecto.component';
import { UserComponent } from './user/user.component';
import { EditorComponent } from './editor/editor.component';
import { LoginGuard } from './../services/guard/login.guard';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { Routes, RouterModule } from '@angular/router';


const pagesRoutes: Routes = [
    {
        path: '', component: PagesComponent,
        canActivate: [LoginGuard],

        children: [
            { path: 'dashboard', component: DashboardComponent , canActivate: [adminGuard] },
            { path: 'editor/:id', component: EditorComponent },
            { path: 'perfil/:id', component: UserComponent },
            { path: 'plantillas', component: PlantillasComponent },
            { path: 'crear-proyecto', component: CrearProyectoComponent, canActivate: [adminGuard] },
            { path: 'subir-plantilla', component: SubirTemplateComponent, canActivate: [adminGuard] },
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
        ]
    }
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);