import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../config/global';



@Injectable({
  providedIn: 'root'
})
export class ModalGalleryService {

  constructor(public http: HttpClient) { }
  oculto = 'oculto'
  public notificacion = new EventEmitter<any>()
  images: any = ['https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100', 'https://picsum.photos/id/237/100']
  urlSeleccionado
  getUrl(elementoImagen: HTMLElement) {

    elementoImagen.classList.add('elementoConBorde')

    this.urlSeleccionado = elementoImagen.getAttribute('src')

    //this.ocultarModal()
    //this.notificacion.emit(item)
  }

  ocultarModal() {
    this.oculto = 'oculto'
  }

  saveImageChange() {

    this.notificacion.emit(this.urlSeleccionado)
    this.ocultarModal()
  }

  /*
  * Recibe el id del proyecto y devuelve el nombre de las imagenes que tiene el proyecto 
  */
  getNombresDeImagenes(id) {
    let url = URL_SERVICIOS + '/img/filenames/' + id
    return this.http.get(url).toPromise().then((e: any) => {
      console.log(e);
      return e.images || []
      
    })
    // console.log(resp);

    
  }

  async mostralModalGallery(id) {

    this.images = await this.getNombresDeImagenes(id)
    let aux = []
    this.images.forEach(element => {
      console.log(element);
      

      aux.push(URL_SERVICIOS+'/img/user/'+id+'/'+element)
    });
    this.images = aux;
    this.oculto = ''
  }


}
