import { MenuService } from './../../services/menu.service';
import { DragService } from './drag.service';
import { Card } from './../../models/card.model';
import { ModalCollectionService } from './../../components/modal-collection-edit/modal-collection.service';
import { ModalCollectionEditComponent } from './../../components/modal-collection-edit/modal-collection-edit.component';
import { URL_SERVICIOS } from './../../config/global';
import { Contenido } from './../../models/contenido';
import { NAVBARS, ELEMENTS, TEXTS } from './objects';
import { SubirArchivoService } from './../../services/subir-archivo.service';
import { ModalUploadService } from './../../components/modal-upload/modal-upload.service';
import { element, promise } from 'protractor';
import { Usuario } from './../../models/usuario.model';
import { Config } from './../../models/config.model';
import { EditorService } from './../../services/editor.service';
import { Component, OnInit, Output, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { config, Observable, SubscribableOrPromise, Subscription, range } from 'rxjs';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import html2canvas from 'html2canvas';
import * as $ from 'jquery';
import * as Swiper from 'swiper';
import { relative } from 'path';
import { image } from 'html2canvas/dist/types/css/types/image';
import Sortable from 'sortablejs';
import HTMLString from 'ContentTools'
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';

// import * as summernote from 'summernote';
// var summernote = require('../../../assets/summernote.js') ;
declare function jQuery();
declare function mifuncion();
declare var ContentTools;


import { cpuUsage } from 'process';
import { parse, stringify } from 'querystring';
import { ɵBROWSER_SANITIZATION_PROVIDERS } from '@angular/platform-browser';
import { table } from 'console';
import { DOM } from '@fortawesome/fontawesome-svg-core';
import { HtmlAstPath } from '@angular/compiler';
import { AgregarElementoService } from 'src/app/services/agregar-elemento.service';
//import {AgregarElementoService} from '../../services/agregar-elemento.service'


//import { Target, Element } from '@angular/compiler';
//declare let html2canvas: (element: HTMLElement, options?: Partial<any>) => Promise<HTMLCanvasElement>;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, AfterViewInit {
  ngAfterViewInit() {

  }
  clienteY = 0
  id: any
  elementPressed;
  proyect
  vista: Element;
  estadoMarcoDeSegmento = false;
  segmentoSeleccionado: HTMLElement
  espacioEnBlanco
  bloquesDisponibles;
  bloqueSobre
  editor
  colNuevo
  seArrastroAdentroArriba: boolean = false;
  seArrastroAdentroAbajo: boolean = false;
  seArrastroAdentroIzquierda: boolean = false;
  seArrastroAdentroDerecha: boolean = false;
  seArrastroAfueraArriba: boolean = false;
  seArrastroAfueraAbajo: boolean = false;
  seArrastroAfueraIzquierda: boolean = false;
  seArrastroAfueraDerecha: boolean = false;
  seArrastroAfueraDesdeAdentroDerecha: boolean = false;
  seArrastroAfueraDesdeAdentroIzquierda: boolean = false;
  elementoBlanco: any = { id: '' }
  leftInicial = 0;
  topInicial = 0;
  leftActual = 0;
  topActual = 0;
  modificarOffset = false;
  allowDrag = true;
  indicador;
  permitirRedimensionar = false;
  colEnBp = 0; // tamaño de columna en Bootstrap
  colEnPx = 0; // tamano de columna en pixeles
  offsetEnBp = 0; // tamano de offset en Bootstrap
  clase_col_a_modificar = 0;
  col_a_redimensionar
  clase_offset_a_modificar = 0;
  redimensionarLadoIzquierdo = false;
  elementoSeleccionado
  elementAuxiliar // elemento que se esta arrastrando
  permitirOver = true;
  elementSobre // elemento donde se esta soltando
  permitirSoltarElemento = false;
  elementSelected
  textSelection: Selection
  underline
  resaltado
  bold
  italics
  btnTextcenter
  btnTextjustify
  btnTextright
  btnEdit
  card: Card
  showMenu = ''
  cardTitleCheck
  cardSubtitleCheck
  cardTextCheck
  cardButtonCheck
  listaBloques = ['text', 'card']
  cardSelectedTitle
  cardSelectedText
  cardSelectedSubtitle
  cardSelectedButton

  cardSelectedList
  cardListCheck

  cardListcheckBoolean

  cardTextcheckBoolean = false;
  cardTitlecheckBoolean = false;
  cardSubtitlecheckBoolean = false;
  cardButtoncheckBoolean = false;

  typeOfTextEditable = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'i', 'b', 'u', 'li'];
  clasesDeComponentes = []

  cardSelected

  tipo_de_elemento_seleccionado

  botonPlusAbajo:HTMLElement

  btnLista
  btnDisplay
  btnDisplayDos
  btnDisplayTres
  btnDisplayCuatro
  textoEditable

  btnAdd

  buttonlargeCheckBoolean = false;

  listaDeColores = ['primary', 'secondary', 'dark', 'success', 'info', 'light', 'warning', 'danger']

  btnH1
  btnH2
  btnH3
  btnH4
  btnH5
  botonSeleccionado

  elementoOverPath

  btnBlockClass = 'btn-secondary'
  btnSmallClass = 'btn-secondary'
  btnMediumClass = 'btn-secondary'
  btnLargeClass = 'btn-secondary'

  elementoDraggable
  liSeleccionado: HTMLElement


  tablaCheck
  tableCheckedBoolean = false
  tableSelectedText
  tableSelected



  botonTableDark = 'btn-secondary'
  botonTableStriped = 'btn-secondary'
  botonTableBordered = 'btn-secondary'
  botonTableBorderless = 'btn-secondary'
  botonTableHover = 'btn-secondary'
  botonTableSmall = 'btn-secondary'

  columnaSeleccionada: HTMLElement
  ptColSelected: number = 0
  pbColSelected: number = 0
  plColSelected: number = 0
  pxColSelected: number = 0
  prColSelected: number = 0
  pyColSelected: number = 0
  pcolumnaSeleccionada: number = 0

  rowSeleccionado: HTMLElement
  ptRowSelected: number = 0

  divHeight: number

  paddingRow = {
    pt: 0,
    pb: 0,
    pl: 0,
    px: 0,
    pr: 0,
    py: 0,
    p: 0
  }

  paddingCol = {
    pt: 0,
    pb: 0,
    pl: 0,
    px: 0,
    pr: 0,
    py: 0,
    p: 0
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public _modalUpload: ModalUploadService,
    public _editorSerivce: EditorService,
    private _subirArchivo: SubirArchivoService,
    private _modalCollectionEdit: ModalCollectionEditComponent,
    public _modalCollectionService: ModalCollectionService,
    public _dragService: DragService,
    public _menuService: MenuService,
    public _agregarElementoService: AgregarElementoService
  ) {
    this.card = {
      classBody: 'card-body'
    }
    this.card.background = 'bg-success';

  }
  @HostListener('document:mousedown', ['$event'])
  async onMouseEnter(event) {
  }
  @HostListener('document:mouseup', ['$event'])
  async onMouseUp(event) {

  }
  @HostListener('document:keydown', ['$event']) onKeyDown(event) {
    if (event.which === 13) {
      let content
      let selection = window.getSelection()

      if (selection.focusNode.parentElement.localName == 'li') {

        event.preventDefault();

        let range = selection.getRangeAt(0);
        content = this.liSeleccionado.textContent.slice(selection.focusOffset);
        let li = document.createElement('li')
        li.textContent = content;


        if (this.liSeleccionado.parentElement.lastChild == this.liSeleccionado) {
          this.liSeleccionado.parentElement.appendChild(li)

        } else {
          this.liSeleccionado.parentElement.insertBefore(li, this.liSeleccionado.nextSibling)
        }
        this.liSeleccionado.textContent = this.liSeleccionado.textContent.slice(0, selection.focusOffset)
      } else {

        event.preventDefault();

        let range = selection.getRangeAt(0);
        // range.setEnd(range.endContainer,0);
        // content = range.cloneContents().cloneNode(true);
        range.insertNode(document.createElement('br'));
        // console.log(range.cloneContents().cloneNode(true));
      }
    }
  }


  @HostListener('document:dragover', ['$event']) dragOver(event) {
    // element.target.style.opacity = .5;
    this._dragService.dragOver(event);

    // this.moviendoBloque(element.target, element)  
  }
  @HostListener('document:drop', ['$event']) drop(event) {
    this._dragService.dropElement(event)


  }
  @HostListener('document:dragenter', ['$event']) dragEnter(event: any) {

    this._dragService.dragEnter(event)
    // this.onMouseUp(element);
  }
  @HostListener('document:dragleave', ['$event']) dragLeave(event) {


    this._dragService.dragLeave(event)




  }
  @HostListener('document:dragstart', ['$event']) async dragStart(event) {

    this._dragService.dragStart(event, this.textoEditable)

  }

  @HostListener('document:keyup', ['$event'])
  onkeyUp(event) {
    if (event.which === 13) {

    }
  }


  // ____________________________________________________________________________________________________________________________________________________


  obtenerAnchoDeCol(elementoCol) {
    let fila = elementoCol.parentElement;
    let anchoCol = elementoCol.getBoundingClientRect().width;

    let anchoFila = fila.getBoundingClientRect().width
    let col = anchoFila / 12;
    this.colEnPx = col
    let tamano = anchoCol / col;
    return tamano
  }

  redimensionar(elemento?, cursor?, tamanoColenPX?, tamanoColenBp?, izquierda?) {

    let posicionXPuntoDerecho = elemento.getBoundingClientRect().right;
    let posicionXPuntoIzquierdo = elemento.getBoundingClientRect().x;
    let distanciaCursorDelPuntoIzquierdo = cursor.clientX - elemento.getBoundingClientRect().x
    let distanciaCursorDelPuntoDerecho = cursor.clientX - elemento.getBoundingClientRect().right

    // ACHICAR DESDE DERECHA
    if (!izquierda && distanciaCursorDelPuntoDerecho < 0) {
      let diferencia = Math.abs(distanciaCursorDelPuntoDerecho);
      for (let i = 1; i <= 12; i++) {
        if (diferencia < (tamanoColenPX * i) && diferencia > ((tamanoColenPX * (i - 1)))) {
          this.clase_col_a_modificar = tamanoColenBp - i;
        }
      }
    }
    // ACHICAR DESDE IZQUIERDA
    else if (izquierda && distanciaCursorDelPuntoIzquierdo > 0 && distanciaCursorDelPuntoIzquierdo < (tamanoColenPX * 12)) {

      let diferencia = Math.abs(distanciaCursorDelPuntoIzquierdo);
      for (let i = 1; i <= 12; i++) {
        if (diferencia < (tamanoColenPX * i) && diferencia > ((tamanoColenPX * (i - 1)))) {
          this.modificarOffset = true;

          this.clase_offset_a_modificar = this.offsetEnBp + i;
          this.clase_col_a_modificar = tamanoColenBp - i;
        }
      }
    }
    // AGRANDAR DESDE DERECHA
    else if (!izquierda && distanciaCursorDelPuntoDerecho > 0) {

      for (let i = 1; i <= 12; i++) {
        if (distanciaCursorDelPuntoIzquierdo < (tamanoColenPX * i) && distanciaCursorDelPuntoIzquierdo > ((tamanoColenPX * (i - 1)))) {
          this.clase_col_a_modificar = i;
        }
      }
    }
    // AGRANDAR DESDE IZQUIERDA
    else if (izquierda && distanciaCursorDelPuntoIzquierdo < 0) {
      let diferencia = Math.abs(distanciaCursorDelPuntoIzquierdo);
      for (let i = 1; i <= 12; i++) {
        if (diferencia < (tamanoColenPX * i) && diferencia > ((tamanoColenPX * (i - 1)))) {
          this.modificarOffset = true;
          this.clase_offset_a_modificar = this.offsetEnBp - i;
          this.clase_col_a_modificar = tamanoColenBp + i;
        }
      }
    }
  }

  limpiarCondiciones() {
    this.seArrastroAdentroIzquierda = false;
    this.seArrastroAdentroDerecha = false;
    this.seArrastroAdentroAbajo = false;
    this.seArrastroAdentroArriba = false;

    this.seArrastroAfueraAbajo = false;
    this.seArrastroAfueraArriba = false;
    this.seArrastroAfueraIzquierda = false;
    this.seArrastroAfueraDerecha = false;
    this.seArrastroAfueraDesdeAdentroDerecha = false;
    this.seArrastroAfueraDesdeAdentroIzquierda = false;


  }

  selectorDeBloques(element: HTMLElement) {
    this.elementoDraggable = document.createElement('div')
    let auxiliar = element
    if (element.nodeName == 'U' || element.nodeName == 'B' || element.nodeName == 'I') {
      while (auxiliar.nodeName == 'U' || auxiliar.nodeName == 'B' || auxiliar.nodeName == 'I') {
        auxiliar = auxiliar.parentElement;
      }
    }
    element = auxiliar;
    // CARD
    if (element.classList.contains('card-body')) {

      this.elementoDraggable = element.parentElement;

    }
    // texto dentro de card
    else if (element.classList.contains('card-text')
      || element.classList.contains('card-title')
      || element.classList.contains('card-subtitle')) {
      let card = element.parentElement;
      if (card.classList.contains('card-body')) {
        this.tipo_de_elemento_seleccionado = 'card'
        this.elementoDraggable = card.parentElement;
      }
    }

    else if (element.classList.contains('btn')) { //pregunta por boton
      if (element.parentElement.classList.contains('card-body') ||
        element.parentElement.classList.contains('card-header') ||
        element.parentElement.classList.contains('card-footer')) {
        let card = element.parentElement;
        if (card.classList.contains('card-body')) {


          this.elementoDraggable = card.parentElement;
          this.tipo_de_elemento_seleccionado = 'card'


        }
      }
      else {
        this.elementoDraggable = element;

      }
    }

    // lista item
    else if (element.classList.contains('list-group-item')) {
      let list = element.parentElement
      this.elementoDraggable = list;

    }
    else if (element.classList.contains('separador')) {
      this.elementoDraggable = element;
    }


    else if (element.classList.contains('elementHr')) {
      this.elementoDraggable = element
    } else if (element.localName == 'hr') {
      this.elementoDraggable  = element.parentElement;
    }

    //imagen
    else if (element.localName == 'img') {
      console.log("selector de bloque");
      
      this.elementoDraggable  = element.parentElement;
    }


    else if (element.localName == "td" || element.localName == "th") { //pregunta si lo seleccionado es tabla
      let tabla: HTMLElement = element.parentElement.parentElement.parentElement

      this.elementoDraggable = tabla

    }
    // texto cualquiera
    else if (this.typeOfTextEditable.includes(element.localName)) {


      if (element.localName == 'li') {
        this.liSeleccionado = element;
        element = element.parentElement.parentElement;
      }

      this.tipo_de_elemento_seleccionado = 'texto'

      this.elementoDraggable = element;


    }
  }



  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this._editorSerivce.getConfigPorId(id).subscribe(resp => {
        this.proyect = resp.config
        this.id = resp.config._id
        // this._modalCollectionService.setimages(this.proyect.images)
        this.cargar();
        this._agregarElementoService.id = this.id;
        console.log(this._modalUpload.id);
        
        this.underline = document.getElementById('underline')
        this.resaltado = document.getElementById('resaltado')
        this.italics = document.getElementById('italics')
        this.bold = document.getElementById('bold')
        this.btnTextcenter = document.getElementById('center')
        this.btnTextjustify = document.getElementById('justify')
        this.btnTextright = document.getElementById('right')
        this.btnLista = document.getElementById("list")
        this.btnDisplay = document.getElementById('display1')
        this.btnDisplayDos = document.getElementById('display2')
        this.btnDisplayTres = document.getElementById('display3')
        this.btnDisplayCuatro = document.getElementById('display4')
        this.btnH1 = document.getElementById('btnH1')
        this.btnH2 = document.getElementById('btnH2')

        this.btnH3 = document.getElementById('btnH3')
        this.btnH4 = document.getElementById('btnH4')
        this.btnH5 = document.getElementById('btnH5')


        this.btnEdit = document.getElementById('btnEdit')

        this.btnAdd = document.getElementById('btnbtn');

        this._menuService.id=this.id



      });
    }
  }
  // traeer el proyecto html del servidor
  async cargar() {
    this.vista = document.getElementById('vista');
    if (this.vista) {
      let url = URL_SERVICIOS + '/config/files/' + this.id + '/index.html';
      let file = await this._editorSerivce.getTextFile(url)
      this.vista.insertAdjacentHTML('afterbegin', file.toString())
      if (document.getElementById('segmento')) {
        this.segmentoSeleccionado = document.getElementById('segmento')
         
        // this.clienteY = this.segmentoSeleccionado.getBoundingClientRect().top - 5
        this.clienteY = 70

        // this.segmentoSeleccionado.style.border = '2px blue solid';
        this.setBloquesDisponibles();
        // this.segmentoSeleccionado.parentElement.appendChild(this.bloquesDisponibles);
        console.log(this.segmentoSeleccionado);


      } else {
        this.agregarPrimerMarco()
      }

    }
  }


  onMouseDown(event) {

    this.selectorDeBloques(event.target)

    let elemento = this.elementoDraggable;

    if (elemento.id.indexOf('bloque') >= 0) {
      if (elemento != this.textoEditable) {
        setTimeout(() => {
          elemento.setAttribute('draggable', 'true');
        }, 20);

      }

    }




    // this.elementPressedTarget = elemento
    this.elementPressed = event

    if (elemento.id == 'crearSegmento') {
      this.mostrarMarcoDeSegmento();
      this.espacioEnBlanco.remove();
    }

    if (elemento.id == 'agregarBloqueTexto') {
      this.bloquesDisponibles.remove();
      this.agregarTexto();
    }

    if (elemento.classList.contains('puntoRedimenzionador')) {
      if (elemento.id == 'puntoI') {
        this.redimensionarLadoIzquierdo = true;
      } else if (elemento.id != 'puntoA') {
        this.redimensionarLadoIzquierdo = false;
      }
      this.col_a_redimensionar = elemento.parentElement;

      this.colEnBp = Math.round(this.obtenerAnchoDeCol(this.col_a_redimensionar))
      this.offsetEnBp = this.obtenerOffsetSiTiene(this.col_a_redimensionar);

      this.permitirRedimensionar = true;
    }


    // if (event.target.id == 'agregarBloqueParrafo') {
    //   this.bloquesDisponibles.remove();

    //   this.agregarParrafo();
    // }
    // if (event.target.id == 'agregarBloqueTitulo2') {
    //   this.bloquesDisponibles.remove();

    //   this.agregarTitulo2();
    // }
    // if (event.target.id == 'agregarBloqueImagen') {
    //   this.bloquesDisponibles.remove();
    //   this.agregarBloqueImagen();
    // }
  }

  dragEnd() {
    // e.log("end");
  }

  obtenerOffsetSiTiene(col) {
    let numero = 0
    if (col) {
      if (col.className.indexOf('offset-') >= 0) {
        let index = col.className.indexOf('offset-') + 7;
        if (col.className.charAt(index + 1) === ' ') {
          numero = parseInt(col.className.charAt(index))
        } else {
          numero = parseInt(col.className.charAt(index) + col.className.charAt(index + 1))
        }
      }
    }
    return numero;
  }
  mouseUp(event) {
    this._dragService.onMouseUp()

    if (this.permitirRedimensionar) {


      this.reemplazarClaseCol(this.col_a_redimensionar, Math.round(this.clase_col_a_modificar))
      if (this.modificarOffset) {
        this.removerClaseOffset(this.col_a_redimensionar)

      }
      this.modificarOffset = false;

      this.clase_offset_a_modificar = 0;

    }
    this.permitirRedimensionar = false;

    if (event.target.id.indexOf('bloque') >= 0) {

      event.target.removeAttribute('draggable');

    }

  }


  save() {
    var editor = ContentTools.EditorApp.get();

    this.allowDrag = true;

    editor.stop(editor.save(true))

    editor.destroy()

  }



  editar() {
    var editor;

    editor = ContentTools.EditorApp.get();
    editor.init('*[data-editable]', 'data-name');
    editor.start()
    this.allowDrag = false;
  }
  reemplazarClaseCol(col_a_redimensionar, tamano) {
    if (col_a_redimensionar) {
      if (col_a_redimensionar.classList.contains('col-md')) {
        col_a_redimensionar.classList.remove('col-md');

        col_a_redimensionar.classList.add('col-md-' + tamano);

      } else if (col_a_redimensionar.className.indexOf('col-md-') >= 0) {
        col_a_redimensionar.classList.remove('col-md-' + this.colEnBp);
        col_a_redimensionar.classList.add('col-md-' + tamano);

      }
    }

  }
  removerClaseOffset(col_a_redimensionar) {
    if (col_a_redimensionar) {
      if (col_a_redimensionar.className.indexOf('offset-') >= 0) {
        let index = col_a_redimensionar.className.indexOf('offset-') + 7;
        let numero
        if (col_a_redimensionar.className.charAt(index + 1) === ' ') {
          numero = col_a_redimensionar.className.charAt(index)
        } else {
          numero = col_a_redimensionar.className.charAt(index) + col_a_redimensionar.className.charAt(index + 1)
        }
        let offset = 'offset-' + numero
        if (col_a_redimensionar.classList.contains(offset)) {

          this.col_a_redimensionar.classList.remove(offset)

        }
        this.col_a_redimensionar.classList.add('offset-' + (this.clase_offset_a_modificar));

      } else {
        this.col_a_redimensionar.classList.add('offset-' + this.clase_offset_a_modificar);

      }
    }
  }


  mouseMove(event: MouseEvent) {



    if (this.permitirRedimensionar) {

      this.redimensionar(this.col_a_redimensionar, event, this.colEnPx, this.colEnBp, this.redimensionarLadoIzquierdo);

    }

  }
  mouseOver(event: MouseEvent) {
    
    this.elementoOverPath = event.composedPath()
    this.elementoOverPath.forEach(element => {
      if (element) {
        if (element.localName == 'div') {
          if (element.hasAttribute('seccion')) {

            if (this.segmentoSeleccionado != element) {
              console.log(this.segmentoSeleccionado);

              // this.segmentoSeleccionado.style.border = 'none'
              this.segmentoSeleccionado.parentElement.classList.remove('elementoConBorde')
              let buttons = document.getElementsByClassName('botonAgregarSeccion')
              while (buttons.length > 0) {
                buttons.item(0).remove()
              }

              this.segmentoSeleccionado = element;
               this.segmentoSeleccionado.parentElement.classList.add('elementoConBorde')
              this._menuService.segmentoSeleccionado = this.segmentoSeleccionado;
              console.log(this._menuService.segmentoSeleccionado);
              
              this._agregarElementoService.segmentoSeleccionado = this.segmentoSeleccionado
              //this.agregarBotonesPlusParaCrearSeccion(this.segmentoSeleccionado)
              this.divHeight = this.segmentoSeleccionado.getBoundingClientRect().height;
              
               

            }

            else if (this.segmentoSeleccionado) {
 

              
                this.segmentoSeleccionado.setAttribute('tieneBotonesPlus', '');
                this.segmentoSeleccionado = element;
                this._menuService.segmentoSeleccionado = this.segmentoSeleccionado;
                this._agregarElementoService.segmentoSeleccionado = this.segmentoSeleccionado
 
              this.divHeight = this.segmentoSeleccionado.getBoundingClientRect().height;
               
            }
          }
        }
      }
    });
     

  }

  agregarBotonesPlusParaCrearSeccion(seccion: HTMLElement) {
    seccion.parentElement.classList.add('elementoConBorde')
    let boton: HTMLElement
    boton = document.createElement('div');
    boton.style.left = '50%'
    boton.style.top = seccion.parentElement.getBoundingClientRect().top - seccion.parentElement.parentElement.getBoundingClientRect().top - 15 + 'px'
    boton.style.width = '40px';
    boton.style.height = '40px';
    boton.style.borderRadius = '20px';
    boton.style.zIndex = '5';
    boton.style.position = 'absolute';
    boton.style.backgroundColor = 'blue';
    boton.setAttribute('sectionButtonTop', '');
    boton.classList.add('botonAgregarSeccion')
    seccion.parentElement.append(boton);
    boton = document.createElement('div');
    boton.style.left = '50%'

    boton.style.top = seccion.parentElement.getBoundingClientRect().top - seccion.parentElement.parentElement.getBoundingClientRect().top + seccion.parentElement.getBoundingClientRect().height - 15 + 'px'
    boton.style.width = '40px';
    boton.style.height = '40px';
    boton.style.borderRadius = '20px';
    boton.style.zIndex = '5';
    boton.style.position = 'absolute';
    boton.style.backgroundColor = 'red';
    boton.classList.add('botonAgregarSeccion')
    boton.setAttribute('sectionButtonBottom', '');
    boton.id = "sectionButtonBottom";
    this.botonPlusAbajo = boton;
    // seccion.style.border = 'solid gray 2px'
    seccion.parentElement.append(boton);
  }

  limpiadorDeColores() {

    this.switchToSecondary(this.underline)

    this.switchToSecondary(this.italics)

    this.bold.classList.add('btn-secondary');
    this.bold.classList.remove('btn-dark');
    this.switchToSecondary(this.bold)

    this.btnTextcenter.classList.remove('btn-dark')
    this.btnTextcenter.classList.add('btn-secondary')

    this.btnTextjustify.classList.remove('btn-dark')
    this.btnTextjustify.classList.add('btn-secondary')


    this.btnTextright.classList.remove('btn-dark')
    this.btnTextright.classList.add('btn-secondary')

    this.btnDisplay.classList.remove('btn-dark')
    this.btnDisplay.classList.add('btn-secondary')
    this.btnDisplayDos.classList.remove('btn-dark')
    this.btnDisplayDos.classList.add('btn-secondary')
    this.btnDisplayTres.classList.remove('btn-dark')
    this.btnDisplayTres.classList.add('btn-secondary')
    this.btnDisplayCuatro.classList.remove('btn-dark')
    this.btnDisplayCuatro.classList.add('btn-secondary')

    this.btnAdd.classList.remove("btn-dark")
    this.btnAdd.classList.add("btn-secondary")
    this.switchToSecondary(this.btnH1);
    this.switchToSecondary(this.btnH2);

    this.switchToSecondary(this.btnH3)
    this.switchToSecondary(this.btnH4)
    this.switchToSecondary(this.btnH5)

  }

  menuRouter(element: HTMLElement) {
    let arregloRuta = this.breadcrumb(element)
    let auxiliar = element

    if (element.nodeName == 'U' || element.nodeName == 'B' || element.nodeName == 'I') {
      while (auxiliar.nodeName == 'U' || auxiliar.nodeName == 'B' || auxiliar.nodeName == 'I') {
        auxiliar = auxiliar.parentElement;
      }
    }
    element = auxiliar;
    // CARD
    if (element.classList.contains('card-body')) {

      
      //element.classList.add('elementoConBorde') ///////////////////////////////////////////////////////////////////////////////////////
      this.tipo_de_elemento_seleccionado = 'card'
      this.desSeleccionarElemento()

      this.elementoSeleccionado = element;

      this.elementoDraggable = element.parentElement;
      this.columnaSeleccionada = this.elementoDraggable.parentElement
      this.configPadding(this.columnaSeleccionada, this.paddingCol)
      this.rowSeleccionado = this.columnaSeleccionada.parentElement
      this.configPadding(this.rowSeleccionado, this.paddingRow)

      this.onCardBody(element)

    }
    // texto dentro de card
    else if (element.classList.contains('card-text')
      || element.classList.contains('card-title')
      || element.classList.contains('card-subtitle')) {
      let card = element.parentElement;
      if (card.classList.contains('card-body')) {
        this.tipo_de_elemento_seleccionado = 'card'

        if (arregloRuta.includes(this.elementoSeleccionado)) {

          if (this.textoEditable && element != this.textoEditable) {
            this.textoEditable.removeAttribute('contenteditable')
          }
          element.setAttribute('contenteditable', 'true')
          this.textoEditable = element;
        }
        if (this.elementoSeleccionado != card) {
          this.desSeleccionarElemento()
          this.elementoSeleccionado = card;
          this.elementoDraggable = card.parentElement;
          this.columnaSeleccionada = this.elementoDraggable.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol)
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)

        }

        this.onCardBody(card)

      }
    }

    else if (element.classList.contains('btn')) { //pregunta por boton
      if (element.parentElement.classList.contains('card-body') ||
        element.parentElement.classList.contains('card-header') ||
        element.parentElement.classList.contains('card-footer')) {
        let card = element.parentElement;
        if (card.classList.contains('card-body')) {
          this.desSeleccionarElemento()

          this.elementoSeleccionado = element;
          this.tipo_de_elemento_seleccionado = 'button'
          this.botonSeleccionado = element;
          this.onConfigBoton(element) // llama a la funcion de configurar botones

          this.columnaSeleccionada = this.botonSeleccionado.parentElement.parentElement.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol)
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)

        }
      }
      else {
        this.desSeleccionarElemento()
        this.botonSeleccionado = element;

        this.onConfigBoton(element) // llama a la funcion de configurar botones

        this.columnaSeleccionada = this.botonSeleccionado.parentElement
        this.configPadding(this.columnaSeleccionada, this.paddingCol)
        this.rowSeleccionado = this.columnaSeleccionada.parentElement
        this.configPadding(this.rowSeleccionado, this.paddingRow)
      }
    }

    // lista item
    else if (!this.typeOfTextEditable.includes(element.parentElement.parentElement.localName) && element.classList.contains('list-group-item')) {


      let list = element.parentElement
      if (arregloRuta.includes(this.elementoSeleccionado)) {
        if (this.textoEditable && element != this.textoEditable) {
          this.textoEditable.removeAttribute('contenteditable')
        }

        this.textoEditable = element;
        this.textoEditable.setAttribute('contenteditable', 'true')
      }
      if (this.elementoSeleccionado != list) {
        this.desSeleccionarElemento()
        this.elementoSeleccionado = list

        this.columnaSeleccionada = this.elementoSeleccionado.parentElement
        this.configPadding(this.columnaSeleccionada, this.paddingCol)
        this.rowSeleccionado = this.columnaSeleccionada.parentElement
        this.configPadding(this.rowSeleccionado, this.paddingRow)

      }




    } else if (element.localName == "td") { //pregunta si lo seleccionado es tabla
      //console.log("entra")
      let tabla: HTMLElement = element.parentElement.parentElement.parentElement


      if (arregloRuta.includes(this.elementoSeleccionado)) {
        if (this.textoEditable && element != this.textoEditable) {
          this.textoEditable.removeAttribute('contenteditable')
        }

        this.textoEditable = element;
        this.textoEditable.setAttribute('contenteditable', 'true')
      }
      if (this.elementoSeleccionado != tabla) {
        this.desSeleccionarElemento()
        this.elementoSeleccionado = tabla
        this.configTable(tabla)

        this.columnaSeleccionada = this.elementoSeleccionado.parentElement
        this.configPadding(this.columnaSeleccionada, this.paddingCol) //para extraer valores de columna
        this.rowSeleccionado = this.columnaSeleccionada.parentElement
        this.configPadding(this.rowSeleccionado, this.paddingRow)

      }

      tabla.childNodes.forEach((element: HTMLElement) => {
        if (element.localName == "thead") {
          this.tableCheckedBoolean = true
        }

      });


    }
    // texto cualquiera
    else if (this.typeOfTextEditable.includes(element.nodeName.toLocaleLowerCase())) {

      this.tipo_de_elemento_seleccionado = 'texto'

      if (element.localName == 'li') {
        this.liSeleccionado = element;
        element = element.parentElement.parentElement;
      }
      if (arregloRuta.includes(this.elementoSeleccionado)) {



        if (this.elementoSeleccionado != element) {
          this.desSeleccionarElemento()

          this.elementoSeleccionado = element
          this.columnaSeleccionada = this.elementoSeleccionado.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol) //llama a la funcion que extrae el valor de la columna
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)

        }
        element.setAttribute('contenteditable', 'true')
        this.textoEditable = element
      } else {
        if (this.elementoSeleccionado != element) {
          this.desSeleccionarElemento()

          this.elementoSeleccionado = element
          this.columnaSeleccionada = this.elementoSeleccionado.parentElement
          this.configPadding(this.columnaSeleccionada, this.paddingCol)
          this.rowSeleccionado = this.columnaSeleccionada.parentElement
          this.configPadding(this.rowSeleccionado, this.paddingRow)



        }

      }

    }


    else {

      this.desSeleccionarElemento()
    }

  }

  desSeleccionarElemento() {
    this.showMenu = ''
    if (this.elementoSeleccionado) {
      if (this.textoEditable) {

        this.textoEditable.removeAttribute('contenteditable');
      }

      this.elementoSeleccionado.classList.remove('elementoConBorde');
      this.elementoSeleccionado = null;

    }
    this.textoEditable = null;

    this.paddingCol.pt = 0
    this.paddingCol.pb = 0
    this.paddingCol.pl = 0
    this.paddingCol.pr = 0
    this.paddingCol.px = 0
    this.paddingCol.py = 0
    this.paddingCol.p = 0

    this.paddingRow.pl = 0
    this.paddingRow.pb = 0
    this.paddingRow.pr = 0
    this.paddingRow.pt = 0
    this.paddingRow.px = 0
    this.paddingRow.py = 0
    this.paddingRow.p = 0

  }

  cardMenuGeneral() {

  }

  cardBodyMenu() {

  }
  cardFooterMenu() {

  }

  comprobarConfiguracionDeTexto(textoEditable) {

    if (textoEditable.classList.contains("lead")) {
      let lead = document.getElementById("lead")
      lead.classList.add("btn-dark")
      lead.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("text-center")) {
      this.btnTextcenter.classList.add("btn-dark")
      this.btnTextcenter.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("text-justify")) {
      this.btnTextjustify.classList.add("btn-dark")
      this.btnTextjustify.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("text-right")) {
      this.btnTextright.classList.add("btn-dark")
      this.btnTextright.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-1")) {
      this.btnDisplay.classList.add("btn-dark")
      this.btnDisplay.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-2")) {
      this.btnDisplayDos.classList.add("btn-dark")
      this.btnDisplayDos.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-3")) {
      this.btnDisplayTres.classList.add("btn-dark")
      this.btnDisplayTres.classList.remove("btn-secondary")
    }
    if (textoEditable.classList.contains("display-4")) {
      this.btnDisplayCuatro.classList.add("btn-dark")
      this.btnDisplayCuatro.classList.remove("btn-secondary")
    }

    // texto seleccionado
    var selection = window.getSelection();

    if (selection.toString()) {
      if (selection.focusNode.parentNode.nodeName == 'U'
        || selection.focusNode.parentNode.parentNode.nodeName == 'U'
        || selection.focusNode.parentNode.parentNode.parentNode.nodeName == 'U') {
        this.underline.classList.remove('btn-secondary')
        this.underline.classList.add('btn-dark')

      }
      if (selection.focusNode.parentNode.nodeName == 'B'
        || selection.focusNode.parentNode.parentNode.nodeName == 'B'
        || selection.focusNode.parentNode.parentNode.parentNode.nodeName == 'B') {
        this.bold.classList.remove('btn-secondary')
        this.bold.classList.add('btn-dark')
      }
      if (selection.focusNode.parentNode.nodeName == 'I'
        || selection.focusNode.parentNode.parentNode.nodeName == 'I'
        || selection.focusNode.parentNode.parentNode.parentNode.nodeName == 'I') {
        this.italics.classList.remove('btn-secondary')
        this.italics.classList.add('btn-dark')
      }
    }

    if (textoEditable.localName == 'h1') {

      this.switchToDark(this.btnH1)
    }
    if (textoEditable.localName == 'h2') {
      this.switchToDark(this.btnH2)
    }

    if (textoEditable.localName == 'h3') {
      this.switchToDark(this.btnH3)
    }

    if (textoEditable.localName == 'h4') {
      this.switchToDark(this.btnH4)
    }

    if (textoEditable.localName == 'h5') {
      this.switchToDark(this.btnH5)
    }


  }

  // funcion que llama desde component.html
  onClick(event) {

    let elemento: HTMLElement = event.target
    if (elemento.classList.contains('botonAgregarSeccion')) {
      this.agregarSeccion(elemento, this.segmentoSeleccionado)
    }

    this._menuService.menuRouter(elemento);
    // if (this.elementoSeleccionado) {
    //   this.colocarBorde(this.elementoSeleccionado);
    // }
    // this._menuService.onClick(event)

    // this.limpiadorDeColores()

    // if (this.textoEditable) {

    //   this.comprobarConfiguracionDeTexto(this.textoEditable);
    // }

    // this.textoEditable = this.textoEditable;

  }

  onCardBody(elemento) {
    this.cardSelected = elemento
    this.showMenu = 'cardBody'
    // if (!document.getElementById('cardBody')) {
    //   return;
    // } 
    this.cardTextCheck = document.getElementById('textCheck')
    this.cardTitleCheck = document.getElementById('titleCheck')
    this.cardSubtitleCheck = document.getElementById('subtitleCheck')
    this.cardButtonCheck = document.getElementById('buttonCheck')

    this.cardListCheck = document.getElementById('listaCheck')
    elemento.childNodes.forEach(element => {
      if (element.classList.contains('card-text')) {
        this.cardTextcheckBoolean = true;
        this.cardSelectedText = element;
      }
      if (element.classList.contains('card-title')) {
        this.cardTitlecheckBoolean = true;
        this.cardSelectedTitle = element;
      }
      if (element.classList.contains('card-subtitle')) {
        this.cardSubtitlecheckBoolean = true;
        this.cardSelectedSubtitle = element;
      }
      if (element.classList.contains('btn')) {
        this.cardButtoncheckBoolean = true;
        this.cardSelectedButton = element;
      }

      if (element.classList.contains('list-group')) {
        this.cardListcheckBoolean = true
        this.cardSelectedList = element
      }
    });


    elemento.classList.add('elementoConBorde')

  }

  cardText() {

  }
  quitarRedimensionadores() {
    if (this.puntoAbajo) {
      this.puntoAbajo.remove()
      this.puntoIzquierdo.remove()
      this.puntoDerecho.remove()
    }

  }

  breadcrumb(e) {
    let elemento = e
    let arreglo = []
    arreglo.push(elemento)
    for (let i = 0; i < 50; i++) {
      if (elemento.classList.length > 0) {
        if (elemento.classList.contains('row')) {
          i = 51;
        } else {
          elemento = elemento.parentElement;
          arreglo.push(elemento);
        }
      } else {
        elemento = elemento.parentElement;
        arreglo.push(elemento)
      }
    }
    return arreglo;

  }

  removeElement(id) {
    if (id == 'titleCheck') {
      if (this.cardTitlecheckBoolean) {
        // this.cardTitleCheck.removeAttribute('checked')
        this.cardTitlecheckBoolean = false;
        this.cardSelectedTitle.remove()
      } else {
        let title = document.createElement('h5');
        title.classList.add('card-title')
        title.textContent = 'Card title';
        // this.cardTitleCheck.setAttribute('checked', '')
        this.cardTitlecheckBoolean = true;
        this.cardSelectedTitle = title;
        this.cardSelected.prepend(title)
      }
    }
    if (id == 'textCheck') {
      if (this.cardTextcheckBoolean) {
        this.cardSelectedText.remove()
        this.cardSelectedText = null;
        // this.cardTextCheck.removeAttribute('checked')
        this.cardTextcheckBoolean = false;
      } else {
        let p = document.createElement('p')
        p.classList.add('card-text');
        p.textContent = `Some quick example text to build on the card title and make up the bulk of the card's content.`
        this.cardSelectedText = p
        // this.cardTextCheck.setAttribute('checked', '')
        this.cardTextcheckBoolean = true;
        if (this.cardSelectedButton) {
          this.cardSelected.insertBefore(p, this.cardSelectedButton);
        } else {
          this.cardSelected.append(p)
        }
      }
    }
    if (id == 'subtitleCheck') {
      if (this.cardSubtitlecheckBoolean) {
        this.cardSubtitlecheckBoolean = false;
        this.cardSelectedSubtitle.remove()
        this.cardSelectedSubtitle = null;
      } else {
        let subtitle = document.createElement('h6');
        subtitle.classList.add('card-subtitle')
        subtitle.textContent = 'Card subtitle';
        this.cardSubtitlecheckBoolean = true;
        this.cardSelectedSubtitle = subtitle;
        if (this.cardSelectedText) {
          // document.getElementById('').insertBefore()
          this.cardSelected.insertBefore(subtitle, this.cardSelectedText);
        } else if (this.cardSelectedButton && !this.cardSelectedText) {
          this.cardSelected.insertBefore(subtitle, this.cardSelectedButton);
        } else {
          this.cardSelected.append(subtitle)
        }
      }
    }

    if (id == 'buttonCheck') {
      if (this.cardButtoncheckBoolean) {
        this.cardButtoncheckBoolean = false;
        this.cardSelectedButton.remove()
        this.cardSelectedButton = null;
      } else {
        this.cardButtoncheckBoolean = true;
        let button = document.createElement('a');
        button.classList.add('btn');
        button.classList.add('btn-primary');
        button.href = 'javascript:null'
        button.textContent = 'Go somewhere'
        this.cardSelectedButton = button;

        this.cardSelected.append(button);
      }
    }

    //lista contenida en card ///////////////////////////////////////////////////////////////////////
    if (id == "listaCheck") {
      if (this.cardListcheckBoolean) {
        this.cardListcheckBoolean = false;
        this.cardSelectedList.remove();
        this.cardSelectedList = null;
      } else {
        this.cardListcheckBoolean = true;
        let ul = document.createElement("ul")
        ul.classList.add("list-group")
        let li = document.createElement("li")
        li.classList.add('list-group-item')
        li.textContent = "Cras justo odio"
        ul.append(li)
        this.cardSelectedList = ul;
        if (this.cardSelectedButton) {
          this.cardSelected.insertBefore(ul, this.cardSelectedButton)
        } else {
          this.cardSelected.append(ul);
        }

      }
    }
  }

  editText() {
    if (this.btnEdit.classList.contains('btn-dark')) {
      this.elementoSeleccionado.removeAttribute('contenteditable')
    } else {
      this.elementoSeleccionado.setAttribute('contenteditable', 'true');
    }
    this.switchButtonColor(this.btnEdit.id);
  }
  colocarBorde(element) {
    element.classList.add('elementoConBorde')
    element.classList.remove('bloque')
  }
  quitarBorde() {

    if (this.elementoSeleccionado) {
      // this.elementoSeleccionado.removeAttribute('contenteditable')
      this.elementoSeleccionado.classList.remove('elementoConBorde')
      this.elementoSeleccionado = null;
    }

  }
  puntoIzquierdo
  puntoDerecho
  puntoAbajo
  colocarRedimensionadores(element: Element) {

    this.puntoIzquierdo
    this.puntoDerecho
    let puntoArriba
    this.puntoAbajo
    let elementHeight = element.getBoundingClientRect().height
    let elementWidth = element.getBoundingClientRect().width
    let elementTop = element.getBoundingClientRect().top - this.clienteY - 24
    let elementLeft = element.getBoundingClientRect().left
    let punto = document.createElement('div');

    punto.classList.add('puntoRedimenzionador')
    punto.style.position = 'absolute'
    punto.style.zIndex = '12'
    punto.style.backgroundColor = '#007bff'
    let conjuntoDePuntos = document.createElement('div');
    this.puntoIzquierdo = document.createElement('div');
    this.puntoDerecho = document.createElement('div');
    this.puntoAbajo = document.createElement('div');

    this.puntoIzquierdo.style.position = 'absolute'
    this.puntoIzquierdo.style.zIndex = '12'
    this.puntoIzquierdo.style.backgroundColor = '#007bff'
    this.puntoIzquierdo.classList.add('puntoRedimenzionador')
    this.puntoIzquierdo.id = 'puntoI'

    this.puntoDerecho.style.position = 'absolute'
    this.puntoDerecho.style.zIndex = '12'
    this.puntoDerecho.style.backgroundColor = '#007bff'
    this.puntoDerecho.classList.add('puntoRedimenzionador')
    this.puntoDerecho.id = 'puntoD'

    this.puntoAbajo.style.position = 'absolute'
    this.puntoAbajo.style.zIndex = '12'
    this.puntoAbajo.style.backgroundColor = '#007bff'
    this.puntoAbajo.classList.add('puntoRedimenzionadorAbajo')
    this.puntoAbajo.id = 'puntoA'

    this.puntoIzquierdo.style.left = '8px'
    this.puntoIzquierdo.style.top = element.getBoundingClientRect().top - element.parentElement.getBoundingClientRect().top + (element.getBoundingClientRect().height * 0.5) + 'px';

    this.puntoDerecho.style.left = elementWidth + 6 + 'px'
    this.puntoDerecho.style.top = element.getBoundingClientRect().top - element.parentElement.getBoundingClientRect().top + (element.getBoundingClientRect().height * 0.5) + 'px';

    this.puntoAbajo.style.left = (elementWidth / 2) + 8 + 'px';
    this.puntoAbajo.style.top = element.getBoundingClientRect().top - element.parentElement.getBoundingClientRect().top + elementHeight - 6 + 'px';

    element.append(this.puntoDerecho);
    element.append(this.puntoIzquierdo);
    element.append(this.puntoAbajo);

  }



  crearRow(elemento) {
    let row = document.createElement('div');
    let col = document.createElement('div');
    col.className = 'col-xs col-md';
    col.id = 'col_' + Math.floor((Math.random() * 10000) + 1);
    row.id = 'row_' + Math.floor((Math.random() * 10000) + 1);
    row.className = 'row py-3 ';
    // this.removerVacio();
    col.appendChild(elemento);
    row.appendChild(col);
    return row;
  }

  onList() {

    let ul = document.createElement('ul');
    let li = document.createElement('li');
    li.insertAdjacentHTML('afterbegin', this.textoEditable.textContent);
    ul.append(li)
    while (this.textoEditable.firstChild) {
      this.textoEditable.firstChild.remove()
    }
    this.textoEditable.append(ul)

  }

  crearCol(elemento) {

    let col = document.createElement('div');
    col.className = 'col-xs col-md'
    col.id = 'col_' + Math.floor((Math.random() * 10000) + 1);

    // this.removerVacio()
    // = this.elementAuxiliar;
    col.insertAdjacentElement('afterbegin', elemento);

    return col;
  }

  // cambia el orden de los elementos que se le pase, si es antes pone justo antes de colSobre si no coloca despues
  cambiarPosicion(colSobre: Element, colNuevo, antes: boolean) {
    let index = 0;

    let fin = []
    let elementsArray = colSobre.parentElement;
    let guardar: any = colSobre.parentElement.childNodes
    let auxiliar = [];

    while (guardar[index] != colSobre) {
      if (colNuevo == guardar[index] || colSobre == guardar[index]) {
        index++;
      } else {
        auxiliar.push(guardar[index]);
        index++;
      }
    }

    for (let i = auxiliar.length; i < guardar.length; i++) {
      if (colNuevo == guardar[i] || colSobre == guardar[i]) {
      } else {
        fin.push(guardar[i]);
      }
    }

    if (antes) {
      auxiliar.push(colNuevo);
      auxiliar.push(colSobre);

    } else {
      auxiliar.push(colSobre);
      auxiliar.push(colNuevo);
    }
    for (let i = 0; i < fin.length; i++) {
      const element = fin[i];
      auxiliar.push(element)
    }

    while (elementsArray.childNodes.length > 0) {
      elementsArray.lastChild.remove()
    }

    for (let i = 0; i < auxiliar.length; i++) {
      const element = auxiliar[i];
      elementsArray.appendChild(element)
    }

  }

  mostrarMarcoDeSegmento() {
    if (this.vista) {
      let marcoSegmento = this.MarcoDeSegmento()
      this.vista.insertAdjacentElement('beforeend', marcoSegmento)
      this.clienteY = this.segmentoSeleccionado.getBoundingClientRect().top - this.segmentoSeleccionado.getBoundingClientRect().height;
      this.estadoMarcoDeSegmento = true;
      this.setBloquesDisponibles()
      // this.segmentoSeleccionado.insertAdjacentElement('beforeend', this.bloquesDisponibles);
      this._agregarElementoService.segmentoSeleccionado = this.segmentoSeleccionado;
      this._menuService.segmentoSeleccionado = this.segmentoSeleccionado;
      this._agregarElementoService.agregarTexto()
    }
  }

  agregarBloqueImagen() {
    let row = document.createElement('div');
    let imagen = document.createElement('div');
    let img = document.createElement('img');
    img.src = 'https://picsum.photos/300/200'
    imagen.className = ' col-xs-12 col-md-12 ';
    imagen.append(img);
    row.className = ' row ';
    imagen.id = 'bloque_imagen_' + Math.floor((Math.random() * 10000) + 1);
    // imagen.style.backgroundColor = '#a4 ' + Math.floor((Math.random() * 100) + 1);
    row.appendChild(imagen);

    this.segmentoSeleccionado.appendChild(imagen)

    // this.segmentoSeleccionado.insertAdjacentHTML('beforeend', '<br>');
    this.segmentoSeleccionado.insertAdjacentElement('beforeend', this.bloquesDisponibles);

  }

  agregarTexto() {
    let row = document.createElement('div');
    let col = document.createElement('div');

    let textarea = document.createElement('h1');
    textarea.textContent = `
    `;
    textarea.append(document.createElement('br'))
    textarea.style.outline = '0';
    textarea.classList.add('bloque')
    // textarea.setAttribute('contenteditable', 'true')
    col.className = ' col-xs col-md ';
    col.id = 'col_' + Math.floor((Math.random() * 10000) + 1);;
    row.className = ' row py-3 ';
    row.id = 'row_' + Math.floor((Math.random() * 10000) + 1);;
    col.appendChild(textarea);
    textarea.id = 'bloque_texto_' + Math.floor((Math.random() * 10000) + 1);

     // textarea.classList.add('text-white')
    // textarea.setAttribute('draggable', 'true')
    row.appendChild(col);

    this.segmentoSeleccionado.appendChild(row);

    // this.segmentoSeleccionado.appendChild(this.bloquesDisponibles);

  }

  agregarParrafo() {
    let texto = document.createElement('div');
    let row = document.createElement('div');
    let textarea = document.createElement('p');
    // textarea.className = 'bloqueTexto';
    textarea.textContent = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Id cum soluta cumque, voluptatem optio, atque earum asperiores quos perspiciatis at culpa animi natus alias suscipit quod nisi obcaecati iure sunt!`;
    row.className = ' row ';
    textarea.style.outline = '0';
    textarea.setAttribute('contenteditable', 'true')
    texto.className = ' col-xs-12 col-md-12  ';
    // texto.style.backgroundColor = '#f' + Math.floor((Math.random() * 1000) + 1);
    // texto.style.height = '100px';
    texto.appendChild(textarea);
    texto.id = 'bloque_texto_' + Math.floor((Math.random() * 10000) + 1);
    row.appendChild(texto);
    this.segmentoSeleccionado.appendChild(texto);
    //// e.log(this.segmentoSeleccionado);

    this.segmentoSeleccionado.appendChild(this.bloquesDisponibles);

  }
  agregarTitulo2() {
    let texto = document.createElement('div');
    let row = document.createElement('div');

    let textarea = document.createElement('h3');

    textarea.textContent = `Titulo 2`;

    textarea.style.outline = '0';
    textarea.setAttribute('contenteditable', 'true')
    texto.className = ' col-xs-12 col-md-12  ';
    row.className = ' row ';
    texto.appendChild(textarea);
    row.appendChild(texto);

    texto.id = 'bloque_texto_' + Math.floor((Math.random() * 10000) + 1);
    this.segmentoSeleccionado.appendChild(texto);


    this.segmentoSeleccionado.appendChild(this.bloquesDisponibles);

  }
  setBloquesDisponibles() {
    let row = document.createElement('div')
    row.style.width = '100%';
    row.className = 'row';
    let col = document.createElement('div');
    col.className = 'centrado col-xs-12 col-md-12';
    let texto = document.createElement('div');
    texto.className = 'btn btn-primary m-2';
    texto.textContent = 'titulo 1';
    texto.id = 'agregarBloqueTexto'
    let titulo2 = document.createElement('div');
    titulo2.className = 'btn btn-primary m-2';
    titulo2.textContent = 'titulo 2';
    titulo2.id = 'agregarBloqueTitulo2'
    let parrafo = document.createElement('div');
    parrafo.className = 'btn btn-primary m-2';
    parrafo.textContent = 'parrafo';
    parrafo.id = 'agregarBloqueParrafo';
    let imagen = document.createElement('div');
    imagen.className = 'btn btn-primary m-2';
    imagen.textContent = 'imagen';
    imagen.id = 'agregarBloqueImagen';
    col.appendChild(texto)
    col.appendChild(titulo2);
    col.appendChild(parrafo)
    col.appendChild(imagen)
    col.id = 'disponibles'
    row.append(col);
    // document.getElementById('paraBloquesDisponibles').appendChild(col);
    this.bloquesDisponibles = row;

  }


  agregarPrimerMarco() {
    this.espacioEnBlanco = document.createElement('div')
    this.espacioEnBlanco.className = ' nuevoBloque centrado';
    let boton = document.createElement('div')
    boton.className = 'btn btn-primary';
    boton.id = 'crearSegmento';
    boton.textContent = 'nuevo segmento'
    this.espacioEnBlanco.appendChild(boton)

    this.vista.insertAdjacentElement('beforeend', this.espacioEnBlanco)
  }
  MarcoDeSegmento() {
    let container = document.createElement('div');
    container.className = ' container nuevoBloque'
    let marco = document.createElement('div');

    marco.className = ' nuevoBloque';

    // marco.style.border = '2px blue solid';
    container.id = 'segmento'
    container.setAttribute('seccion', '');
    let div = document.createElement('div');
    div.style.width = '100%';
    div.append(container);
    this.segmentoSeleccionado = container;
    // container.appendChild(marco);
    // let col = 'col-' + this.getAnchoDeSegmento(this.anchoSegmento);
    // let row = document.createElement('div');

    // let espacioIzquierdo = document.createElement('div');
    // let centro = document.createElement('div');
    // let espacioDerecho = document.createElement('div');

    // row.className = 'row'
    // espacioIzquierdo.className = col;
    // centro.className = 'col';
    // centro.id = 'segmento'
    // espacioDerecho.className = col;
    // centro.appendChild(this.setbloqueText())
    // row.appendChild(espacioIzquierdo)
    // row.appendChild(centro)
    // row.appendChild(espacioDerecho)
    // marco.appendChild(row)

    return div;
  }

  ngOnDestroy() {
  }


  uploadImage(file) {
    this._modalUpload.file = file
    let img = document.getElementById('imgToEdit')
    if (img) {

      this._modalUpload.mostrarModal(this.id, true)
      this._modalUpload.notificacion2.subscribe(resp => {
        let url = URL_SERVICIOS + '/paginas/' + this.id + '/img' + resp.imgSubida

        img.setAttribute('src', url)
      })

    }
  }

  // onSelect(event) {
  //   this.elemento = document.getElementById(event.srcElement.id)
  // }

  mostrarModal() {
    this._modalUpload.mostrarModal(this.id)
  }



  guardar() {

    // this.contenido[this.indice].codigo = document.getElementById('vista').innerHTML
    // this.segmentoSeleccionado.style.border = 'none';
    this.bloquesDisponibles.remove()
    // this.config.codigo = this.contenido[this.indice];
    let pelotas = document.getElementsByClassName('botonAgregarSeccion')
    while (pelotas.length) {
      pelotas.item(0).remove()
    }
    let contenido = {
      nombre: "index",
      contenido: document.getElementById('vista').innerHTML,
      _id: this.id
    }
    this._editorSerivce.updateConfig(contenido).subscribe();
  }

  // sin uso
  onHighlight() {

    this.cambiarColorDeBoton(this.resaltado);

    document.designMode = "on";

    document.execCommand('UseCSS', true);
    document.execCommand('hiliteColor', true);
    document.designMode = "off";

  }
  // sin uso
  onDel() {
    this.editSelection('s')
  }

  editSelection(type: string) {
    var range = window.getSelection().getRangeAt(window.getSelection().toString.length);
    var selection = window.getSelection();
    var markerElement = document.createElement(type);

    markerElement.append(selection.toString())
    range.deleteContents()
    range.insertNode(markerElement);
  }

  cambiarColorDeBoton(boton) {
    if (boton.classList.contains('btn-dark')) {
      boton.classList.remove('btn-dark')
      boton.classList.add('btn-secondary')

    } else {
      boton.classList.add('btn-dark')
    }
  }

  onUnderLine() {


    this.cambiarColorDeBoton(this.underline);


    document.execCommand('underline', false);

    // let selection = window.getSelection()
    // if (selection.focusNode.parentNode.nodeName == 'U') {
    //   let startOffset = selection.getRangeAt(0).startOffset


    //   let endOffset = selection.getRangeAt(0).endOffset
    //   if (startOffset>0) {
    //     let rangeInicial = document.createRange();
    //     rangeInicial.setStart(selection.focusNode, 0)
    //     rangeInicial.setEnd(selection.focusNode, startOffset)
    //     console.log(rangeInicial.toString());

    //    }
    // }
    // this.editSelection('u')
  }
  onBold() {
    this.cambiarColorDeBoton(this.bold);

    document.execCommand('bold', true);

  }
  onItalics() {
    this.cambiarColorDeBoton(this.italics);
    document.execCommand('italic', true);
  }

  cambiarTipoDeTexto(tipo: string) {
    if (this.textoEditable) {

      this.elementoSeleccionado = this.textoEditable
      this.quitarRedimensionadores()
      let atributos: string = this.textoEditable.attributes

      let nuevo = document.createElement(tipo);

      // nuevo.append(this.textoEditable.innerHTML);
      // console.log(this.textoEditable.childNodes);
      nuevo.insertAdjacentHTML('afterbegin', this.textoEditable.innerHTML);
      // for (let i = 0; i < this.textoEditable.childNodes.length; i++) {
      //   const childNode = this.textoEditable.childNodes[i];
      // }
      for (let i = 0; i < atributos.length; i++) {
        const atributo: any = atributos[i];
        nuevo.setAttribute(atributo.nodeName, atributo.nodeValue)
      }
      this.textoEditable.parentElement.replaceChild(nuevo, this.textoEditable);
      this.textoEditable = nuevo;
      this.elementoSeleccionado = nuevo;
      // this.quitarBorde()

      this.switchToSecondary(this.btnH1)
      this.switchToSecondary(this.btnH2)
      this.switchToSecondary(this.btnH3)
      this.switchToSecondary(this.btnH4)
      this.switchToSecondary(this.btnH5)

      if (tipo == 'h1') {

        this.switchToDark(this.btnH1)
      }

      if (tipo == 'h2') {
        this.switchToDark(this.btnH2)
      }

      if (tipo == 'h3') {
        this.switchToDark(this.btnH3)
      }

      if (tipo == 'h4') {
        this.switchToDark(this.btnH4)
      }

      if (tipo == 'h5') {
        this.switchToDark(this.btnH5)
      }





    }
  }

  agregarClase(clase) {
    if (this.textoEditable) {

      this.textoEditable.classList.add(clase);
    }
  }

  establecerLead() {
    if (this.textoEditable) {
      if (!this.textoEditable.classList.contains("lead")) {
        this.agregarClase("lead")
      } else {
        this.textoEditable.classList.remove("lead")
      }
      this.switchButtonColor('lead')
    }

  }

  cita() {
    if (this.textoEditable) {

      if (!this.textoEditable.classList.contains("blockquote")) {
        this.agregarClase("blockquote")
        this.cambiarTipoDeTexto("blockquote")
      } else {
        //this.textoEditable.classList.remove("blockquote")
      }
      let cita = document.getElementById('cita')
      cita.classList.add("btn-dark")
      cita.classList.remove('btn-secondary');
    }
  }

  //cambia a centrado el texto
  onCenter() {
    if (this.textoEditable) {
      this.funcion('text-center')
      if (!this.textoEditable.classList.contains("text-center")) {
        this.agregarClase("text-center")
      } else {
        this.textoEditable.classList.remove("text-center")
      }
      this.switchButtonColor('center')
    }



  }

  funcion(omitir) {

    if (omitir != 'text-justify' && this.textoEditable.classList.contains('text-justify')) {
      this.textoEditable.classList.remove('text-justify')
      //this.textoEditable.classList.remove('btn-dark');
      this.btnTextjustify.classList.remove('btn-dark')
      this.btnTextjustify.classList.add('btn-secondary')
    }
    if (omitir != 'text-center' && this.textoEditable.classList.contains('text-center')) {
      this.textoEditable.classList.remove('text-center')
      this.btnTextcenter.classList.remove('btn-dark')
      this.btnTextcenter.classList.add('btn-secondary')
    }
    if (omitir != 'text-right' && this.textoEditable.classList.contains('text-right')) {
      this.textoEditable.classList.remove('text-right')
      this.btnTextright.classList.remove('btn-dark');
      this.btnTextright.classList.add('btn-secondary')
    }

  }

  //funcion para los displays
  funcion2(omitir) {
    if (omitir != 'display-1' && this.textoEditable.classList.contains('display-1')) {
      this.textoEditable.classList.remove('display-1')
      this.btnDisplay.classList.remove('btn-dark')
      this.btnDisplay.classList.add('btn-secondary')
    }
    if (omitir != 'display-2' && this.textoEditable.classList.contains('display-2')) {
      this.textoEditable.classList.remove('display-2')
      this.btnDisplayDos.classList.remove('btn-dark')
      this.btnDisplayDos.classList.add('btn-secondary')
    }
    if (omitir != 'display-3' && this.textoEditable.classList.contains('display-3')) {
      this.textoEditable.classList.remove('display-3')
      this.btnDisplayTres.classList.remove('btn-dark')
      this.btnDisplayTres.classList.add('btn-secondary')
    }
    if (omitir != 'display-4' && this.textoEditable.classList.contains('display-4')) {
      this.textoEditable.classList.remove('display-4')
      this.btnDisplayCuatro.classList.remove('btn-dark')
      this.btnDisplayCuatro.classList.add('btn-secondary')
    }
  }

  //cambia a texto justificado
  onJustify() {
    if (this.textoEditable) {

      this.funcion('text-justify')
      /* if (this.textoEditable.classList.contains("text-right") || this.textoEditable.classList.contains("text-center") ) {
        this.textoEditable.classList.remove("text-right")
        this.textoEditable.classList.remove("text-center")
        this.switchButtonColor('justify') //ver si esta bien asi
        this.switchButtonColor('right') //ver si esta bien asi
      } */
      if (!this.textoEditable.classList.contains("text-justify")) {
        this.agregarClase("text-justify")
      } else {
        this.textoEditable.classList.remove("text-justify")
      }
      this.switchButtonColor('justify')
    }
  }
  //cambia a texto a la derecha
  onRight() {
    if (this.textoEditable) {

      this.funcion('text-right')
      if (!this.textoEditable.classList.contains("text-right")) {
        this.agregarClase("text-right")
      } else {
        this.textoEditable.classList.remove("text-right")
      }
      this.switchButtonColor('right')
    }
  }

  //agrega al texto display 1
  onDisplay1() {
    if (this.textoEditable) {

      this.funcion2('display-1')
      if (!this.textoEditable.classList.contains("display-1")) {
        //this.agregarClase('display-1')
        this.textoEditable.classList.add('display-1')
      } else {
        this.textoEditable.classList.remove("display-1")
      }
      this.switchButtonColor('display1')
    }
  }

  //agrega al texto display 2
  onDisplay2() {
    if (this.textoEditable) {

      this.funcion2('display-2')
      if (!this.textoEditable.classList.contains("display-2")) {
        this.agregarClase('display-2')
      } else {
        this.textoEditable.classList.remove('display-2')
      }
      this.switchButtonColor('display2')
    }
  }

  //agrega al texto display 3
  onDisplay3() {

    if (this.textoEditable) {

      this.funcion2('display-3')
      if (!this.textoEditable.classList.contains("display-3")) {
        this.agregarClase('display-3')
      } else {
        this.textoEditable.classList.remove('display-3')
      }
      this.switchButtonColor('display3')

    }
  }
  //agrega al texto display 4
  onDisplay4() {
    if (this.textoEditable) {

      this.funcion2('display-4')
      if (!this.textoEditable.classList.contains("display-4")) {
        this.agregarClase('display-4')
      } else {
        this.textoEditable.classList.remove('display-4')
      }
      this.switchButtonColor('display4')
    }
  }


  switchButtonColor(id) {
    let elemento = document.getElementById(id)
    if (elemento.classList.contains('btn-secondary')) {
      elemento.classList.add("btn-dark");
      elemento.classList.remove("btn-secondary")
    } else {
      elemento.classList.add("btn-secondary")
      elemento.classList.remove("btn-dark")
    }
  }




  createCard() {

    let card = document.createElement('div');
    card.classList.add('card');
    card.classList.add('text-white');
    card.classList.add(this.card.background);
    card.style.width = '18rem'

    let body = document.createElement('div');
    body.setAttribute('type', 'card')

    body.classList.add('card-body');
    let title = document.createElement('h5');
    title.classList.add('card-title');
    title.textContent = 'Card title';
    let p = document.createElement('p');
    p.classList.add('card-text');
    p.classList.add('card-texct');
    p.classList.add('card-texsct');
    p.textContent = `Some quick example text to build on the card title and make up the bulk of the card's content.`;
    let btn = document.createElement('a');
    btn.href = 'javascript:null';
    btn.classList.add('btn-outline-light');
    btn.classList.add('btn');

    btn.textContent = 'Go somewhere';
    body.append(title)
    body.append(p)
    body.append(btn);
    card.append(body);
    let row = document.createElement('div');
    let col = document.createElement('div');
    row.classList.add('row')
    row.classList.add('py-3')
    col.classList.add('col-xs');
    col.classList.add('col-md');
    card.id = 'bloque_card_' + Math.floor((Math.random() * 10000) + 1);


    col.append(card);
    row.append(col);
    this.segmentoSeleccionado.appendChild(row)
  }

  crearLista() {

    //lista a partir de un boton
    let ul = document.createElement("ul")
    ul.classList.add("list-group")
    let li = document.createElement("li")
    li.classList.add('list-group-item')
    li.textContent = "Cras justo odio"
    ul.append(li)
    li = document.createElement("li")
    li.classList.add('list-group-item')

    li.textContent = "Dapibus ac facilisis in"
    ul.append(li)
    li = document.createElement("li")
    li.classList.add('list-group-item')
    li.textContent = "Cras justo odio"
    ul.append(li)
    ul.appendChild(li)

    let row = document.createElement('div');
    let col = document.createElement('div');
    row.classList.add('row')
    row.classList.add('py-3')
    col.classList.add('col-xs');
    col.classList.add('col-md');
    ul.id = 'bloque_lista_' + Math.floor((Math.random() * 10000) + 1);
    col.append(ul)
    row.append(col)
    this.segmentoSeleccionado.appendChild(row)

  }


  onBtnbtn() {
    let boton = document.createElement("button")
    boton.classList.add("btn")
    boton.classList.add("btn-primary")
    boton.textContent = 'Button';
    //this.segmentoSeleccionado.appendChild(boton)
    let row = document.createElement('div');
    let col = document.createElement('div');

    col.classList.add('col-xs')
    col.classList.add('col-md')
    row.classList.add('row')
    row.classList.add('py-3')
    boton.id = 'bloque_boton_' + Math.floor((Math.random() * 10000) + 1);
    col.append(boton)
    row.append(col);
    this.segmentoSeleccionado.appendChild(row)

  }

  cambiarColorCard(color) {
    this.elementoDraggable.classList.remove('bg-' + this.reconocerColor(this.elementoDraggable));
    this.elementoDraggable.classList.add(color)
    if (color == 'bg-light') {
      this.elementoDraggable.classList.remove('text-white');
    } else {
      this.elementoDraggable.classList.add('text-white');
    }
  }

  cambiarColorBoton(color) {

    if (this.botonSeleccionado.classList.contains('btn-outline-' + this.reconocerColor(this.botonSeleccionado))) {
      this.botonSeleccionado.classList.remove('btn-outline-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.add('btn-outline-' + color);

    } else {
      this.botonSeleccionado.classList.remove('btn-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.add('btn-' + color)
    }
    // if (color == 'btn-light') {
    //   this.botonSeleccionado.classList.remove('text-white');
    // } else {
    //   this.botonSeleccionado.classList.add('text-white');
    // }
  }

  agregarSeccion(boton, segmentoSeleccionado: HTMLElement) {
    console.log(segmentoSeleccionado);
    let div = document.createElement('div');
    div.style.width = '100%';
    let body = segmentoSeleccionado.parentElement.parentElement;
    console.log(body);

    let segmento = document.createElement('div')

    segmento.classList.add('container');
    segmento.setAttribute('seccion', '');
    div.append(segmento);
    console.log(segmentoSeleccionado.parentElement);

    if (boton.hasAttribute('sectionbuttontop')) {
      body.insertBefore(div, segmentoSeleccionado.parentElement)
    } else {
      body.insertBefore(div, segmentoSeleccionado.parentElement.nextElementSibling)
    }
    this.segmentoSeleccionado = segmento
    this.agregarTexto()

  }







  onConfigBoton(elemento) {
    this.elementoSeleccionado = elemento
    this.showMenu = "botonConfig"



    if (elemento.classList.contains('btn-block')) {

      this.btnBlockClass = 'btn-dark'
    } else {
      this.btnBlockClass = 'btn-secondary'

    }



  }

  removeOutline(elemento) {

    let color = 'primary';
    elemento.classList.remove('btn-' + color);
    elemento.classList.add('btn-outline-' + color);
  }

  setOutline(elemento) {
    let color = 'primary';
    elemento.classList.remove('btn-' + color);
    elemento.classList.add('btn-outline-' + color);

    this.showMenu = "botonConfig"

    if (this.elementoSeleccionado.classList.contains('btn-block')) {
      let bloque = document.getElementById('btnBloque')
      this.switchToDark(bloque);
    }

  }

  //funcion para cambiar el tamano de un boton agregado
  cambiarTamanoBoton(size) {
    if (size == "chico") {
      this.elementoSeleccionado.classList.add('btn-sm')
      this.elementoSeleccionado.classList.remove('btn-lg')

    }
    if (size == "mediano") {
      this.elementoSeleccionado.classList.remove('btn-sm')
      this.elementoSeleccionado.classList.remove('btn-lg')
    }
    if (size == "grande") {
      this.elementoSeleccionado.classList.add('btn-lg')
      this.elementoSeleccionado.classList.remove('btn-sm')

    }
  }

  //funcion para configuar un boton a bloque
  setBloque(b) {
    let bloque = document.getElementById('btnBloque')
    if (bloque.classList.contains('btn-dark')) {
      this.elementoSeleccionado.classList.remove('btn-block')
      this.switchToSecondary(bloque)
    } else {
      this.elementoSeleccionado.classList.add('btn-block')
      this.switchToDark(bloque)
    }
  }

  switchToDark(element) {
    element.classList.remove('btn-secondary')
    element.classList.add('btn-dark')
  }
  switchToSecondary(element) {
    element.classList.remove('btn-dark')
    element.classList.add('btn-secondary')
  }

  switchOutline() {
    if (this.botonSeleccionado.classList.contains('btn-outline-' + this.reconocerColor(this.botonSeleccionado))) {
      this.botonSeleccionado.classList.add('btn-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.remove('btn-outline-' + this.reconocerColor(this.botonSeleccionado));

    } else {
      this.botonSeleccionado.classList.add('btn-outline-' + this.reconocerColor(this.botonSeleccionado));
      this.botonSeleccionado.classList.remove('btn-' + this.reconocerColor(this.botonSeleccionado));
    }

  }

  reconocerColor(elemento) {
    let comienzo
    this.listaDeColores.forEach(color => {
      let index = elemento.className.indexOf(color)

      if (index >= 0) {
        comienzo = elemento.className.slice(index);
        if (comienzo.indexOf(' ') >= 0) {
          comienzo = comienzo.slice(0, comienzo.indexOf(' '))
        }
      }
    });
    return comienzo
  }

  //Funcion para crear una tabla
  createTable() {
    let body = document.getElementsByTagName("body")
    let tabla = document.createElement("table")
    let tbody = document.createElement("tbody")

    for (let i = 0; i < 2; i++) {
      let row = document.createElement("tr")

      for (let j = 0; j < 3; j++) {
        let col = document.createElement("td")
        let texto = document.createTextNode("cell")

        col.appendChild(texto)
        row.appendChild(col)
      }

      tbody.appendChild(row)
    }
    tabla.id = "bloque_tabla_" + Math.floor((Math.random() * 10000) + 1)
    tabla.append(tbody)
    tabla.classList.add("table")
    //document.getElementById("vista").appendChild(tabla)
    let col = document.createElement("div")
    col.classList.add("col")
    col.id = "col_" + Math.floor((Math.random() * 10000) + 1)

    let row = document.createElement("div")
    row.classList.add("row")
    row.id = "row_" + Math.floor((Math.random() * 10000) + 1)
    col.appendChild(tabla)
    row.appendChild(col)


    this.segmentoSeleccionado.appendChild(row)

  }

  //configuracion de tabla: agrega head
  configTable(element: HTMLElement) {
    //console.log(element.firstChild.firstChild.childNodes.length)
    this.showMenu = "configTable"
    this.tableSelected = element
    this.tablaCheck = document.getElementById('theadCheck')


    if (element.classList.contains('table-dark')) {
      this.botonTableDark = 'btn-dark'
    } else {
      element.classList.remove('table-dark')
      this.botonTableDark = 'btn-secondary'
    }

    if (element.classList.contains('table-striped')) {
      this.botonTableStriped = 'btn-dark'
    } else {
      element.classList.remove('table-striped')
      this.botonTableStriped = 'btn-secondary'
    }

    if (element.classList.contains('table-bordered')) {
      this.botonTableBordered = 'btn-dark'
    } else {
      element.classList.remove('table-bordered')
      this.botonTableBordered = 'btn-secondary'
    }

    if (element.classList.contains('table-borderless')) {
      this.botonTableBorderless = 'btn-dark'
    } else {
      element.classList.remove('table-borderless')
      this.botonTableBorderless = 'btn-secondary'
    }

    if (element.classList.contains('table-hover')) {
      this.botonTableHover = 'btn-dark'
    } else {
      element.classList.remove('table-hover')
      this.botonTableHover = 'btn-secondary'
    }

    if (element.classList.contains('table-sm')) {
      this.botonTableSmall = 'btn-dark'
    } else {
      element.classList.remove("table-sm")
      this.botonTableSmall = 'btn-secondary'
    }

  }
  //agrega head a la tabla
  addThead(theadCheck: string) {
    let cantidad = this.elementoSeleccionado.firstChild.firstChild.childNodes.length //pregunto por cantidad de columnas que tengo
    //console.log(cantidad) 

    if (this.tableCheckedBoolean) {  //aqui pregunto si el boton check esta activado, es decir que si tengo head
      this.tableCheckedBoolean = false    // si ya tiene, entonces limpio el boton
      this.elementoSeleccionado.firstChild.remove() //remueve esa fila de encabezado
    } else {     //aqui creo el encabezado de la tabla
      let head = document.createElement('thead'); //creo el elemento thead
      let row = document.createElement("tr") //creo el elemento th

      for (let j = 0; j < cantidad; j++) {   //for para ir creando las columnas
        let col = document.createElement("th")   //creo las columnas
        let texto = document.createTextNode("cell")  //escribo algo dentro de las columnas

        col.appendChild(texto)  //agrego el texto dentro de la columna
        row.appendChild(col)    //agrego la columna a la fila
      }

      head.appendChild(row)  // agrego la fila al encabezado

      this.tableCheckedBoolean = true;  // checkeo boton en activo

      this.elementoSeleccionado.prepend(head)  //inserto la tabla 
    }


  }

  cambiarTabla(formato: string) {
    if (formato == "dark") {
      if (this.elementoSeleccionado.classList.contains('table-dark')) {
        this.elementoSeleccionado.classList.remove('table-dark')
        this.botonTableDark = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-dark')
        this.botonTableDark = 'btn-dark'
      }

    }

    if (formato == "striped") {
      if (this.elementoSeleccionado.classList.contains('table-striped')) {
        this.elementoSeleccionado.classList.remove('table-striped')
        this.botonTableStriped = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-striped')
        this.botonTableStriped = 'btn-dark'
      }

    }

    if (formato == "bordered") {
      if (this.elementoSeleccionado.classList.contains('table-bordered')) {
        this.elementoSeleccionado.classList.remove('table-bordered')
        this.botonTableBordered = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-bordered')
        this.botonTableBordered = 'btn-dark'
      }

    }

    if (formato == "borderless") {
      if (this.elementoSeleccionado.classList.contains('table-borderless')) {
        this.elementoSeleccionado.classList.remove('table-borderless')
        this.botonTableBorderless = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-borderless')
        this.botonTableBorderless = 'btn-dark'
      }

    }

    if (formato == "hover") {
      if (this.elementoSeleccionado.classList.contains('table-hover')) {
        this.elementoSeleccionado.classList.remove('table-hover')
        this.botonTableHover = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-hover')
        this.botonTableHover = 'btn-dark'
      }

    }

    if (formato == "small") {
      if (this.elementoSeleccionado.classList.contains('table-sm')) {
        this.elementoSeleccionado.classList.remove('table-sm')
        this.botonTableSmall = 'btn-secondary'
      } else {
        this.elementoSeleccionado.classList.add('table-sm')
        this.botonTableSmall = 'btn-dark'
      }


    }
  }


  eliminarElemento() {
    if (this.elementoDraggable) {
      let row: HTMLElement = this.elementoDraggable.parentElement.parentElement;
      let col: HTMLElement = this.elementoDraggable.parentElement;
      if (col.childElementCount == 1) {
        if (row.childElementCount == 1) {
          row.remove()
        } else {
          col.remove()
        }
      } else {
        this.elementoDraggable.remove()
      }

    }
  }

  agregarSeparador() {
    let separador = document.createElement('div');
    let col = document.createElement('div');
    let row = document.createElement('div');

    separador.style.height = '150px';
    separador.style.width = '100%';
    separador.style.boxShadow = 'inset 0 0 0 2px #D3D3D3'
    separador.id = 'bloque_separador_' + Math.floor((Math.random() * 10000) + 1);
    separador.classList.add('separador')
    col.append(separador);
    row.append(col)
    this.segmentoSeleccionado.append(row);




  }

  configPadding(col: HTMLElement, padding: any) {
    console.log(col)
    let arreglo = ["pt-", "pl-", "pb-", "px-", "pr-", "py-"] //guardo en un array los nombres que puede tener p
    arreglo.forEach(element => { //recorro con un for el arreglo
      let index = col.className.indexOf(element)
      if (element == "pt-" && index >= 0) { //pregunto si en esa posicion equivale a pt
        padding.pt = parseInt(col.className.charAt(index + 3)) //guardo en una variable el valor de la columna
      }
      if (element == "pb-" && index >= 0) {
        padding.pb = parseInt(col.className.charAt(index + 3))
      }
      if (element == "pl-" && index >= 0) {
        padding.pl = parseInt(col.className.charAt(index + 3))
      }
      if (element == "px-" && index > 0) {
        padding.px = parseInt(col.className.charAt(index + 3))
      }
      if (element == "pr-" && index > 0) {
        padding.pr = parseInt(col.className.charAt(index + 3))
      }
      if (element == "py-" && index > 0) {
        padding.py = parseInt(col.className.charAt(index + 3))
      }


    });
    let indice = col.className.indexOf("p-")
    if (indice >= 0) {
      padding.p = parseInt(col.className.charAt(indice + 2))

    }
  }

  paddingGeneral(elemento, paddingValue) {
    if (paddingValue >= 0 && paddingValue <= 5) {
      let arreglo = ["pt-", "pl-", "pb-", "px-", "pr-", "py-"] //guardo en un array los nombres que puede tener p
      arreglo.forEach(element => { //recorro con un for el arreglo
        let index = elemento.className.indexOf(element)
        if (index >= 0) {
          elemento.classList.remove(element + parseInt(elemento.className.charAt(index + 3)))
        }
      });
      let ind = elemento.className.indexOf("p-")
      elemento.classList.remove("p-" + elemento.className.charAt(ind + 2))
      elemento.classList.add("p-" + paddingValue)
    }
  }

  onChangePaddingTop(clase, numero, elemento) {

    let index = elemento.className.indexOf(clase)
    if (numero <= 5 && numero >= 0) {
      if (index >= 0) {
        let size = elemento.className.charAt(index + 3)
        elemento.classList.remove(clase + size)
      }
      elemento.classList.add(clase + numero)
      if (numero == 0) {
        elemento.classList.remove(clase + "0")
      }
    }

  }


  agregar() {
    this._agregarElementoService.ocultar = ''
  }

  listenerFunction(segmento: HTMLElement) {
    setInterval(() => {
      if (this.divHeight != segmento.getBoundingClientRect().height) {

        let boton = this.botonPlusAbajo;
        boton.style.top = segmento.parentElement.getBoundingClientRect().top - segmento.parentElement.parentElement.getBoundingClientRect().top + segmento.parentElement.getBoundingClientRect().height - 15 + 'px'
      }
    }, 100)

  }




}
