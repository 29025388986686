import { ConfigService } from './../../services/config.service';
import { UsuarioService } from './../../services/usuario.service';
import { Config } from './../../models/config.model';
import { TemplatesService } from './../../services/templates.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalTemplateService {
  template
  oculto = 'oculto'

  constructor(
    public _templatesService: TemplatesService,
    public _usuarioService: UsuarioService
  ) {

  }

  getTemplate(id) {
    this._templatesService.getTemplate(id).subscribe(resp => {
      this.template = resp.template;
      console.log(resp);

    })
  }

  mostrarModal(id) {
    this.oculto = ''
    this.getTemplate(id)
  }
  


  ocultarModal() {
    this.oculto = 'oculto'
  }

}
