import { UsuarioService } from './usuario.service';
import { Usuario } from './../models/usuario.model';
import { URL_SERVICIOS } from './../config/global';
import { Config } from './../models/config.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import Swal from 'sweetalert2'
// import  from 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  notification = new EventEmitter<any>();
  usuario: Usuario;
  token: string;
  constructor(
    public http: HttpClient,
    private _usuarioService: UsuarioService
  ) {

    this.cargarStorage()

  }
  cargarStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
    } else {
      this.token = '';
      this.usuario = null

    }
  }

  getConfig() {

    let url = URL_SERVICIOS + '/config';
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      if (resp.ok == false) {

      }
      return resp
    }));

  }

  sendConfig(config: Config) {

    let url = URL_SERVICIOS + '/html';
    url += '?token=' + this.token;
    return this.http.post(url, config).pipe(map((resp: any) => {
    }));
  }

  getConfigPorId(id) {
    let url = URL_SERVICIOS + '/config/' + id;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp
    }));

  }


  getCollectionModel(id, name) {
    let url = URL_SERVICIOS + '/config/dbModel/' + id + '/' + name;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {

      return JSON.parse(resp.data)
    }));

  }
  getCollection(id, configName, collectionName) {




    let url = URL_SERVICIOS + '/collection/d/' + id + '/' + configName + '/' + collectionName;

    return this.http.get(url).pipe(map((resp: any) => {

      return resp.data;
    }));

  }


  getTextFile(src) {
    let url = src;
    return this.http.get(url, { responseType: 'text' as 'json' })
      .toPromise()
        .then(res =>{
          
          return res;
        })
  }
  deleteDBCollection(id, configName, pageId) {

    let url = URL_SERVICIOS + '/collection/delete/' + id + '/' + configName + '/' + pageId;
    url += '?token=' + this.token;

    return this.http.get(url)
      .pipe(map((resp: any) => {
        if (resp.ok) {
          this.notification.emit()
        }
        return resp.data;
      }));
  }

  getConfigPorUsuario(idUsuario) {
    let url = URL_SERVICIOS + '/config/usuario/' + idUsuario;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp.configs
    }));
  }
  hola() {

  }

  buscarTemplates(termino: string) {
    let url = URL_SERVICIOS + '/busqueda/coleccion/template/' + termino;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp.templates


    }))
  }

  getTemplates() {
    let url = URL_SERVICIOS + '/template';
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {

      return resp
    }));
  }

  getTemplate(id) {
    let url = URL_SERVICIOS + '/template/' + id;
    url += '?token=' + this.token;
    return this.http.get(url).pipe(map((resp: any) => {
      return resp

    }));
  }

  updateObject(object, name, id) {

    let url = URL_SERVICIOS + '/collection/updateObject/' + id + '/' + name
    url += '?token=' + this.token;
    return this.http.put(url, object).pipe(map((resp: any) => {
      this.notification.emit()

    }));
  }

  newObject(object, name, id) {

    let url = URL_SERVICIOS + '/collection/addObject/' + id + '/' + name
    url += '?token=' + this.token;
    return this.http.put(url, object).pipe(map((resp: any) => {
      this.notification.emit()

    }));
  }

  updateConfig(config) {

    let url = URL_SERVICIOS + '/html/' + config._id
    url += '?token=' + this.token;
    return this.http.put(url, config).pipe(map((resp: any) => {
      console.log(resp);
      
    }));
  }

}
